import { useCallback, useContext, useEffect, useState } from "react";
import "./ExamPage.css";
import BackLink from "src/components/nav/BackLink";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import Divider from "src/components/layout/Divider";
import FooterControlBar from "src/pages/goodpoint/exam/FooterControlBar";
import { ExamTabs } from "src/pages/goodpoint/exam/tabs/Tabs";
import useCallApi from "src/api/useCallApi";
import { useTabsState } from "src/pages/goodpoint/exam/tabs/useTabsState";
import { Tab } from "src/api/goodpoint/Const";
import { loadCurrentTabById, saveExamCurrentTab } from "src/storage/CurrentTab";
import { getExamObjectById, saveExamObject } from "src/storage/ExamObject";
import { ExamContext, EditMode } from "src/pages/goodpoint/exam/ExamContext";
import EditableExamName from "src/pages/goodpoint/exam/EditableExamName";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import InfoTab from "src/pages/goodpoint/exam/tabs/info/InfoTab";
import RubricTab from "src/pages/goodpoint/exam/tabs/rubric/RubricTab";
import AnswersTab from "src/pages/goodpoint/exam/tabs/answers/AnswersTab";
import GradingTab from "src/pages/goodpoint/exam/tabs/grading/GradingTab";
import useSendInfo from "src/api/goodpoint/useSendInfo";
import { compareLastModified } from "src/util/Time";
import { Trash, TrashSolid } from "iconoir-react";
import useDeleteExam from "src/pages/goodpoint/dashboard/useDeleteExam";
import AreYouSureModal from "src/components/ui/AreYouSureModal";
import { Skeleton, Stack } from "@mui/material";


const TabComponentMap = {
    [Tab.INFO]: InfoTab,
    [Tab.RUBRIC]: RubricTab,
    [Tab.ANSWERS]: AnswersTab,
    [Tab.GRADING]: GradingTab,
};


function DeleteExamButton() {
    const { examObject, setIsDeleting } = useContext(ExamContext);
    const { deleteExam } = useDeleteExam();
    const navigate = useNavigate();
    const [popupOpen, setPopupOpen] = useState(false);
    return <>
        <div className="relative inline-block flex items-center justify-center text-zanista-red">
            <button 
                className="group peer p-0 pt-1/2 h-6 w-6 transition-scale hover:scale-125 duration-300 ml-2"
                onClick={() => setPopupOpen(true)}
            >
                <Trash className="absolute top-0 h-6 w-6 transition-opacity opacity-100 group-hover:opacity-0 duration-300"/>
                <TrashSolid className="absolute top-0 h-6 w-6 transition-opacity opacity-0 group-hover:opacity-100 duration-300"/>
                {/* Increase the clickable area around the button using an invisible span */}
                <span className="absolute inset-0 -m-2"></span>
            </button>
            <span className={
                `absolute top-1/2 -translate-y-1/2 text-nowrap opacity-0 translate-x-4 transition-all duration-300 ` +
                `right-full peer-hover:translate-x-0 peer-hover:opacity-100 cursor-default pointer-events-none`
            }>
                Delete exam?
            </span>
        </div>
        <AreYouSureModal
            {...{popupOpen, setPopupOpen}}
            onClosePopup={() => setPopupOpen(false)}
            title={"Delete exam"}
            onConfirm={
                () => {
                    setIsDeleting(true);
                    deleteExam(examObject.id, () => { 
                        setPopupOpen(false); 
                        navigate("/goodpoint"); 
                    });
                }
            }
            danger={true}
            confirmContent={"Delete"}
        >
            {`Are you sure you want to delete ${examObject?.name}?`}
        </AreYouSureModal>
    </>
}


function LoadingExamSkeleton() {
    return <div className="centered">
        <div className="container">
            <BackLink prevPage="AI Grader" href="/goodpoint" />

            <Stack className="mt-1 px-4">
                <Skeleton variant="text" sx={{ fontSize: '4rem' }} />

                <div className="grid grid-cols-4 gap-4 -mt-4">
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </div>

                <div className="h-8"></div>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

                <div className="h-1"></div>
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </Stack>
        </div>
    </div>
}


export default function ExamPage() {
    const { examId } = useParams();
    const callApi = useCallApi();
    const [examObject, setExamObject] = useState(getExamObjectById(examId));
    const [queriedServer, setQueriedServer] = useState(false);
    const [editMode, setEditMode] = useState(EditMode.DEFAULT);
    const [showPDF, setShowPDF] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    
    const updateExamObjectWithLastModified = useCallback((updates) => {
        setExamObject((obj) => {
            const newObj = {
                ...obj,
                ...updates,
                last_modified: dayjs().unix(),
            };
            saveExamObject(newObj);
            return newObj;
        });
    }, []);

    const updateExamState = useCallback((updates) => {
        updateExamObjectWithLastModified({ "state": { ...examObject["state"], ...updates }})
    }, [examObject, updateExamObjectWithLastModified])

    const sendInfo = useSendInfo(examId);

    const { tabsState, currentTab, nextTab, futureTab, changeTab } = useTabsState(
        {
            examState: examObject?.state ?? {},
            startTab: loadCurrentTabById(examId),
            onChangeTab: (oldTab, newTab) => {
                setShowPDF(false);
                saveExamCurrentTab(examId, newTab);
            },
        }
    );

    const [footerControls, setFooterControls] = useState(null);
    const [deleteSelected, setDeleteSelected] = useState([]);

    // Fetch exam object from API on load
    useEffect(() => {
        if (queriedServer) return;
        callApi("GET", GoodPointApi.Exam(examId))?.then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {
                    const exam = body["data"];
                    if (!examObject || compareLastModified(exam, examObject)) {
                        setExamObject(exam);
                    } else {
                        setExamObject(examObject);
                        sendInfo(examObject["info"]);
                    }
                    setQueriedServer(true);
                });
            } else {
                console.error(
                    "Failed to fetch exam: " +
                    response.status +
                    " " +
                    response.statusText
                );
            }
        });
    }, [callApi, examId, examObject, queriedServer, sendInfo]);

    if (!examObject || !tabsState) {
        return <LoadingExamSkeleton />;
    }

    const TabComponent = TabComponentMap[currentTab];

    return (
        <div className="centered">
            <div className="container exam-details">
                <ExamContext.Provider
                    value={{
                        examObject,
                        updateExamObject: updateExamObjectWithLastModified,
                        examState: examObject["state"],
                        updateExamState,
                        isDeleting,
                        setIsDeleting,
                        footerControls,
                        setFooterControls,
                        deleteSelected,
                        setDeleteSelected,
                        editMode,
                        setEditMode,
                        showPDF,
                        setShowPDF,
                        currentTab,
                    }}
                >
                    <div className="flex flex-row justify-between align-center">
                        <BackLink prevPage="AI Grader" href="/goodpoint" />
                        <DeleteExamButton />
                    </div>
                    <EditableExamName />
                    <ExamTabs
                        tabsState={tabsState}
                        changeTab={changeTab}
                        currentTab={currentTab}
                    />
                    <Divider
                        lineColour="rgba(0, 0, 0, 0.1)"
                        shadowColour="rgba(0, 0, 0, 0.3)"
                    />
                    <div className="exam-content">
                        { TabComponent && <TabComponent /> }
                    </div>
                    {currentTab && (
                        <FooterControlBar
                            changeTab={changeTab}
                            currentTab={currentTab}
                            nextTab={nextTab}
                            futureTab={futureTab}
                            footerControls={footerControls}
                        />
                    )}
                </ExamContext.Provider>
            </div>
        </div>
    );
}
