import { createContext } from "react";

export const ScanContext = createContext({
    scanObject: null,
    scanState: null,
    originalLink: null,
    setOriginalLink: (_l) => {},
    processedLink: null,
    setProcessedLink: (_l) => {}
});
