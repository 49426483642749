import { useContext } from "react";
import Select from "react-select";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";


export const customStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'var(--zanista-yellow-light)', // Same background as your original example
        borderRadius: '8px', // Border radius as per your original
        boxShadow: '0 0 0 1px var(--zanista-orange-mid)', // Optional: subtle shadow matching the border color
        ':hover': {
            borderColor: 'var(--zanista-yellow-light)', // Yellow border on hover
        },
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected ? 'var(--zanista-orange-mid)' : isFocused ? 'var(--zanista-yellow-light)' : undefined, // Orange for selected and yellow for hover
        color: isSelected ? 'white' : 'black', // White text when selected, black otherwise
        cursor: 'pointer',
        ':active': {
            ...styles[':active'],
            backgroundColor: isSelected ? 'var(--zanista-orange-mid)' : 'var(--zanista-yellow-light)', // Same active state as hover
        },
        transition: 'background-color 0.15s ease-in-out', // Smooth transition for background color
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#ccc',
    }),
    singleValue: (styles) => ({
        ...styles,
        color: 'black', // Style for the selected value (default to black text)
    }),
};


export function StudentsSelect({
    extraColumns = 0,
    extraCells = null
}) {
    /**
     * `extraCells` is an array of lambdas that return a single JSX component.
     * The columns in the grid are given by `2 + extraColumns` or, if null, auto-calculated.
     */
    const { selectedStudent, setSelectedStudent, students } = useContext(StudentsContext);
    const cols = 2 + (extraColumns || (extraCells?.length ?? 0));

    const studentOptions = Object.entries(students ?? {}).map(
        ([_studentId, studentData]) => {
            const studentId = studentData["id"];
            const { student_name: studentName } = studentData["config"];
            return {
                value: _studentId,
                label: (
                    // <div className={`flex flex-row gap-4 justify-between`}>
                    <div className={`grid grid-cols-${cols} gap-4`}>
                        <div className="text-left truncate">
                            <p><b>{studentName ?? studentId}</b></p>
                        </div>
                        <div className="text-left truncate">
                            <p>{studentName && studentId}</p>
                        </div>
                        {
                            extraCells &&
                            extraCells.map((ec, i) => <div key={i}>{ec(studentData)}</div>)
                        }
                    </div>
                )
            };
        }
    );

    const handleStudentChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedStudent(students[selectedOption.value]);
        }
    };

    return (
        <Select
            className="basic-single z-30"
            classNamePrefix="select"
            value={
                studentOptions.find(
                    (option) => option.value === selectedStudent?.id
                )
            }
            styles={customStyles} // Apply the custom styles
            isClearable={false} // Not clearable
            isSearchable={true} // Searchable
            name="student"
            options={studentOptions}
            onChange={handleStudentChange}
            placeholder="Select a student"
        />
    );
}