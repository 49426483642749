import { Spinner } from "@react-pdf-viewer/core";
import { EditPencil, PlusCircle, UndoCircle } from "iconoir-react";
import { useEffect, useState, useCallback } from "react";
import Popup from "reactjs-popup";
import useFetchSearchTicker from "src/api/newswitch/useFetchSearchTicker";
import { Tooltip } from '@mui/material';
import useSendTicker from "src/api/newswitch/useSendTicker";
import useFetchTickers from "src/api/newswitch/useFetchTickers";

function TickerSearchBar({ onSelect, maxNameLength = 20, showAddCustomTicker = false, onCustomTickerSelect = (ticker, exchange) => {} }) {

    const [query, setQuery] = useState("");
    const [tickers, setTickers] = useState([]);
    const [filteredTickers, setFilteredTickers] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const fetchTickers = useFetchTickers();

    const fetchAndSetTickers = useCallback(() => {
        fetchTickers()?.then(tickers => {
            setTickers(tickers);
            setFilteredTickers(JSON.parse(JSON.stringify(tickers)));
        });
    }, [fetchTickers]);

    // Initial fetch of tickers
    useEffect(() => {
        fetchAndSetTickers();
    }, [fetchAndSetTickers]);

    // Filter tickers based on the query
    const filterTickers = useCallback((value) => {
        if (value === "") {
            setFilteredTickers(tickers);
            return;
        }
        // Filter by ticker
        // First get all tickers/names starting with the query
        const closestMatches = tickers.filter(ticker => ticker["ticker"].toLowerCase().startsWith(value.toLowerCase()));
        const nameClosestMatches = tickers.filter(ticker => ticker["name"].toLowerCase().startsWith(value.toLowerCase()));
        // Then get the tickers/names with the query anywhere in the ticker
        const nameAnywhereMatches = tickers.filter(ticker => ticker["name"].toLowerCase().includes(value.toLowerCase()));
        const anywhereMatches = tickers.filter(ticker => ticker["ticker"].toLowerCase().includes(value.toLowerCase()));
        // Combine the lists and remove duplicates
        const matches = [...new Set([ // Note the order for best recommendations
            ...closestMatches, 
            ...nameClosestMatches, 
            ...nameAnywhereMatches, 
            ...anywhereMatches
        ])];
        setFilteredTickers(matches);
    }, [tickers]);

    // Filter the tickers whenever the query changes
    useEffect(() => {
        if (tickers === undefined) return;
        filterTickers(query);
    }, [query, filterTickers, tickers]);

    // Input handling
    const handleChange = (e) => {
        const value = e.target.value;
        setQuery(value);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setTimeout(() => setIsFocused(false), 100);
    };

    const handleKeyDown = (e) => {
        // @ts-ignore
        if (e.key === 'Enter' && filteredTickers && filteredTickers.length > 0) {
            onSelect(filteredTickers[0]);
            setQuery("");
            setIsFocused(false);
            // @ts-ignore
            document.activeElement.blur();
        }
    };

    return (
        <div className="relative">
            <input type="text" id="tickerSearchInput" className="w-full min-h-11 p-2 py-0 rounded outline outline-1 focus:rounded-b-none" 
                placeholder="Search for a ticker"
                value={query}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
            />
            <ul id="tickerDropDown" className={`absolute left-0 right-0 bg-white ${isFocused ? 'border border-slate-500' : ''} rounded rounded-t-none mt-0 max-h-48 overflow-y-auto z-10`}>
                {
                    isFocused && filteredTickers?.map(ticker => {
                        return (
                            <li key={ticker["ticker"]+ticker["name"]} className="p-2 hover:bg-[color:--zanista-orange-mid] cursor-pointer flex-row justify-between items-center"
                                onClick={() => {onSelect(ticker); setQuery(""); setIsFocused(false);}}
                            >
                                <p>
                                    {ticker["ticker"]}
                                </p>
                                {
                                    // Check if the name will fit on one line, else use ellipsis
                                    ticker["name"].length > maxNameLength ?
                                    <p className='text-xs'>
                                        {ticker["name"].substring(0, maxNameLength) + "..."}
                                    </p> :
                                    <p className='text-xs'>
                                        {ticker["name"]}
                                    </p>
                                }
                            </li>
                        );
                    })
                }
                {
                    isFocused && tickers === undefined &&
                        (
                            <li className="p-2 hover:bg-slate-200 cursor-pointer flex-row justify-between items-center">
                                <p>
                                    Loading tickers...
                                </p>
                            </li>
                        )
                }
                {
                    isFocused && showAddCustomTicker && tickers !== undefined &&
                    (
                        <li className="p-2 hover:bg-[color:--zanista-orange-mid] cursor-pointer flex-row justify-between items-center"
                            onClick={() => {setIsPopupOpen(true);}}
                        >
                            <p className="flex-row items-center gap-2">
                                <PlusCircle width={18} height={18} />
                                Custom ticker
                            </p>
                        </li>
                    )
                }
            </ul>
            <CustomTickerPopup 
                setIsPopupOpen={setIsPopupOpen} 
                isPopupOpen={isPopupOpen} 
                inputTicker={query} 
                onChange={(ticker, exchange) => {
                    onCustomTickerSelect(ticker, exchange);
                    setQuery("");
                    setIsFocused(false);
                    fetchAndSetTickers();
                }}
            />
        </div>
    );
}

function CustomTickerPopup({ setIsPopupOpen = (b) => {}, isPopupOpen = false, inputTicker = undefined, inputExchange = "", onChange = (ticker, exchange) => {} }) {

    const [ticker, setTicker] = useState(inputTicker.toUpperCase());
    const [exchange, setExchange] = useState(inputExchange.toUpperCase());
    const [type, setType] = useState("equity");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [tagText, setTagText] = useState("");
    const [tags, setTags] = useState([]);
    const [linkedTicker, setLinkedTicker] = useState(undefined);
    
    const [searchResults, setSearchResults] = useState([]);
    const [showDescription, setShowDescription] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const [tickerSelected, setTickerSelected] = useState(false);
    const [isLinkTickerOpen, setIsLinkTickerOpen] = useState(false);

    const financialTypes = [
        {
            value: "equity",
            label: "Equity"
        },
        {
            value: "etf",
            label: "ETF"
        },
        {
            value: "currency",
            label: "Currency"
        },
        {
            value: "index",
            label: "Index"
        },
        {
            value: "cryptocurrency",
            label: "Cryptocurrency"
        },
        {
            value: "mutualfund",
            label: "Mutual Fund"
        },
        {
            value: "future",
            label: "Future"
        },
        {
            value: "commodity",
            label: "Commodity"
        },
        {
            value: "bond",
            label: "Bond"
        },
        {
            value: "warrant",
            label: "Warrant"
        },
        {
            value: "option",
            label: "Option"
        }
    ]

    const nonFinancialTypes = [
        {
            value: "private",
            label: "Private Company"
        },
        {
            value: "non_financial",
            label: "Non-Financial"
        },
        {
            value: "other",
            label: "Other"
        }
    ]

    const isFinancialType = (value) => {
        return financialTypes.map(t => t.value).includes(value);
    }

    const fetchSearchTicker = useFetchSearchTicker();
    const sendTicker = useSendTicker();

    // TODO: Here, call the API to check if the ticker is valid using yf
    useEffect(() => {
        if (isSearching) {
            fetchSearchTicker(ticker, name, exchange)?.then(results => {
                setIsSearching(false);
                setSearchResults(results);
                setShowDescription(false);
            });
        }
    }, [isSearching, ticker, exchange, name, setIsPopupOpen, fetchSearchTicker]);

    // Reset values when the popup is opened
    useEffect(() => {
        setIsSearching(false);
        if (!isPopupOpen) {
            setTicker(inputTicker.toUpperCase());
            setExchange(inputExchange.toUpperCase());
            setName("");
            setDescription("");
            setType("equity");
            setSearchResults([]);
            setShowDescription(true);
            setTickerSelected(false);
            setLinkedTicker(undefined);
            setTagText("");
            setTags([]);
        }
    }, [isPopupOpen, inputTicker, inputExchange]);

    // Update values when a serch item (including custom) is clicked
    const handleSearchResultClick = (result) => {
        setTicker(result["ticker"]);
        setExchange(result["exchange"]);
        setName(result["name"]);
        setDescription(result["description"]);
        setShowDescription(true);
        setTickerSelected(true);
        setTagText("");
        if (result["asset_class"] !== undefined && financialTypes.concat(nonFinancialTypes).map(t => t.value).includes(result["asset_class"].toLowerCase())) {
            setType(result["asset_class"].toLowerCase());
        } else if (isFinancialType(type)) {
            setType("other");
        }
        setLinkedTicker(undefined);
    }

    useEffect(() => {
        if (!tickerSelected) {
            setShowDescription(true);
        }
    }, [tickerSelected]);

    // Update tags when tagText changes
    useEffect(() => {
        setTags(tagText.split(",").map(tag => tag.trim()).filter(tag => tag !== ""));
    }, [tagText]);

    return (
        <Popup
                open={isPopupOpen}
                onClose={() => {setIsPopupOpen(false);}}
                modal nested
                position="center center"
        >
                <div className='flex-col p-4 text-sm bg-white rounded gap-3 min-w-72'>
                    <h2>Add a Custom Search Term</h2>
                    <hr className='w-full' />
                    <p className="text-xs text-gray-600">
                        {!tickerSelected ? "Search by ticker, name and exchange" : "Enter custom ticker information"}
                    </p>
                    <div className="flex-row gap-4">
                        <div className="flex-col gap-3 justify-between">
                            <div className='flex-col text-sm gap-2'>
                                <div className="flex-row gap-1">
                                    <h3 className="text-sm">{isFinancialType(type) ? "Ticker" : "Identifier"}:</h3>
                                    <p className="text-xs text-red-500">
                                        *
                                    </p>
                                </div>
                                <input 
                                    type="text" 
                                    className="text-gray-700 rounded outline outline-1 px-1 min-h-6" 
                                    value={ticker}
                                    onChange={(e) => {
                                        const value = isFinancialType(type) ? e.target.value.toUpperCase().slice(0,10) : e.target.value;
                                        setTicker(value);
                                        setTickerSelected(false);
                                    }}
                                    style={{textTransform: isFinancialType(type) ? 'uppercase' : 'none'}}
                                    maxLength={isFinancialType(type) ? 10 : undefined}
                                    disabled={isSearching}
                                />
                            </div>
                            <div className='flex-col text-sm gap-2'>
                                <h3>Type:</h3>
                                <select 
                                    className="text-gray-700 rounded outline outline-1 px-1 min-h-6 bg-white" 
                                    value={type}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const beforeType = isFinancialType(type);
                                        setType(value);
                                        // Don't update tickerSelected if the type is changing from non-financial to non-financial
                                        if (beforeType || isFinancialType(value)) {
                                            setTickerSelected(false);
                                        }
                                    }}
                                    disabled={isSearching}
                                >
                                    {
                                        financialTypes.map(type => {
                                            return <option value={type.value}>{type.label}</option>
                                        })
                                    }
                                    <option disabled> ─────────── </option>
                                    {
                                        nonFinancialTypes.map(type => {
                                            return <option value={type.value}>{type.label}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {
                                (isFinancialType(type) || !tickerSelected) ?
                                <div className='flex-col text-sm gap-2'>
                                    <h3>Exchange:</h3>
                                    <input 
                                        type="text" 
                                        className="text-gray-700 rounded outline outline-1 px-1 min-h-6" 
                                        value={exchange}
                                        onChange={(e) => {
                                            setExchange(e.target.value.toUpperCase());
                                            setTickerSelected(false);
                                        }}
                                        style={{textTransform: 'uppercase'}}
                                        disabled={isSearching}
                                    />
                                </div>
                                :
                                <div className='flex-col text-sm gap-2'>
                                    <div className="flex-row gap-1 justify-between items-end">
                                        <h3 className="text-sm">Tags:</h3>
                                        <p className="text-xs text-gray-500">
                                            e.g. tech, energy
                                        </p>
                                </div>
                                    <input 
                                        type="text" 
                                        className="text-gray-700 rounded outline outline-1 px-1 min-h-6" 
                                        value={tagText}
                                        onChange={(e) => {
                                            setTagText(e.target.value);
                                        }}
                                        disabled={isSearching}
                                    />
                                </div>
                            }
                        </div>
                        {
                            showDescription ?
                            <div className='flex-col text-sm gap-2 h-full'>
                                <div className='flex-col text-sm gap-2'>
                                    <div className="flex-row gap-1">
                                        <h3 className="text-sm">{isFinancialType(type) ? "Company Name" : "Name"}:</h3>
                                        <p className="text-xs text-red-500">
                                            *
                                        </p>
                                    </div>
                                    <input 
                                        type="text" 
                                        className="text-gray-700 rounded outline outline-1 px-1 min-h-6" 
                                        value={name}
                                        onChange={(e) => {
                                            const value = isFinancialType(type) ? e.target.value.toUpperCase() : e.target.value;
                                            setName(value);
                                            setTickerSelected(false);
                                        }}
                                        style={{textTransform: isFinancialType(type) ? 'uppercase' : 'none'}}
                                        disabled={isSearching}
                                    />
                                </div>
                                <div className='flex-col text-sm gap-2'>
                                    <h3>Description:</h3>
                                    <textarea 
                                        className="text-gray-700 rounded outline outline-1 p-1 h-full min-h-24 min-w-52" 
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        style={{resize: 'none'}}
                                        disabled={isSearching}
                                    />
                                </div>
                            </div> :
                            <div className='flex-col text-sm gap-2'>
                                <h3>Search Results:</h3>
                                <div 
                                    className="flex-col gap-1 py-1 rounded outline outline-1 overflow-y-auto h-full max-h-40 min-w-52"
                                >
                                    <hr className="w-full" />
                                    <div className="flex-row justify-between items-center px-1 cursor-pointer hover:bg-[color:--zanista-orange-light]"
                                        onClick={() => {handleSearchResultClick({
                                            ticker: ticker,
                                            exchange: exchange,
                                            name: name,
                                            description: description
                                        })}}
                                    >
                                        <p className="text-xs">
                                            CUSTOM {isFinancialType(type) ? "TICKER" : "TERM"}: {
                                                exchange ?
                                                `${exchange}:${ticker}` :
                                                `${ticker}`
                                            }
                                        </p>
                                    </div>
                                    {
                                        searchResults.length > 0 &&
                                        (
                                            <>
                                                <hr className="w-full" />
                                                <hr className="w-full" />
                                                </>
                                        )
                                    }
                                    {searchResults.map(result => {
                                        const maxNameLength = 12;
                                        return (
                                            <Tooltip 
                                                title={result["name"]} 
                                                placement="left" 
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            backgroundColor: "var(--zanista-orange-light)",
                                                            color: "black",
                                                        }
                                                    }
                                                }}
                                            >
                                                <div className="flex-row justify-between items-center px-1 cursor-pointer hover:bg-[color:--zanista-orange-light]"
                                                    onClick={() => {handleSearchResultClick(result)}}
                                                >
                                                    <p className="text-xs">
                                                        {result["exchange"] && result["exchange"] !== "" ? result["exchange"] + ":" + result["ticker"] : result["ticker"]}
                                                    </p>
                                                    {result["name"].length > maxNameLength ?
                                                        <p className='text-xs'>
                                                            {result["name"].substring(0, maxNameLength) + "..."}
                                                        </p> :
                                                        <p className='text-xs'>
                                                            {result["name"]}
                                                        </p>
                                                    }
                                                </div>
                                            </Tooltip>
                                        );
                                    })}
                                    <hr className="w-full" />
                                </div>
                            </div>
                        }
                    </div>
                    <div className='flex-row gap-3 justify-between w-full'>
                        <div className='flex-row gap-3 justify-start'>
                            <button 
                                className="text-xs h-min p-2 my-2 w-fit rounded outline outline-1 bg-[var(--zanista-orange-light)]"
                                onClick={() => {setIsPopupOpen(false);}}
                            >
                                Cancel
                            </button>
                            <button 
                                className={"text-xs h-min p-2 my-2 w-fit rounded outline outline-1 bg-[var(--zanista-orange-mid)] " + (isSearching || (ticker.length === 0 && name.length === 0) ? "bg-gray-200" : "")}
                                onClick={() => {
                                    if (tickerSelected) {
                                        // Try add ticker to db
                                        sendTicker(ticker, name, type, exchange, description, !isFinancialType(type), linkedTicker, tags)?.then(result => {
                                            if (result.success) {
                                                onChange(ticker, exchange);
                                                setIsPopupOpen(false);
                                            } else if (result.exists_error) {
                                                alert(`${ticker} already exists as a custom search term`);
                                            } else {
                                                alert(`Failed to add ${ticker} as a custom search term`);
                                            }
                                        });
                                    } else {
                                        setIsSearching(true);
                                    }
                                }}
                                disabled={isSearching || (ticker.length === 0 && name.length === 0)}
                            >
                                <div className="flex-row gap-1 items-center text-xs">
                                    {isSearching && <Spinner 
                                        // @ts-ignore
                                        size={12} 
                                    />}
                                    {isSearching ? "Searching" : tickerSelected ? "Submit" : "Search"}
                                </div>
                            </button>
                        </div>
                        {   tickerSelected && !isFinancialType(type) &&
                            ( linkedTicker === undefined ?
                                <button 
                                    className="text-xs h-min p-2 my-2 w-fit rounded outline outline-1 bg-[var(--zanista-orange-light)]"
                                    onClick={() => {setIsLinkTickerOpen(true);}}
                                >
                                    Link Ticker
                                </button>
                                :
                                <button 
                                    className="text-xs h-min p-2 my-2 w-fit rounded outline outline-1 bg-[var(--zanista-orange-light)]"
                                    onClick={() => {setLinkedTicker(undefined);}}
                                >
                                    Unlink {linkedTicker}
                                </button>
                            )
                        }
                    </div>
                    <LinkTickerPopup onSelect={(t) => {
                        if (ticker !== t["ticker"]) {
                            setLinkedTicker(t["ticker"]);
                        }
                    }} setIsPopupOpen={setIsLinkTickerOpen} isPopupOpen={isLinkTickerOpen} />
                </div>
            </Popup>
    )
}

function LinkTickerPopup({ onSelect = (ticker) => {}, setIsPopupOpen = (b) => {}, isPopupOpen = false }) {

    const [ticker, setTicker] = useState(undefined);
    const [isSearching, setIsSearching] = useState(true);

    useEffect(() => {
        if (ticker === undefined) {
            setIsSearching(true);
        }
    }, [ticker]);

    return (
        <Popup
                open={isPopupOpen}
                onClose={() => {setIsPopupOpen(false);}}
                onOpen={() => {setTicker(undefined); setIsSearching(true);}}
                modal nested
                position="center center"
        >
            <div className='flex-col p-4 text-sm bg-white rounded gap-3 min-w-72'>
                <h2>Link Ticker</h2>
                <hr className='w-full' />
                <p className="text-xs text-gray-600">
                    Select a ticker to link
                </p>
                {
                    isSearching ? (
                        <div className="flex-row gap-2 text-sm justify-start items-center">
                            <div className="flex-1">
                                <TickerSearchBar 
                                    onSelect={(ticker) => {
                                        setTicker(ticker);
                                        setIsSearching(false);
                                    }} 
                                    showAddCustomTicker={false} 
                                />
                            </div>
                            {
                                ticker &&
                                <div className="cursor-pointer"
                                    onClick={() => {
                                        setIsSearching(false);
                                    }}
                                >
                                    <UndoCircle width={24} height={24} />
                                </div>
                            }
                        </div>
                    ) :
                    (
                        <div className="flex-row gap-2 text-sm justify-between items-center">
                            <h3>Ticker: {ticker["ticker"]}</h3>
                            <div className="flex-row gap-2 items-center cursor-pointer"
                                onClick={() => {
                                    setIsSearching(true);
                                }}
                            >
                                <EditPencil width={16} height={16} />
                            </div>
                        </div>
                    )
                }
                <div className='flex-row gap-3 justify-between w-full'>
                    <div className='flex-row gap-3 justify-start'>
                        <button 
                            className="text-xs h-min p-2 my-2 w-fit rounded outline outline-1 bg-[var(--zanista-orange-light)]"
                            onClick={() => {setIsPopupOpen(false);}}
                        >
                            Cancel
                        </button>
                        <button 
                            className={"text-xs h-min p-2 my-2 w-fit rounded outline outline-1 bg-[var(--zanista-orange-mid)] " + (ticker === undefined ? "bg-slate-200" : "")}
                            onClick={() => {
                                if (ticker) {
                                    onSelect(ticker);
                                    setIsPopupOpen(false);
                                }
                            }}
                            disabled={ticker === undefined}
                        >
                            <div className="flex-row gap-1 items-center text-xs">
                                Link Ticker
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default TickerSearchBar;
