import { Divider, IconButton, Tooltip } from "@mui/material";
import { Check, Play, TrashSolid, Xmark } from "iconoir-react";
import { useContext, useState, useCallback, useMemo } from "react";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { StepState } from "src/api/goodpoint/Const";
import OchreApi from "src/api/ochre/OchreApi";
import useCallApi from "src/api/useCallApi";
import AreYouSureModal from "src/components/ui/AreYouSureModal";
import { ScansListContext } from "src/pages/ochre/dashboard/ScansListContext";


function ScanItem({ item, bgColour = "zanista-yellow-light", clickable = true, enabled = true }) {
    const { id: scanId, source_file: sourceFile } = item;
    return (
        <Link
            className={
                `${clickable ? "btn-clickable" : "cursor-disabled"} bg-${bgColour} ` +
                `w-full p-3 py-2 rounded-md `
            }
            to={`scan/${scanId}`}
        >
            <div className={`flex flex-row justify-between w-full`}>
                <p><b>{sourceFile}</b></p>
                <p>{scanId}</p>
            </div>
        </Link>
    )
}


function ProcessButton({ scanId }) {
    const callApi = useCallApi();
    const [clicked, setClicked] = useState(false);
    const { setScans } = useContext(ScansListContext);

    const onClick = useCallback(() => {
        setClicked(true);
        callApi("POST", OchreApi.Scan.Process(scanId))
            ?.then(response => {
                if (response.ok) {
                    setScans(s => {
                        return {
                            ...s, 
                            [scanId]: {
                                ...s[scanId], 
                                state: StepState.PROCESSING
                            }
                        }
                    });
                }
            })
            .finally(() => setClicked(false));
    }, [callApi, scanId, setScans]);

    return (
        <Tooltip title={clicked ? "Awaiting start..." : "Begin processing"} placement="right">
            <span><button 
                className={
                    "flex flex-row p-2 border border-2 justify-center items-center rounded-md " +
                    `${clicked 
                        ? "bg-slate-300 "
                        : (
                            "bg-zanista-orange-mid transition hover:bg-zanista-red btn-clickable"
                        )
                    }`
                }
                onClick={onClick}
                disabled={clicked}
            >
                {
                    clicked 
                        ? <TailSpin width={27} height={27} color="gray"/> 
                        : <Play color=""/>
                }
            </button></span>
        </Tooltip>
    )
}


function SelectForDeletion({ id, deleteSelection, setDeleteSelection }) {
    return (
        <input
            className="mt-2 mb-2 p-5 w-6 h-6 accent-zanista-orange border border-zanista-grey-mid"
            checked={deleteSelection?.[id]}
            onChange={() => setDeleteSelection(s => {return {...s, [id]: s[id] ? false : true}})}
            type="checkbox"
        />
    )
}


function DeleteToolbarButton({ onClick = null, children }) {
    return (
        <button className="transition transition-all hover:scale-110 duration-100 p-2 m-0" onClick={onClick}>
            {children}
        </button>
    )
}


export default function ScanMenu({ 
    title, filter, buttonsDisabled = false, allowDelete = true, showProcessButton = false, 
    bgColour = "zanista-yellow-light"
}) {
    const callApi = useCallApi();
    const { scans, setScans } = useContext(ScansListContext);
    const [deleteMode, setDeleteMode] = useState(false);
    const [deleteSelection, setDeleteSelection] = useState({});
    const [popupOpen, setPopupOpen] = useState(false);

    const items = useMemo(() => Object.values(scans ?? {})?.filter(filter) ?? [], [filter, scans]);

    const selectAllForDeletion = useCallback(() => {
        setDeleteSelection(Object.fromEntries(
            items.map((i) => [i.id, true])
        ));
    }, [items]);

    const doDelete = useCallback((scanIds) => {
        callApi("POST", OchreApi.DeleteList, {body: {"to_delete": scanIds}})
            ?.then((response) => {
                if (response.ok) {
                    response.json().then(body => {
                        const deleted = body["data"]["deleted"];
                        setScans(oldScans => {
                            const newScans = {...oldScans};
                            for (const deletedId of deleted) {
                                delete newScans[deletedId];
                            }
                            return newScans;
                        })
                    })
                } else {
                    setScans(null);
                }
                setDeleteMode(false);
                setDeleteSelection({});
                setPopupOpen(false);
            });
    }, [callApi, setPopupOpen, setScans]);

    if (items.length === 0) {
        return null
    }

    return (
        <div className="mt-8 space-y-3">
            <div className="flex-row justify-content-space-between">
                <h2 className="">{title}</h2>
                {
                    allowDelete && (
                        deleteMode
                        ? (
                            <div className="flex flex-row items-center gap-3">
                                <DeleteToolbarButton onClick={selectAllForDeletion}>All</DeleteToolbarButton>
                                <DeleteToolbarButton onClick={() => setDeleteSelection({})}>None</DeleteToolbarButton>
                                <DeleteToolbarButton onClick={() => 
                                    (Object.values(deleteSelection).filter(v => v).length > 0) 
                                        ? setPopupOpen(true)
                                        : setDeleteMode(false)
                                }><Check/></DeleteToolbarButton>
                                <DeleteToolbarButton onClick={() => setDeleteMode(false)}><Xmark/></DeleteToolbarButton>
                            </div>
                        )
                        : (
                            (items.length > 0) &&
                            <Tooltip title="Delete scans" placement="top">
                                <IconButton onClick={() => {
                                    setDeleteSelection({});
                                    setDeleteMode(d => !d);
                                }}><TrashSolid /></IconButton>
                            </Tooltip>
                        )
                    )
                }
            </div>
            <Divider />
            <div className="space-y-3">
                {
                    items
                        ?.sort((a, b) => {
                            return a.source_file - b.source_file
                        })
                        ?.map((item, index) => (
                            <div key={index} className="flex flex-row items-center gap-3">
                                <ScanItem
                                    item={item}
                                    clickable={!deleteMode && !buttonsDisabled}
                                    bgColour={bgColour}
                                />
                                {
                                    deleteMode && 
                                    <SelectForDeletion 
                                        id={item.id} 
                                        deleteSelection={deleteSelection} setDeleteSelection={setDeleteSelection}
                                    />
                                }
                                {
                                    showProcessButton && !deleteMode &&
                                    <ProcessButton scanId={item.id}/>
                                }
                            </div>
                        ))
                }
            </div>
            <AreYouSureModal
                {...{popupOpen, setPopupOpen}}
                onClosePopup={() => setPopupOpen(false)}
                title={"Delete scans"}
                onConfirm={
                    () => doDelete(
                        Object.entries(deleteSelection).filter(([_, v]) => v).map(([k, _]) => k)
                    )
                }
                danger={true}
                confirmContent={"Delete"}
            >
                <p>Are you sure you want to delete <b>{
                    `${deleteSelection && Object.values(deleteSelection).filter(v => v).length}`
                }</b> scan(s)?</p>
                <p>This may take a while. Do not reload the page.</p>
            </AreYouSureModal>
        </div>
    )
}