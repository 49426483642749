import { Download, FloppyDiskArrowOut, StatsUpSquare, Xmark } from "iconoir-react";
import { useContext, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import Popup from "reactjs-popup";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { useGeneralBlobDownload } from "src/api/useBlobDownload";
import Divider from "src/components/layout/Divider";
import Loading from "src/components/ui/Loading";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { standardSlugify } from "src/util/StringUtil";


function DownloadButton({ enabledClassName, endpoint, filename }) {
    const { download, isDownloading } = useGeneralBlobDownload();
    return (
        <button 
            className={
                "flex flex-row items-center gap-1 p-2 px-2 rounded-md " +
                (!isDownloading ? `btn-clickable ${enabledClassName}` : "bg-slate-300")
            }
            onClick={() => download(endpoint, filename)}
            disabled={isDownloading}
        >
            { 
                isDownloading 
                    ? <TailSpin strokeWidth={2.0} color="black" width={27} height={27} /> 
                    : <Download /> 
            }
        </button>
    )
}

function DownloadRow({ 
    Icon, text, endpointFunc, filenameFunc, enabledClassName = null,
    selectOptions = undefined, defaultSelection = undefined
}) {
    const [selection, setSelection] = useState(
        () => {
            if (defaultSelection) return defaultSelection;
            if (selectOptions && selectOptions.length > 0) {
                const first = selectOptions[0];
                if (Array.isArray(first)) return first[0]
                else return first;
            }
        }
    );

    return (
        <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-2 items-center">
                <Icon /><p>{text}</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
            {
                (selectOptions !== undefined) &&
                <select 
                    className=
                        "px-2 py-2 rounded-md border border-slate-300 transition hover:border-black"
                    defaultValue={defaultSelection}
                    onChange={(e) => setSelection(e.target.value)}
                >
                    {
                        selectOptions
                            ? (
                                selectOptions.map((o, i) => {
                                    return Array.isArray(o) 
                                        ? <option key={i} value={o[0]}>{o[1]}</option>
                                        : <option key={i} value={o}>{o}</option>
                                })
                            )
                            : <option><Loading /></option>
                    }
                </select>
            }
            <DownloadButton 
                enabledClassName={enabledClassName} 
                endpoint={endpointFunc(selection)}
                filename={filenameFunc(selection)}
            />
            </div>
        </div>
    )
}

function DownloadsMenu({ popupOpen, setPopupOpen, menuText, rowData, children = null }) {
    const { examObject } = useContext(ExamContext);
    const examName = examObject.name ?? "this exam";

    return (
        <Popup
            open={popupOpen}
            closeOnDocumentClick={false}
            onClose={() => setPopupOpen(false)}
            modal
        >
            <div className="bg-white rounded-md shadow-lg p-10 space-y-2">
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-2 items-center">
                        <StatsUpSquare height={30} width={30} strokeWidth={2.0}/>
                        <h2 className="text-lg font-bold"><b>{menuText}</b></h2>
                    </div>
                    <Download height={30} width={30} strokeWidth={2.0}/>
                </div>
                <Divider />
                <div>
                    {children}
                </div>
                <Divider />
                <div className="px-5 space-y-3">
                    {
                        rowData.map((r, i) => r && <DownloadRow key={i} {...r}/>)
                    }
                </div>
                <Divider />
                <div className="flex flex-row justify-between items-center my-4">
                    <div className="flex flex-row gap-2 items-center">
                        <FloppyDiskArrowOut/>
                        <p>{examName} Output</p>
                        <DownloadButton
                            enabledClassName={"bg-zanista-yellow-light"}
                            endpoint={GoodPointApi.Output.Download(examObject.id)}
                            filename={`${standardSlugify(examName)}_exam_output.zip`}
                        />
                    </div>
                    <button 
                        className={
                            `flex flex-row gap-1 p-1 pl-2 rounded-md ` +
                            (`btn-clickable bg-zanista-yellow-light `)
                        }
                        onClick={() => setPopupOpen(false)}
                    >
                        <p>Close</p> <Xmark />
                    </button>
                </div>
            </div>
        </Popup>
    )
}

export function DownloadsMenuButton({ text, downloadsIcon, rowData, children = null }) {
    const [popupOpen, setPopupOpen] = useState(false);

    return <>
        <button 
            className="btn-clickable flex flex-row gap-1 p-1 pr-3 px-2 bg-zanista-orange-mid rounded-md "
            onClick={() => setPopupOpen(true)}
        ><Download width={20}/><p>{text}</p></button>
        <DownloadsMenu 
            {...{popupOpen, setPopupOpen, menuText: text, menuIcon: downloadsIcon, rowData, children}}
        />
    </>
}

