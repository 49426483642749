import { loadFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";

export function getGrading(examId) {
    return loadFromLocalStorage(StorageKeys.GRADING_RESULTS, {})[examId];
}

export function setGrading(examId, allGrading) {
    const store = loadFromLocalStorage(StorageKeys.GRADING_RESULTS, {});
    store[examId] = allGrading;
    return saveToLocalStorage(StorageKeys.GRADING_RESULTS, store);
}

export function getGradingClassFeedback(examId) {
    const grading = getGrading(examId) ?? {};
    return grading["class_feedback"];
}

export function saveGradingClassFeedback(examId, generalFeedback) {
    const grading = getGrading(examId) ?? {};
    grading["class_feedback"] = generalFeedback;
    return setGrading(examId, grading);
}

export function getAllGradingFeedback(examId) {
    const store = getGrading(examId) ?? {};
    return store["student_feedback"];
}

export function setAllGradingFeedback(examId, studentFeedback) {
    const store = getGrading(examId) ?? {};
    store["student_feedback"] = studentFeedback;
    return setGrading(examId, store);
}

export function getGradingFeedbackById(examId, studentId) {
    const classFeedback = getAllGradingFeedback(examId) ?? {};
    return classFeedback[studentId];
}

export function saveGradingFeedbackbyId(examId, studentId, feedback) {
    const studentGrading = getAllGradingFeedback(examId) ?? {};
    studentGrading[studentId] = feedback;
    return setAllGradingFeedback(examId, studentGrading);
}

export function getGradingQuestionsFeedbackById(examId, studentId) {
    const studentFeedback = getGradingFeedbackById(examId, studentId) ?? {};
    return studentFeedback["per_question"];
}

export function saveGradingQuestionsFeedbackById(examId, studentId, questionFeedback) {
    const studentFeedback = getGradingFeedbackById(examId, studentId) ?? {};
    studentFeedback["per_question"] = questionFeedback;
    return saveGradingFeedbackbyId(examId, studentId, studentFeedback);
}

export function getGradingOverallFeedbackbyId(examId, studentId) {
    const studentFeedback = getGradingFeedbackById(examId, studentId) ?? {};
    return studentFeedback["overall_feedback"];
}

export function saveGradingOverallFeedbackById(examId, studentId, overallFeedback) {
    const studentFeedback = getGradingFeedbackById(examId, studentId) ?? {};
    studentFeedback["overall_feedback"] = overallFeedback;
    return saveGradingFeedbackbyId(examId, studentId, studentFeedback);
}


export function getGradingGeneralResults(examId) {
    const grading = getGrading(examId) ?? {};
    return grading["general_results"];
}

export function saveGradingGeneralResults(examId, results) {
    const grading = getGrading(examId) ?? {};
    grading["general_results"] = results;
    return setGrading(examId, grading);
}

export function getGradingAllStudentsResults(examId) {
    const grading = getAllGradingFeedback(examId);
    if (!grading) return undefined;
    return Object.fromEntries(
        Object
            .entries(grading)
            .map(([studentId, data]) => {
                return [studentId, {
                    "student_id": studentId,
                    "grade": data["grade"],
                    "total_marks": data["total_marks"]
                }];
            })
    );
}

export function saveGradingAllStudentsResults(examId, allStudentsResults) {
    const grading = (getAllGradingFeedback(examId) ?? {})["student_feedback"] ?? {};
    return setAllGradingFeedback(examId,
        Object.fromEntries(
            Object
                .entries(allStudentsResults)
                .map(([studentId, data]) => {
                    const f = grading[studentId] ?? {};
                    const new_data = { ...f, ...data };
                    return [studentId, new_data];
                })
        )
    );
}


export function getOverallQuestionFeedback(examId) {
    const store = getGrading(examId) ?? {};
    return store["question_feedback"];
}

export function saveOverallQuestionFeedback(examId, newQuestionFeedback) {
    const store = getGrading(examId) ?? {};
    store["question_feedback"] = newQuestionFeedback;
    return setGrading(examId, store);
}

export function getClassDistImgSrc(examId) {
    const store = getGrading(examId) ?? {};
    if (store["class_dist_img_src"]) {
        return store["class_dist_img_src"];
    } else {
        return null;
    }
}

export function saveClassDistImgSrc(examId, classDistImgSrc) {
    const store = getGrading(examId) ?? {};
    store["class_dist_img_src"] = classDistImgSrc;
    return setGrading(examId, store);
}

//for one student 
export function getStudentQuantileImgById(examId, studentId) {
    const store = getGrading(examId) ?? {};
    if (store["quantile_imgs"] && store["quantile_imgs"][studentId]) {
        return store["quantile_imgs"][studentId];
    } else {
        return null;
    }
}

export function saveStudentQuantileImgById(examId, studentId, quantileImg) {
    const store = getGrading(examId) ?? {};
    if (!store["quantile_imgs"]) {
        store["quantile_imgs"] = {};
    }
    store["quantile_imgs"][studentId] = quantileImg;
    return setGrading(examId, store);
}