import { GraphUp, PageEdit } from 'iconoir-react';
import './Dashboard.css';
import Title from 'src/components/content/Title';
import Footer from 'src/pages/dashboard/Footer';
import { useHasRole } from 'src/api/useGetRole';
import OchreIcon from 'src/pages/ochre/OchreIcon';
import { Link } from 'react-router-dom';


export const PRODUCT_COLS = 4
export const MIN_PRODUCTS = 4

export const EDTECH_PRODUCTS = [
    {
        name: "GoodPoint",
        subtitle: "AI Exam Grader",
        Icon: PageEdit,
        bgColour: "zanista-orange-light",
        features: [
            "Automatic grading and feedback",
            "Exam performance assessment"
        ],
        link: "/goodpoint",
        adminsOnly: false,
    }
]

export const FINANCE_PRODUCTS = [
    {
        name: "NewsWitch",
        subtitle: "Tailored Financial Reports",
        Icon: GraphUp,
        bgColour: "zanista-orange-light",
        features: [
            "News aggregation",
            "Sentiment analysis",
        ],
        link: "/newswitch",
        adminsOnly: false,
    }
]

export const MISC_PRODUCTS = [
    {
        name: "Ochre",
        subtitle: "OCR Document Scanner",
        Icon: OchreIcon,
        bgColour: "zanista-yellow-light",
        features: [
            "LaTeX and JSON output",
            "Automatic PDF rendering"
        ],
        link: "/ochre",
        adminsOnly: true,
    }
]


function Card({ link = null, index, className, children }) {
    return <Link
        to={link}
        className={`
            flex flex-col py-12 rounded-big items-center justify-center 
            ${(link !== null) ? "btn-clickable" : "cursor-disabled"}
            ${className}
        `}
        style={{
            gridRow: Math.floor(index / PRODUCT_COLS) + 1,
            gridColumn: index % PRODUCT_COLS + 1
        }}
    >
        {children}
    </Link>
}

function ProductCard({ product, index, iconSize = 100, iconStroke = 0.75 }) {
    const { Icon, name, bgColour, subtitle, features, link } = product;
    return (
        <Card link={link} className={`bg-${bgColour ?? "zanista-orange-light"} border-mid`} index={index}>
            <Icon width={iconSize} height={iconSize} strokeWidth={iconStroke} />
            <h2 className="product-name font-size-x-large font-weight-bold text-align-center">{name}</h2>
            <p className="product-subtitle text-zanista-orange-dark font-weight-bold margin-small text-align-center">
                {subtitle}
            </p>
            <ul>
                {
                    features.map((feature, index) => {
                        return <li className="font-size-small" key={index}>{feature}</li>
                    })
                }
            </ul>
        </Card>
    )
}

function ComingSoonCard({ index }) {
    return (
        <Card className="bg-slate-300 border-thin border-grey" index={index}>
            <p className="fg-grey font-italic font-size-x-large position-relative text-align-center margin-mid">
                Coming Soon...
            </p>
        </Card>
    )
}


export default function Dashboard() {
    var allProducts = [...EDTECH_PRODUCTS, ...FINANCE_PRODUCTS, ...MISC_PRODUCTS]
    const isAdmin = useHasRole("admin");
    if (!isAdmin) {
        allProducts = allProducts.filter((p) => !p.adminsOnly);
    }

    return <div className='dashboard'>
        <div className="centered main">
            <div className="container" id="main-dashboard">
                <Title title="Dashboard">
                    <p>Select a product to get started</p>
                </Title>
                <div className="product-panel flex-col">
                    {
                        Array(Math.max(MIN_PRODUCTS, allProducts.length))
                            .fill()
                            .map((_, i) => {
                                return (i < allProducts.length)
                                    ? <ProductCard key={i} product={allProducts[i]} index={i} />
                                    : <ComingSoonCard key={i} index={i} />
                            })
                    }
                </div>
            </div>
        </div>
        <Footer />
    </div>;
}