import { useCallback } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

export default function useFetchTickers() {
    const callApi = useNewsWitchApi();

    const fetchTickers = useCallback(() => {
        return callApi("GET", "/tickers")?.then(response => {
            if (response.status === 200) {
                return response.json().then(body => {
                    return [...new Set(Object.values(body["tickers"]))];
                });
            } else {
                console.error(response);
                return [];
            }
        });
    }, [callApi]);

    return fetchTickers;
}
