import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import OchreApi from "src/api/ochre/OchreApi";
import { protectedResources } from "src/auth/AuthConfig";
import useAccessToken from "src/auth/useAccessToken";
import Divider from "src/components/layout/Divider";
import ProgressBar from "src/components/ui/ProgressBar";
import { ScansListContext } from "src/pages/ochre/dashboard/ScansListContext";


export default function UploadScans() {
    const { setScans } = useContext(ScansListContext);
    const [progress, setProgress] = useState(null);
    const [isAutoProcess, setIsAutoProcess] = useState(false);
    const msalRequest = { scopes: protectedResources.apiOchre.scopes.read }
    const accessToken = useAccessToken(msalRequest);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => handleUploadAll(acceptedFiles),
        multiple: true,
    });

    const handleUpload = useCallback(async (file) => {
        if (!accessToken) return;
        const formData = new FormData();
        formData.append("files", file)
        try {
            await axios
                .post(OchreApi.Upload(isAutoProcess), formData, {
                    headers: {
                        "Content-Type": 'multipart/form-data',
                        "Authorization": `Bearer ${accessToken}`
                    },
                    onUploadProgress: (event) => {
                        if (event.total > 0) {
                            const factor = event.loaded / event.total;
                            setProgress((p) => { return {...p, [file.name]: factor} });
                        }
                    },
                });
            console.log('Files uploaded successfully!');
        } catch (error) {
            console.error(`Error uploading files: `, error);
        }
    }, [accessToken, isAutoProcess]);

    const handleUploadAll = useCallback(async (files) => {
        setProgress(Object.fromEntries(files.map(f => [f.name, 0.0])));
        await Promise.all(files.map((file) => handleUpload(file)));
        setProgress(null);
        setScans(null);
    }, [handleUpload, setScans]);

    return <>
        <div className="flex flex-row justify-between items-center">
            <h2>Upload</h2>
            <div className="flex flex-row items-center gap-2">
                <p>Enable auto processing?</p>
                <input 
                    className="mt-2 mb-2 p-5 w-6 h-6 accent-zanista-orange border border-zanista-grey-mid"
                    checked={isAutoProcess} onChange={() => setIsAutoProcess(ap => !ap)} type="checkbox"
                />
            </div>
        </div>
        <Divider />
        {
            (progress === null) 
                ? (
                    <div 
                        className={
                            "flex flex-row border-2 border-dashed border-zanista-orange-mid " + 
                            "justify-center p-12 cursor-pointer"
                        }
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <p className="pointer-events-none text-zanista-orange">
                            Drag & drop files here, or click to upload files (.pdf)
                        </p>
                    </div>
                )
                : (
                    <div className="space-y-3">
                        {
                            Object.entries(progress).map(([filename, progress], i) => 
                                <ProgressBar key={i} progress={progress} height={10} text={filename}/>
                            )
                        }
                    </div>
                )
        }
    </>
}