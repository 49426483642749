import { useContext, useState, useCallback } from "react";
import useCallApi from "src/api/useCallApi";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { protectedResources } from "src/auth/AuthConfig";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { Tab } from "src/api/goodpoint/Const";


export default function useResetTab(examId, tab) {
    const { updateExamState } = useContext(ExamContext);
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write }
    });

    const [isResetting, setReseting] = useState(false);

    const resetTab = useCallback((afterReset) => {
        setReseting(true);
        var endpoint;
        switch (tab) {
            case Tab.RUBRIC: endpoint = GoodPointApi.Rubric.Reset(examId); break;
            case Tab.ANSWERS: endpoint = GoodPointApi.Answers.Reset(examId); break;
            case Tab.GRADING: endpoint = GoodPointApi.Grading.Reset(examId); break;
            default: endpoint = null;
        }
        if (!endpoint) return;
        callApi("POST", endpoint)
            ?.then(response => {
                if (response.ok) {
                    response.json().then(body => {
                        const stateUpdate = body["data"]["state"];
                        updateExamState(stateUpdate);
                    })
                } else {
                    console.error(`Failed to reset tab ${tab}: ${response.status} ${response.statusText}`);
                }
            }).finally(() => {
                setReseting(false);
                afterReset && afterReset();
            });
    }, [callApi, examId, tab, updateExamState]);

    return {
        resetTab,
        isResetting
    };
}