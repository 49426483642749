import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { GradingImages } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import Divider from "src/components/layout/Divider";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import ClassGeneralFeedback from "src/pages/goodpoint/exam/tabs/grading/class-grading/ClassGeneralFeedback";
import { AiGenerated } from "src/components/content/exam/StructureSection";
import useCallApi from "src/api/useCallApi";
import Loading from "src/components/ui/Loading";
import { ReactComponent as BoxPlotIcon } from "src/assets/boxplot.svg";
import { ReactComponent as LineGraphIcon } from "src/assets/linegraph.svg";
import { calcGrade, EditGradeBoundaries } from "src/pages/goodpoint/exam/tabs/grading/GradeBoundaries";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";


function ClassGradingPlot({ plotType, Icon, alt }) {
    const { examObject } = useContext(ExamContext);
    const callApi = useCallApi();

    const [popupOpen, setPopupOpen] = useState(false);
    const [plotImage, setPlotImage] = useState(null);

    useEffect(() => {
        if (plotImage !== null) return;
        callApi("GET", GoodPointApi.Grading.Plots.Class(examObject.id, plotType))
            ?.then(response => {
                if (response.status === 200) {
                    response.blob().then(img => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setPlotImage(reader.result);
                        }
                        reader.readAsDataURL(img);
                    })
                }
            });
    }, [callApi, examObject, plotType, plotImage]);

    return <>
        <div className="w-full object-contain">
            {
                plotImage
                    ? <img 
                        className={
                            "cursor-pointer flex-shrink-0 transform transition-transform duration-200 hover:scale-105 " +
                            "shadow-lg hover:shadow-2x1 rounded-lg"
                        }
                        src={plotImage} alt={alt}
                        onClick={() => setPopupOpen(true)}
                    />
                    : <div className="w-full object-contain flex-shrink-0">
                        <div className="relative flex flex-row w-full justify-center items-center shadow-lg">
                            <Icon className="opacity-10 h-96 flex-shrink-0" />
                            <div className="absolute inset-0 flex justify-center items-center rounded-lg">
                                <Loading />
                            </div>
                        </div>
                    </div>
            }
        </div>
        <Popup open={popupOpen} closeOnDocumentClick={true} onClose={() => setPopupOpen(false)}>
            <div className='bg-white rounded-small w-full h-full'>
                <img src={plotImage} alt={alt} />
            </div>
        </Popup>
    </>
}

function ClassResults() {
    const { examResults, gradeBoundaries } = useContext(GradingContext);

    // const readOnly = useHasRole("student");

    const avgMarks = examResults?.["avg_marks"];
    const maxMarks = examResults?.["max_marks"];
    const percent = 100.0 * avgMarks / maxMarks;
    const grade = calcGrade(percent, gradeBoundaries);

    return (
        examResults 
            ? <div className="flex-row align-center justify-between">
                <div className="flex flex-row gap-2 align-center">
                    <h1 className="font-semibold">{percent?.toFixed(1)}%</h1>
                    <div>
                        <p>{avgMarks?.toFixed(1)}/{maxMarks} Marks</p>
                        <p>Grade {grade}</p>
                    </div>
                </div>
                {/* {
                    !readOnly && <ShareButton examId={examObject.id} />
                } */}
            </div>
            : <Loading />
    )
}

export default function ClassStats() {

    return <>
        <div className="class-stats shadow-slate-500 shadow-[0px_0px_10px] rounded-lg w-full p-4">
            <div className="flex-row justify-content-space-between">
                <div className="flex-row align-baseline gap-small">
                    <h2 className="text-xl"><b>Class Results</b></h2>
                    <AiGenerated />
                </div>
            </div>
            <div className="flex flex-row align-center justify-between">
                <ClassResults/>
                <EditGradeBoundaries/>
            </div>
            <Divider />
            <div className="flex flex-row max-w-full gap-5 p-5">
                <ClassGradingPlot 
                    plotType={GradingImages.OVERALL_QUESTION_BOXPLOT}
                    Icon={BoxPlotIcon}
                    alt="Question Marks Boxplot"
                />
                <ClassGradingPlot 
                    plotType={GradingImages.STUDENTS_DISTRIBUTION}
                    Icon={LineGraphIcon}
                    alt="Students Distribution"
                />
            </div>

            <Divider />
            <ClassGeneralFeedback />
        </div>
    </>
}