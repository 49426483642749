import { useCallback, useContext, useState } from "react";
import { InfoCircleSolid, TrashSolid, LineSpace, Xmark, Eye } from "iconoir-react";
import { EditMode } from "src/pages/goodpoint/exam/ExamContext";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import AreYouSureModal from "./AreYouSureModal";
import { ReactComponent as PdfIcon } from 'src/assets/PdfIcon.svg';
import { VisibilityContext } from "src/components/content/exam/Visibility";
import { Check } from "iconoir-react";
import { useHasRole } from "src/api/useGetRole";
import SearchBar from "src/components/content/SearchBar";
import HoverIconButton from "src/components/ui/HoverIconButton";


//don't ask me why isSelectionMode is separate from here, it's because i just refactored somebody else'code
function ShowHideButton({ isSelectionMode, setIsSelectionMode }) {
    const { clear, hiddenCount } = useContext(VisibilityContext);

    const handleClick = () => {
        setIsSelectionMode(mode => !mode);
    };

    return (
        <div className="flex-row align-center gap-mid">
            <HoverIconButton
                Icon={Eye}
                onClick={handleClick}
                tooltip="Select visible"
            />
            {
                hiddenCount > 0 && !isSelectionMode &&
                <div
                    className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer"
                    onClick={() => clear()}
                >
                    <p>{hiddenCount} total hidden (Clear)</p>
                    <Xmark strokeWidth={1.0} />
                </div>
            }
        </div>
    );
}

export default function EditToolbar({
    isSelectionMode,
    setIsSelectionMode,
    clearSearchBar,
    onChangeSearchText,
    clearSearchTerms,
    // TODO: Don't show edit buttons for students
    listDisplay = [0, 0, 0, 0, 0, 0] // Controls which buttons are shown
}) {
    const { deleteSelected, setDeleteSelected, editMode, setEditMode } = useContext(ExamContext);
    const { setShowPDF } = useContext(ExamContext);
    const [discardPopupOpen, setDiscardPopupOpen] = useState(false);

    // Don't show editing buttons for students
    // const readOnly = useHasRole("student");
    // if (readOnly) {
    //   listDisplay = [0, 0, 0, 1, 1, 1];
    // }

    function SafeUseHasRole(role) {
        try {
            return useHasRole(role);
        } catch (error) {
            console.error("Error in useHasRole:", error);
            return true;
        }
    }

    // Inside your component
    const readOnly = SafeUseHasRole("student");

    // a student 
    if (readOnly && !listDisplay) {
        listDisplay = [0, 0, 0, 1, 1, 1];
    }

    const deleteQuestion = useCallback(() => {
    }, []);

    return (
        <div>
            <div className="flex-row mb-2 justify-between">
                {editMode === EditMode.DEFAULT && !isSelectionMode && (
                    <>
                        <div className="flex flex-row items-center gap-2">
                            <>
                                {
                                    listDisplay[0] === 1 &&
                                    <HoverIconButton 
                                        Icon={LineSpace}
                                        onClick={() => setEditMode(EditMode.REORDER)}
                                        tooltip={"Change order"} 
                                    />
                                }
                                {
                                    listDisplay[1] === 1 &&
                                    <HoverIconButton 
                                        Icon={TrashSolid}
                                        onClick={() => setEditMode(EditMode.DELETE)}
                                        tooltip={"Delete questions"} 
                                        strokeWidth={1.0}
                                    />
                                }
                            </>
                            {listDisplay[2] === 1 && (
                                <ShowHideButton
                                    isSelectionMode={isSelectionMode}
                                    setIsSelectionMode={setIsSelectionMode}
                                />
                            )}
                        </div>
                        {listDisplay[3] === 1 && (
                            <SearchBar
                                clearRef={clearSearchBar}
                                onChangeSearchText={onChangeSearchText}
                                onClear={clearSearchTerms}
                                className="w-3/5 -ml-6"
                            />
                        )}
                        <div className="flex-row gap-2">
                            {
                                listDisplay[4] === 1 &&
                                <HoverIconButton
                                    onClick={() => setShowPDF((prevShowPDF) => !prevShowPDF)}
                                    tooltip="Show PDF"
                                    Icon={PdfIcon}
                                />
                            }
                            {
                                listDisplay[5] === 1 &&
                                <HoverIconButton
                                    tooltip={!readOnly ? "Click on marks to edit" : ""}
                                    tooltipPlacement="right"
                                    Icon={InfoCircleSolid}
                                    disabled={readOnly}
                                />
                            }
                        </div>
                    </>
                )}

                {editMode === EditMode.REORDER && !isSelectionMode && (
                    <>
                        <div className="flex items-center gap-2">
                            <HoverIconButton
                                Icon={Check}
                                tooltip="Done?"
                                tooltipPlacement="left"
                                onClick={() => setEditMode(EditMode.DEFAULT)}
                            />
                            <p className="">Drag questions to change order</p>
                        </div>
                    </>
                )}

                {
                    isSelectionMode &&
                    <>
                        <div className="flex items-center gap-2">
                            <HoverIconButton
                                tooltip="Done"
                                tooltipPlacement="top"
                                onClick={() => { setEditMode(EditMode.DEFAULT); setIsSelectionMode(false) }}
                                Icon={Check}
                            />
                            <p className="">Toggle visible questions</p>
                        </div>
                    </>
                }

                {editMode === EditMode.DELETE && !isSelectionMode && (
                    <>
                        <div className="flex items-center gap-2">
                            <HoverIconButton
                                Icon={Xmark}
                                tooltip="Cancel"
                                tooltipPlacement="left"
                                onClick={() => { setEditMode(EditMode.DEFAULT); setDeleteSelected([]); }}
                            />
                            <p>Select questions to delete</p>
                            {
                                deleteSelected.length > 0 &&
                                <HoverIconButton
                                    Icon={TrashSolid}
                                    onClick={() => { setDiscardPopupOpen(true); }}
                                    tooltip="Delete questions"
                                    tooltipPlacement="right"
                                    colour="zanista-red"
                                />
                            }
                        </div>
                        <AreYouSureModal
                            popupOpen={discardPopupOpen}
                            setPopupOpen={setDiscardPopupOpen}
                            onClosePopup={() => setDiscardPopupOpen(false)}
                            title={"Delete questions"}
                            onConfirm={deleteQuestion}
                            danger={true}
                            confirmContent={"Delete"}
                            cancelContent={"Cancel"}
                        >
                            Are you sure you want to delete these questions?
                        </AreYouSureModal>
                    </>
                )}
            </div>

        </div >
    );
}
