import { useCallback } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

export default function useFetchSearchTicker() {
    const callApi = useNewsWitchApi();

    const fetchSearchTicker = useCallback((ticker, name = null, exchange = null) => {
        return callApi("GET", "/ticker/search?" + new URLSearchParams(
            // Always send ticker, conditionally send name and exchange
            Object.assign(
                { ticker: ticker },
                name ? { name: name } : {},
                exchange ? { exchange: exchange } : {}
            )
        ).toString())?.then(response => {
            if (response.status === 200) {
                return response.json().then(body => {
                    if (body["results"] === undefined) {
                        return [];
                    }
                    return body["results"];
                });
            } else if (response.status === 404) {
                return [];
            } else {
                console.error(response);
                return [];
            }
        });
    }, [callApi]);

    return fetchSearchTicker;
}
