import { useCallback, useState } from "react";
import useCallApi from "src/api/useCallApi";

export function useGeneralBlobView() {
    const callApi = useCallApi();
    const [isFetching, setIsFetching] = useState(false);

    const view = useCallback((blobEndpoint) => {
        setIsFetching(true);
        return callApi("GET", blobEndpoint)
            ?.then(async response => {
                if (response.status === 200) {
                    // Get the blob data
                    const blob = await response.blob();
                    // Download blob as a file from a link
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, "_blank", "noopener,noreferrer");
                    // window.URL.revokeObjectURL(url);
                } else {
                    console.error(
                        `Failed to fetch blob for viewing: ${response.status} ${response.statusText} \n` +
                        `from (GET) ${blobEndpoint}`
                    );
                }
            })
            .finally(() => {
                setIsFetching(false);
            });
    }, [callApi]);

    return { view, isFetching };
}

export function useBlobView(blobEndpoint) {
    const { view, isFetching } = useGeneralBlobView();
    return {
        "view": () => view(blobEndpoint),
        isFetching
    };
}