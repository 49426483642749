import { EmptyPage, Eye, AlignLeft } from "iconoir-react";


export default function OchreIcon(props) {
    return (
        <div className="relative">
            <EmptyPage {...props}/>
            <Eye 
                width={props.width / 2.5} height={props.height / 2.5} strokeWidth={props.strokeWidth * 2.5}
                color={props.color}
                className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-3/4'
            />
            <AlignLeft 
                width={props.width / 3} height={props.height / 3} strokeWidth={props.strokeWidth * 2}
                color={props.color}
                className='absolute top-1/3 left-1/2 transform -translate-x-1/2 translate-y-1/2'
            />
        </div>
    )
}