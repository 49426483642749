import ReactJson from "@microlink/react-json-view";
import { Check, Page } from "iconoir-react";
import { useContext, useEffect, useMemo, useState } from "react";
import OchreApi from "src/api/ochre/OchreApi";
import useCallApi from "src/api/useCallApi";
import PdfViewer from "src/components/content/PdfViewer";
import { ScanContext } from "src/pages/ochre/scan/ExamContext";
import ScanDownloads from "src/pages/ochre/scan/ScanDownloads";


function PdfViews() {
    const callApi = useCallApi();
    const { scanObject, originalLink, setOriginalLink, processedLink, setProcessedLink } = useContext(ScanContext);
    const outputs = scanObject?.outputs ?? {};

    useEffect(() => {
        if (!scanObject || originalLink) return;
        callApi("GET", OchreApi.Scan.Link(scanObject.id, scanObject["source_file"]))
            ?.then(response => {
                if (response.ok) {
                    response.json().then(body => {
                        const url = body["data"]["url"];
                        setOriginalLink(url);
                    });
                }
            })
    });

    useEffect(() => {
        if (!outputs || processedLink) return;
        callApi("GET", OchreApi.Scan.Link(scanObject.id, outputs["pdf_filename"]))
            ?.then(response => {
                if (response.ok) {
                    response.json().then(body => {
                        const url = body["data"]["url"];
                        setProcessedLink(url);
                    });
                }
            })
    });

    const originalPdfViewer = useMemo(() => {
        // @ts-ignore
        return <PdfViewer url={originalLink} title="original" height="1200px" />;
    }, [originalLink]);

    const processedPdfViewer = useMemo(() => {
        // @ts-ignore
        return <PdfViewer url={processedLink} title="processed" height="1200px" />;
    }, [processedLink]);

    return (
        <div className="w-full grid grid-cols-2 gap-8">
            <div className="space-y-2">
                <h2>Original</h2>
                {originalPdfViewer}
            </div>
            <div className="space-y-2">
                <h2>Processed</h2>
                {processedPdfViewer}
            </div>
        </div>
    )
}


function JsonData() {
    const { scanObject } = useContext(ScanContext);
    const [copied, setCopied] = useState(false);
    return (
        <div className="space-y-2">
            <div className="flex flex-row justify-between items-center">
                <h2>JSON Data</h2>
                <button 
                    className=
                    "flex gap-1 align-center btn-clickable bg-zanista-orange border-thin rounded-small px-2 py-1"
                    onClick={() => {
                        navigator.clipboard
                            .writeText(
                                JSON.stringify(scanObject, null, 4)
                            )
                            .then(() => setCopied(true))
                    }}
                    onMouseEnter={() => setCopied(false)}
                    onBlur={() => setCopied(false)}
                >
                    {copied ? <Check width={20} height={20}/> : <Page width={20} height={20}/>}
                    <p>{copied ? "Copied" : "Copy"}</p>
                </button>
            </div>
            <div className="border border-solid border-slate-300 p-3 rounded-md text-xs text-wrap">
                <ReactJson
                    name="scan data"
                    src={scanObject} 
                    style={{}} 
                    theme="summerfruit:inverted"
                />
            </div>
        </div>
    )
}


export default function ScanCompleted() {
    return (
        <div className="space-y-12">
            <PdfViews/>
            <JsonData/>
            <div className="centered fixed w-full bottom-0 left-0 p-3 bg-white shadow-[0_0_20px_rgba(0,0,0,0.3)]">
                <div className="container w-full flex flex-row justify-between align-center">
                    <ScanDownloads/>
                </div>
            </div>
        </div>
    )
}