import './ComingSoonPage.css'
import { Link } from 'react-router-dom';
import Footer from 'src/pages/dashboard/Footer';
import logo from 'src/assets/logo.png'

function ComingSoon({ title = undefined, subtitle = undefined, buttons = undefined }) {

    if (buttons === undefined) {
        buttons = [
            {
                text: "Request a demo",
                link: "mailto:info@zanista.ai?subject=Request a demo&body=Hi, I would like to set up a short meeting to find out more about your products. Please reach out to me by email." 
            },
            {
                text: "Find out more",
                link: "https://zanista.ai"
            }
        ]
    }

    return (
        <div className='coming-soon-screen centered'>
            <Link to="/">
                <img src={logo} alt="Zanista AI" />
            </Link>
            <h2>Thanks for signing up</h2>
            <p>
                Set up a short meeting to find out more about our products
            </p>
            <div className='row'>
                {
                    buttons.map((button, index) => {
                        return (
                            <Link to={button.link} key={index}
                                className='coming-soon-button btn-clickable'>
                                {button.text}
                            </Link>
                        )
                    })
                }
            </div>
        <Footer />
        </div>
    );
}

export default ComingSoon;
