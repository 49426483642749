import { CheckSquareSolid, Square, FastArrowUp, FastArrowDown } from "iconoir-react";
import { useContext, useState, useMemo } from "react";
import QuestionFeedbackStructure from "src/components/content/exam/grading-structure/question-feedback/QuestionFeedbackStructure";
import Divider from "src/components/layout/Divider";
import ClassGradingContext from "src/pages/goodpoint/exam/tabs/grading/class-grading/ClassGradingContext";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";


export default function QuestionFeedback() {
    const { gradeBoundaries } = useContext(GradingContext);
    const { questionFeedback } = useContext(ClassGradingContext);

    const [isFilterOnlyMistakes, setIsFilterOnlyMistakes] = useState(false);
    const [orderBy, setOrderBy] = useState("index");
    const [isAscendingOrder, setIsAscendingOrder] = useState(true);

    const sortedQuestionFeedback = useMemo(() => {
        if (!questionFeedback) return null;
        return questionFeedback
            .sort((feedback1, feedback2) => {
                var result =
                    (orderBy === "percentage")
                        ? (
                            (feedback1["marks"] / feedback1["max_marks"]) - 
                            (feedback2["marks"] / feedback2["max_marks"])
                        )
                        : feedback1[orderBy] - feedback2[orderBy];
                return isAscendingOrder ? result : -result;
            });
    }, [isAscendingOrder, orderBy, questionFeedback]);

    const hasMistakes = useMemo(() => {
        if (!questionFeedback) return;
        return Object.values(questionFeedback).some(
            (feedback) => feedback["marks"] < feedback["max_marks"]
        );
    }, [questionFeedback]);

    return (
        <div className="feedback-container">
            <div className="flex flex-row items-center justify-between">
                <div
                    className={`feedback-mistakes-filter flex-row align-center margin-small ${hasMistakes ? "" : "translucent"
                        }`}
                >
                    <div
                        className={`${hasMistakes ? "cursor-pointer" : "pointer-disabled"}`}
                        onClick={() =>
                            hasMistakes && setIsFilterOnlyMistakes(!isFilterOnlyMistakes)
                        }
                    >
                        {isFilterOnlyMistakes ? (
                            <CheckSquareSolid color="var(--zanista-orange-dark)" />
                        ) : (
                            <Square color="var(--zanista-orange-dark)" />
                        )}
                    </div>
                    <p className="margin-small">Show only mistakes</p>
                </div>
                <div className="feedback-order-by flex-row align-center margin-small">
                    <p>Order by:</p>
                    <select
                        className="order-by-select padding-x-small font-size-large margin-small"
                        value={orderBy}
                        onChange={(event) => setOrderBy(event.target.value)}
                    >
                        <option value="index">Question Number</option>
                        <option value="marks">Marks</option>
                        <option value="percentage">Percentage</option>
                    </select>
                    <div
                        className="cursor-pointer"
                        onClick={() => setIsAscendingOrder(!isAscendingOrder)}
                    >
                        {isAscendingOrder ? (
                            <FastArrowUp color="var(--zanista-orange-dark)" />
                        ) : (
                            <FastArrowDown color="var(--zanista-orange-dark)" />
                        )}
                    </div>
                </div>
            </div>
            <Divider />
            <QuestionFeedbackStructure
                questionFeedback={sortedQuestionFeedback}
                // setQuestionFeedback={setQuestionFeedback}
                isFilterOnlyMistakes={isFilterOnlyMistakes}
                gradeBoundaries={gradeBoundaries}
            />
        </div>
    );
}
