import { useCallback, useState } from "react";
import useCallApi from "src/api/useCallApi";
import { protectedResources } from "src/auth/AuthConfig";


export default function useSendEdit(endpoint) {
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write }
    });
    const [isSending, setIsSending] = useState(false);

    const sendEdit = useCallback((action, keys, value, onSuccess = undefined, onFail = undefined) => {
        const postBody = [{ action, keys, value }];
        return callApi("PUT", endpoint, { body: postBody })
            ?.then(response => {
                setIsSending(false);
                if (response.ok) {
                    onSuccess && onSuccess();
                } else {
                    console.error(`Failed to put edit: (${response.status}) ${response.statusText}`);
                    onFail && onFail();
                }
            });
    }, [callApi, endpoint]);

    return { sendEdit, isSending };
}