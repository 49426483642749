import { Archive, Download, Page, PageStar, Xmark } from "iconoir-react";
import { useCallback, useContext, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import Popup from "reactjs-popup";
import OchreApi from "src/api/ochre/OchreApi";
import { useGeneralBlobDownload } from "src/api/useBlobDownload";
import Divider from "src/components/layout/Divider";
import Loading from "src/components/ui/Loading";
import OchreIcon from "src/pages/ochre/OchreIcon";
import { ScanContext } from "src/pages/ochre/scan/ExamContext";


export default function ScanDownloads() {
    const { scanObject, originalLink, processedLink } = useContext(ScanContext);
    const sourceFile = scanObject?.["source_file"];

    const [popupOpen, setPopupOpen] = useState(false);

    function DownloadButton({ enabledClassName, endpoint, filename }) {
        const { download, isDownloading } = useGeneralBlobDownload();
        return (
            <button 
                className={
                    "flex flex-row items-center gap-1 p-2 px-2 rounded-md " +
                    ((!isDownloading && endpoint) ? `btn-clickable ${enabledClassName}` : "bg-slate-300")
                }
                onClick={() => endpoint && download(endpoint, filename)}
                disabled={isDownloading || !endpoint}
            >
                { 
                    (isDownloading || !endpoint)
                        ? <TailSpin strokeWidth={2.0} color="black" width={27} height={27} /> 
                        : <Download /> 
                }
            </button>
        )
    }

    function DownloadButtonNoAuth({ enabledClassName, endpoint, filename }) {
        const [isDownloading, setIsDownloading] = useState(false);
        const download = useCallback((url, file) => {
                setIsDownloading(true);
                fetch(url)
                    .then(async response => {
                        if (response.ok) {
                            const blob = await response.blob();
                            const downloadedUrl = window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = downloadedUrl;
                            link.download = file;
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                            window.URL.revokeObjectURL(downloadedUrl);
                        }
                    })
                    .finally(() => setIsDownloading(false));
            }, []);
        return (
            <button 
                className={
                    "flex flex-row items-center gap-1 p-2 px-2 rounded-md " +
                    ((!isDownloading && endpoint) ? `btn-clickable ${enabledClassName}` : "bg-slate-300")
                }
                onClick={() => endpoint && download(endpoint, filename)}
                disabled={isDownloading || !endpoint}
            >
                { 
                    (isDownloading || !endpoint)
                        ? <TailSpin strokeWidth={2.0} color="black" width={27} height={27} /> 
                        : <Download /> 
                }
            </button>
        )
    }

    function DownloadRow({ 
        DownloadButtonComponent = DownloadButton,
        Icon, text, endpointFunc, filenameFunc, enabledClassName = null,
        selectOptions = undefined, defaultSelection = undefined
    }) {
        const [selection, setSelection] = useState(
            () => {
                if (defaultSelection) return defaultSelection;
                if (selectOptions && selectOptions.length > 0) {
                    const first = selectOptions[0];
                    if (Array.isArray(first)) return first[0]
                    else return first;
                }
            }
        );

        return (
            <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-2 items-center">
                    <Icon /><p>{text}</p>
                </div>
                <div className="flex flex-row gap-2 items-center">
                {
                    (selectOptions !== undefined) &&
                    <select 
                        className=
                            "px-2 py-2 rounded-md border border-slate-300 transition hover:border-black"
                        defaultValue={defaultSelection}
                        onChange={(e) => setSelection(e.target.value)}
                    >
                        {
                            selectOptions
                                ? (
                                    selectOptions.map((o, i) => {
                                        return Array.isArray(o) 
                                            ? <option key={i} value={o[0]}>{o[1]}</option>
                                            : <option key={i} value={o}>{o}</option>
                                    })
                                )
                                : <option><Loading /></option>
                        }
                    </select>
                }
                <DownloadButtonComponent 
                    enabledClassName={enabledClassName} 
                    endpoint={endpointFunc(selection)}
                    filename={filenameFunc(selection)}
                />
                </div>
            </div>
        )
    }

    return <>
        <button 
            className="btn-clickable flex flex-row gap-1 p-1 px-2 bg-zanista-orange-mid rounded-md "
            onClick={() => setPopupOpen(true)}
        ><Download /><p>Downloads</p></button>
        
        <Popup 
            open={popupOpen}
            closeOnDocumentClick={true}
            onClose={() => setPopupOpen(false)}
            modal
        >
            <div className="bg-white rounded-md shadow-lg p-10 space-y-2">
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-2 items-center">
                        <OchreIcon height={30} width={30}/><h2>Scan Downloads</h2>
                    </div>
                    <Download height={30} width={30}/>
                </div>
                <div>
                    <p>Click on a button below to download a specific version of the {sourceFile} document scan.</p>
                    <p>Or, click on "All Output" to download everything generated by the OCR process.</p>
                </div>
                <Divider />
                <div className="px-5 space-y-3">
                    <DownloadRow
                        DownloadButtonComponent={DownloadButtonNoAuth}
                        Icon={Page}
                        text={"Original file"}
                        endpointFunc={() => originalLink}
                        filenameFunc={() => sourceFile}
                        enabledClassName={"bg-zanista-yellow-light"}
                    />
                    <DownloadRow
                        DownloadButtonComponent={DownloadButtonNoAuth}
                        Icon={PageStar}
                        text={"Processed scan"}
                        endpointFunc={() => processedLink}
                        filenameFunc={() => `processed_${sourceFile}`}
                        enabledClassName={"bg-zanista-orange-mid"}
                    />
                    <DownloadRow
                        Icon={Archive}
                        text={"All output (.zip)"}
                        endpointFunc={() => OchreApi.Scan.Output.Download(scanObject.id)}
                        filenameFunc={() => `output_${sourceFile.split(".")[0]}.zip`}
                        enabledClassName={"bg-zanista-orange"}
                    />
                </div>
                <Divider />
                <div className="flex flex-row justify-end items-center my-4">
                    <button 
                        className={
                            `flex flex-row gap-1 p-1 pl-2 rounded-md ` +
                            (`btn-clickable bg-zanista-yellow-light `)
                        }
                        onClick={() => setPopupOpen(false)}
                    >
                        <p>Close</p> <Xmark />
                    </button>
                </div>
            </div>
        </Popup>
    </>
}