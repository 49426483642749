import { Collapse, Expand } from "iconoir-react";
import { useCallback, useContext, useState } from "react";
import { useCollapse } from "react-collapsed";
import RichMathEditor from "src/components/ui/RichMathEditor";
import { EditPencil } from "iconoir-react";
import { AiGenerated } from "src/components/content/exam/StructureSection";
import RenderMath from "src/components/ui/RenderMath";
import LoadingText from "src/components/ui/LoadingText";
import { useHasRole } from "src/api/useGetRole";
import ClassGradingContext from "src/pages/goodpoint/exam/tabs/grading/class-grading/ClassGradingContext";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useSendEdit from "src/api/goodpoint/useSendEdit";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { tokenizeText } from "src/components/content/exam/StructureUtil";
import { EditAction } from "src/api/goodpoint/Const";
import HoverIconButton from "src/components/ui/HoverIconButton";


export default function ClassGeneralFeedback() {
    const { examObject } = useContext(ExamContext);
    const { classFeedback, setClassFeedback } = useContext(ClassGradingContext);
    const { sendEdit, isSending } = 
        useSendEdit(GoodPointApi.Grading.Edit.Class(examObject.id));

    const onClickSave = useCallback((newContent) => {
        sendEdit(EditAction.MODIFY, ["class_feedback"], newContent, () => {
            setClassFeedback(newContent);
            setIsEdit(false);
        });
    }, [sendEdit, setClassFeedback]);

    const [isEdit, setIsEdit] = useState(false);
    const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
        useCollapse({
            collapsedHeight: 120,
            hasDisabledAnimation: true,
        });

    const readOnly = useHasRole("student");

    return (
        <div className="class-feedback">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-small align-baseline">
                    <h2 className="text-lg font-bold"><b>Class Feedback</b></h2>
                    <AiGenerated />
                </div>
                <div className="flex flex-row gap-3">
                    {
                        !readOnly &&
                        <HoverIconButton
                            Icon={EditPencil}
                            onClick={() => { setIsEdit(true); setExpanded(true); }}
                            tooltip="Edit content"
                            tooltipPlacement="left"
                            disabled={isEdit}
                        />
                    }
                    <button 
                        className={
                            `btn-clickable flex flex-row gap-1 p-1 px-2 items-center rounded-md ` + 
                            `${isExpanded ? "bg-zanista-orange-mid" : "bg-zanista-yellow-light"}`
                        }
                        {...getToggleProps()}
                    >
                        {
                            isExpanded 
                            ? <><Collapse width="1.2em" strokeWidth={2.0}/><p>Collapse</p></>
                            : <><Expand width="1em" strokeWidth={2.0}/><p>Expand</p></>
                        }
                    </button>
                </div>
            </div>
            <div {...getCollapseProps()}>
                {!classFeedback && <LoadingText />}
                {
                    classFeedback && !isEdit && 
                    <RenderMath tokens={tokenizeText(classFeedback)} />
                }
                {
                    classFeedback && isEdit &&
                    <RichMathEditor
                        initialTokens={tokenizeText(classFeedback)}
                        isSaving={isSending}
                        saveContent={onClickSave}
                        closeEditor={() => setIsEdit(false)}
                    />
                }
            </div>
        </div>
    );
}
