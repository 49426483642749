import { IconButton, Tooltip } from "@mui/material";

export default function HoverIconButton({ 
    Icon, 
    onClick = undefined,
    tooltip = undefined,
    tooltipPlacement = "top",
    size = 30, 
    strokeWidth = 2.0,
    disabled = false,
    colour = "slate-500"
}) {
    return (
        <Tooltip title={tooltip} placement={tooltipPlacement} disableInteractive={!tooltip}>
            <div className={
                `flex flex-row ${!disabled ? "hover:scale-125 transition duration-200" : "pointer-events-none"} items-center`
            }>
                <IconButton onClick={onClick} disabled={disabled}>
                    <Icon width={size} height={size} strokeWidth={strokeWidth} className={`text-${colour}`}/>
                </IconButton>
            </div>
        </Tooltip>
    )
}