import { useMemo, useState, useContext, useCallback } from "react";
import { Tooltip } from "@mui/material";
import { useHasRole } from "src/api/useGetRole";
import { ExamStructureContext } from "src/components/content/exam/ExamStructureContext";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import StructureSection from "src/components/content/exam/StructureSection";
import { StructureItem } from "src/components/content/exam/StructureItem";
import RubricSteps from "src/components/content/exam/rubric-structure/RubricSteps";
import { isAnyFlagged } from "src/components/content/exam/StructureUtil";


function RubricItemHeader({ itemData, nestingLevel }) {
    const flagged = useMemo(() => isAnyFlagged(itemData), [itemData]);
    const numParts = useMemo(() => {
        const subQuestions = itemData.sub_items;
        return (subQuestions ?? []).length;
    }, [itemData.sub_items]);

    const handleMarkChange = useCallback((_event) => {
        // const newMark = parseFloat(event.target.value) || 0;
    }, []);

    const readOnly = useHasRole("student");

    return (
        <div className="w-full">
            <div className="flex-row flex justify-between">
                <p className=""><b>{nestingLevel > 0 ? "Part " : ""} {itemData.name}</b></p>
                {
                    flagged && <p className="text-right text-zanista-red">(NEEDS REVIEW)</p>
                }
                {numParts > 0 && (<p className="">{numParts} Parts</p>)}
                <Tooltip
                    title={
                        readOnly ? "" :
                            itemData.hierarchy.length === 0
                                ? "Adjusts all related subquestion marks."
                                : (itemData.sub_items || []).length === 0
                                    ? "Affects parent question's total marks."
                                    : "Impacts both subquestions and parent marks."
                    }
                    placement="right"
                >
                    <div className="justify-self-right">
                        {
                            readOnly
                                ? <p>{itemData?.max_marks} Marks</p>
                                : <div className="flex flex-row gap-1 align-center">
                                    <input
                                        type="number"
                                        key={`${itemData.name}_${itemData?.max_marks}`} //forces rerender
                                        onClick={(event) => event.stopPropagation()} //stops item from expanding on click
                                        onBlur={handleMarkChange}
                                        defaultValue={itemData.max_marks}
                                        className={`w-12 bg-white outline-black focus:outline-accent text-right`}
                                        disabled={readOnly}
                                    />
                                    Marks
                                </div>
                        }
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}

export function RubricItemContent({ itemData, editKeysHierarchy }) {
    const { itemCommonProps } = useContext(ExamStructureContext);
    const isEnhanced = itemCommonProps.isEnhanced;

    const [questionText, setQuestionText] = useState(itemData["question_content"]);
    const [description, setDescription] = useState(itemData["description"]);
    const [result, setResult] = useState(itemData["result"]);
    const [solutionText, setSolutionText] = useState(itemData["solution_content"]);

    return (
        <div className="rubric-item-content">
            {questionText && (
                <StructureSection
                    heading={"Question Content"}
                    content={questionText}
                    setContent={setQuestionText}
                    editKeys={[...editKeysHierarchy, "question_content"]}
                />
            )}
            {isEnhanced && description && (
                <StructureSection
                    heading={"Description"}
                    content={description}
                    setContent={setDescription}
                    editKeys={[...editKeysHierarchy, "description"]}
                    aiGenerated
                />
            )}
            {isEnhanced && result && (
                <StructureSection
                    heading={"Result"}
                    content={result}
                    setContent={setResult}
                    editKeys={[...editKeysHierarchy, "result"]}
                    aiGenerated
                />
            )}
            {solutionText && (
                <StructureSection
                    heading={"Solution Content"}
                    content={solutionText}
                    setContent={setSolutionText}
                    editKeys={[...editKeysHierarchy, "solution_content"]}
                />
            )}
            {isEnhanced && itemData.steps && (
                <RubricSteps steps={itemData.steps} editKeysHierarchy={editKeysHierarchy} />
            )}
        </div>
    );
}

export function RubricItem(props) {
    const {
        editMode,
        setEditMode,
    } = useContext(ExamContext);

    return (
        <StructureItem
            HeaderComponent={RubricItemHeader}
            ContentComponent={RubricItemContent}
            editMode={editMode}
            setEditMode={setEditMode}
            {...props}
        />
    );
}

export function EnhancedRubricItem({ itemData, nestingLevel = 0 }) {
    return (
        <RubricItem
            itemData={itemData}
            enhanced={true}
            nestingLevel={nestingLevel}
        />
    );
}
