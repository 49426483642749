import { useCallback } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

export default function useFetchPriceData() {
    const callApi = useNewsWitchApi();

    const fetchPriceData = useCallback((ticker, endDate, startDate = undefined, ppp = true) => {
        return callApi("GET", "/price-data?" + new URLSearchParams({
            ticker: ticker,
            event_date: endDate.toISOString(),
            ppp: ppp.toString(),
            start_date: startDate ? startDate.toISOString() : ""
        }).toString())?.then(response => {
            if (response.status === 200) {
                return response.json().then(body => {
                    if (body["prices"][ticker] === undefined) {
                        return [];
                    }
                    return body["prices"][ticker];
                });
            } else {
                console.error(response);
                return [];
            }
        });
    }, [callApi]);

    return fetchPriceData;
}
