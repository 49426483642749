import BackLink from 'src/components/nav/BackLink';
import { NewExam } from "src/pages/goodpoint/dashboard/ExamItem"
import { StepState } from 'src/api/goodpoint/Const';
import ExamMenu from 'src/pages/goodpoint/dashboard/ExamMenu';
import useExamsList from 'src/pages/goodpoint/dashboard/useExamsList';
import { ExamsListContext } from 'src/pages/goodpoint/dashboard/ExamsListContext';
import { useLayoutEffect, useState } from 'react';
import ScrollToTopButton from 'src/components/nav/ScrollToTopButton';
import { useHasRole } from 'src/api/useGetRole';
import { MultiplePages, StatsUpSquare } from 'iconoir-react';
import LoadingTab from 'src/components/ui/LoadingTab';


export function AIGraderDashboard() {
    const { exams, setExams, queriedServer } = useExamsList();
    const isStudent = useHasRole("student");

    const [isLoading, setIsLoading] = useState(true);
    useLayoutEffect(() => {
        if (queriedServer || (exams && Object.keys(exams).length > 0)) {
            setIsLoading(false);
        }
    }, [exams, queriedServer]);

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Dashboard" href="/dashboard" />
                <div className="flex flex-row items-center justify-between">
                    <h1>GoodPoint</h1>
                    <p>Create a new exam {Object.keys(exams).length > 0 && " or select a previous one"}</p>
                </div>
                {
                    isLoading
                        ? <LoadingTab />
                        : <>
                            {
                                !isStudent && 
                                <NewExam />
                            }
                            {
                                isStudent && Object.keys(exams).length === 0 &&
                                <div className="flex-col gap-large justify-center h-16 mx-2">
                                    <p>No exams have been shared with this account</p>
                                </div>
                            }
                            <ExamsListContext.Provider value={{ exams, setExams }}>
                                {/* Show graded exams only to students */}
                                {
                                    !isStudent && 
                                    <ExamMenu
                                        Icon={MultiplePages}
                                        title="Ongoing Exams" 
                                        filter={item => item.state.grading !== StepState.COMPLETED}
                                        itemColour={"zanista-yellow-light"}
                                        hoverColour={"zanista-orange-mid"}
                                    />
                                }
                                <ExamMenu 
                                    Icon={StatsUpSquare}
                                    title="Graded Exams" 
                                    filter={item => item.state.grading === StepState.COMPLETED}
                                    itemColour={"zanista-orange-light"}
                                    hoverColour={"zanista-orange-mid"}
                                />
                            </ExamsListContext.Provider>
                        </>
                }
            </div>
            <ScrollToTopButton />
        </div>
    );
}