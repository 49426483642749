import { useMemo, useState, useEffect, useContext } from "react";
import Divider from "src/components/layout/Divider";
import { StructureItem } from "src/components/content/exam/StructureItem";
import StructureSection from "src/components/content/exam/StructureSection";
import { getFromStructure, isAnyFlagged } from "src/components/content/exam/StructureUtil";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";


function AnswerItemHeader({ itemData, nestingLevel }) {
    const numParts = useMemo(() => {
        const subQuestions = itemData.sub_items;
        return Object.keys(subQuestions ?? {}).length;
    }, [itemData.sub_items]);
    const flagged = useMemo(() => isAnyFlagged(itemData), [itemData]);

    return (
        <div className="answers-item-header fill-width">
            <p className="answers-item-question-tag justify-self-left">
                <b>
                    {nestingLevel > 0 ? "Part " : ""}
                    {itemData.name}
                </b>
            </p>
            {
                flagged && <p className="text-right text-zanista-red">(NEEDS REVIEW)</p>
            }
            {numParts > 0 && (
                <p className="answers-item-parts justify-self-right">
                    {numParts} Parts
                </p>
            )}
        </div>
    );
}

function AnswerItemContent({ itemData, editKeysHierarchy }) {
    const { rubric } = useContext(RubricContext);

    const questionContent = (
        rubric && editKeysHierarchy && getFromStructure(rubric, [...editKeysHierarchy, "question_content"])
    );
    // Need a setSolution to immediately show the change on the screen without collapsing the questions
    const [solution, setSolution] = useState(itemData["answer_content"]);

    // Need this to ensure the content on the screen changes when u switch to another student
    useEffect(() => {
        setSolution(itemData["answer_content"]);
    }, [itemData]);

    return (
        <div className="student-answer-content pt-2">
            {
                questionContent &&
                <StructureSection
                    heading={"Question Content"}
                    content={questionContent}
                    hide
                />
            }
            {
                solution &&
                <StructureSection
                    heading={"Student Answer"}
                    content={solution}
                    setContent={setSolution}
                    editKeys={[...editKeysHierarchy, "answer_content"]}
                />
            }
            {
                !(solution || itemData["sub_items"].length > 0) &&
                <>
                    <p>(empty)</p>
                    <Divider />
                </>
            }
        </div>
    );
}

export default function AnswerItem(props) {
    return (
        <StructureItem
            HeaderComponent={AnswerItemHeader}
            ContentComponent={AnswerItemContent}
            {...props}
        />
    );
}
