import { useContext, useLayoutEffect } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import FileUploader from "src/components/ui/FileUploader";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


export default function RubricAvailable() {
    const { examObject, updateExamState, setFooterControls } = useContext(ExamContext);
        
    useLayoutEffect(() => {
        setFooterControls(null);
    }, [setFooterControls]);

    return (
        <FileUploader
            title="Upload Rubric"
            text=".pdf"
            uploadEndpoint={GoodPointApi.Rubric.Upload(examObject.id)}
            onUpload={(data) => {
                const newState = data["new_state"];
                updateExamState(newState);
            }}
            formDataKey="rubrics"
        />
    )
}