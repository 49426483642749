import { useState, useContext, useEffect } from "react";
import { ExamItem } from "src/pages/goodpoint/dashboard/ExamItem";
import Divider from "src/components/layout/Divider";
import { Check } from "iconoir-react";
import dayjs from "dayjs";
import { ExamsListContext } from "src/pages/goodpoint/dashboard/ExamsListContext";
import { useHasRole } from "src/api/useGetRole";
import useDeleteExam from "src/pages/goodpoint/dashboard/useDeleteExam";
import AreYouSureModal from "src/components/ui/AreYouSureModal";
import TrashHover from "src/components/ui/TrashHover";


function DeleteExamButton({ examItem }) {
    const { deleteExam } = useDeleteExam();
    const [popupOpen, setPopupOpen] = useState(false);

    return (
        <div>
            <TrashHover onClick={() => setPopupOpen(true)} text={undefined} />
            <AreYouSureModal
                popupOpen={popupOpen}
                setPopupOpen={setPopupOpen}
                onClosePopup={() => setPopupOpen(false)}
                title={"Delete exam"}
                onConfirm={
                    () => deleteExam(examItem.id, () => setPopupOpen(false))
                }
                danger={true}
                confirmContent={"Delete"}
            >
                {`Are you sure you want to delete ${examItem != null ? examItem.name : ""}?`}
            </AreYouSureModal>
        </div>
    )
}

export default function ExamMenu({ Icon, title, filter, itemColour, hoverColour }) {
    const { exams } = useContext(ExamsListContext);
    const [editMode, setEditMode] = useState(false);
    const readOnly = useHasRole("student");

    useEffect(() => {
        // Stop editing after we delete an exam
        setEditMode(false);
    }, [exams]);

    const items = Object.values(exams)?.filter(filter) ?? [];
    if (items.length === 0) {
        return null
    }

    return (
        <div className='exam-menu mt-8'>
            <div className="flex flex-row items-center justify-between pl-1 pb-2 pr-3">
                <div className="flex flex-row gap-2 items-center">
                    <Icon width="25" height="25"/>
                    <h2 className="text-xl">{title}</h2>
                </div>
                {
                    !readOnly &&
                    <TrashHover 
                        onClick={() => setEditMode(e => !e)}
                        text={editMode ? "Finish deleting?" : "Select exams to delete?"}
                        Icon={editMode ? Check : undefined} IconHover={editMode ? Check : undefined}
                    />
                }
            </div>
            <Divider />
            <div className="flex flex-col gap-4">
                {
                    items.sort((a, b) => dayjs(a.date).isBefore(b.date) ? -1 : 1).map((item, index) => (
                        <div className={`flex flex-row gap-1 items-center ${editMode ? "pr-3" : null}`}>
                            <ExamItem
                                key={`${title} ${item.id} ${index}`}
                                item={item}
                                clickable={!editMode}
                                colour={itemColour}
                                hoverColour={hoverColour}
                            />
                            {
                                editMode && <DeleteExamButton examItem={item} />
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}