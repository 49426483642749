import { Skeleton, Stack } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { LogType, StepState } from "src/api/goodpoint/Const";
import useCallApi from "src/api/useCallApi";
import { protectedResources } from "src/auth/AuthConfig";
import LogProgress from "src/components/ui/LogProgress";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { LogUpdateContext } from "src/pages/goodpoint/exam/LogUpdateContext";


export default function ProcessingWaitPage({ 
    logsEndpoint,
    stateName,
    text = "Processing...",
    onError = null
}) {
    const { examState, updateExamState, isDeleting } = useContext(ExamContext);
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write },
    });

    const [lastLog, setLastLog] = useState(null);
    const [isFinished, setIsFinished] = useState(examState[stateName] > StepState.PROCESSING);
    const [isError, setIsError] = useState(false);
    const [calledOnError, setCalledOnError] = useState(false);

    const getLogs = useCallback(() => {
        if (isDeleting) return;
        callApi("GET", logsEndpoint)
            ?.then(
                (response) => {
                    if (response.ok) {
                        response.json().then((body) => {
                            const logs = body["data"]["logs"];
                            const log = logs[0];
                            setLastLog(log);

                            if (log) {
                                switch (log["log_type"]) {
                                    case LogType.SUCCESS: setIsFinished(true); break;
                                    case LogType.ERROR:
                                    case LogType.FAIL:
                                        if (onError && !calledOnError) {
                                            setCalledOnError(true);
                                            onError();
                                        }
                                        setIsError(true); break;
                                    default: break;
                                }
                            }
                        });
                    }
                }
            );
    }, [callApi, calledOnError, isDeleting, logsEndpoint, onError]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isError) {
                return;
            }
            if (!isFinished) {
                getLogs();
            } else {
                clearInterval(intervalId);
                updateExamState({[stateName]: StepState.COMPLETED});
            }
        }, 2000);
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [getLogs, isError, isFinished, stateName, updateExamState]);

    useEffect(() => {
        if (lastLog) return;
        getLogs();
    }, [getLogs, lastLog]);

    return (
        <div className="centered">
            <div className="margin-x-large gap-mid flex-col align-center w-1/2">
                {
                    lastLog
                        ? <LogUpdateContext.Provider value={{ lastLog, setLastLog }}>
                            {
                                // processingError
                                //     ? <p>Insufficient funds.</p>
                                //     : 
                                <LogProgress text={text} />
                            }
                        </LogUpdateContext.Provider>
                        : <Stack className="mt-1 w-full">
                            <Skeleton variant="text" sx={{ fontSize: '10rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </Stack>
                }
            </div>
        </div>
    )
}