import GoodPointApi from 'src/api/goodpoint/GoodPointApi';

const OchreApi = {
    Base: `${GoodPointApi.Base}/ochre`
}

OchreApi.User = `${OchreApi.Base}/user`

OchreApi.ListScans = `${OchreApi.Base}/scans`
OchreApi.Upload = (autoProcess = false) => `${OchreApi.Base}/upload?auto_process=${autoProcess}`
OchreApi.DeleteList = `${OchreApi.Base}/delete`

const Scan = (id) => `${OchreApi.Base}/scan/${id}`
Scan.Delete = (id) => `${Scan(id)}/delete`
Scan.Process = (id) => `${Scan(id)}/process`
Scan.Logs = (id, numLogs = 1) => `${Scan(id)}/logs?num_logs=${numLogs}`
Scan.Link = (id, filename) => `${Scan(id)}/link/${filename}`
Scan.Output = {
    Download: (id) => `${Scan(id)}/output/download`
}
OchreApi.Scan = Scan

export default OchreApi;