import useCallApi from "src/api/useCallApi";
import { useEffect } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { loadExamDescs, updateLocalExams } from "src/storage/ExamDesc";
import { useState } from "react";


export default function useExamsList() {
    // Don't use useMemoFetch here because we want to query the server every time we render
    const callApi = useCallApi();
    const [exams, setExams] = useState(loadExamDescs());
    const [queriedServer, setQueriedServer] = useState(false);

    useEffect(() => {
        if (queriedServer) return;
        callApi("GET", GoodPointApi.ListExams)
            ?.then(response => {
                if (response.status === 200) {
                    response.json().then(body => {
                        const data = body["data"]
                        const exams = {};
                        data["exams"].forEach(e => {
                            exams[e.id] = e;
                        });
                        const n_exams = updateLocalExams(exams)
                        setExams(n_exams);
                        setQueriedServer(true);
                    });
                } else {
                    console.error("Failed to fetch exams: " + response.status + " " + response.statusText);
                }
            });
    }, [callApi, queriedServer, setExams]);

    return { exams, setExams, queriedServer }
}