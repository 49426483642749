import { useContext, useState, useCallback } from "react";
import useCallApi from "src/api/useCallApi";
import { protectedResources } from "src/auth/AuthConfig";
import { ScansListContext } from "src/pages/ochre/dashboard/ScansListContext";
import OchreApi from "src/api/ochre/OchreApi";


export default function useDeleteScan() {
    const { setScans } = useContext(ScansListContext);
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write }
    });

    const [deleting, setDeleting] = useState(false);

    const deleteScan = useCallback((id, afterDelete) => {
        setDeleting(true);
        callApi("POST", OchreApi.Scan.Delete(id))
            ?.then(response => {
                if (response.status === 200 || response.status === 400) {
                    console.log(`Deleted scan ${id}`);
                    setScans((scans) => {
                        const result = {...scans};
                        delete result[id];
                        return result;
                    });
                } else {
                    console.error(`Failed to delete scan: ${response.status} ${response.statusText}`);
                }
            }).finally(() => {
                setDeleting(false);
                afterDelete && afterDelete();
            });
    }, [callApi, setScans]);

    return {
        deleteScan,
        deleting
    };
}