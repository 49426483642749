import { capitalize } from "src/util/StringUtil"
import { NavArrowRight, NavArrowLeft, InfoCircle, GraduationCap, StatsUpSquare, Page } from "iconoir-react";
import "./Tabs.css"
import { Tab, TabState } from "src/api/goodpoint/Const";
import { useHasRole } from "src/api/useGetRole";

export const TabIcons = ({
    [Tab.INFO]: InfoCircle,
    [Tab.RUBRIC]: Page,
    [Tab.ANSWERS]: GraduationCap,
    [Tab.GRADING]: StatsUpSquare
});

function TabButton({ tab, tabState, changeTab }) {
    const isStudent = useHasRole("student");
    const disabled = !(!isStudent || tab === Tab.GRADING || tab === Tab.RUBRIC);
    const Icon = TabIcons[tab];
    return (
        <button 
            className={
                `flex flex-row justify-between w-full p-2 px-3 rounded-md text-lg hover:scale-105 ` +
                `${tabState === TabState.COMPLETE ? "bg-zanista-yellow-light" : ""} ` +
                `${tabState === TabState.CURRENT ? "bg-zanista-orange font-bold" : ""} ` +
                `${tabState === TabState.NEXT ? "bg-zanista-yellow-light text-zanista-orange-dark" : ""} ` +
                `${tabState === TabState.INCOMPLETE ? "bg-slate-300 text-slate-600 pointer-events-none" : "btn-clickable"} ` +
                `duration-100`
            }
            disabled={disabled}
            onClick={() => { if (!isStudent || tab === Tab.GRADING || tab === Tab.RUBRIC) changeTab(tab) }}
        >
            <p className="text-lg">{capitalize(tab)}</p>
            {Icon && <Icon width="1.2em"/>}
        </button>
    )
}

function NavTabItem({ tab, rightNav, changeTab }) {
    if (tab) {
        return <button
            className={
                `tab-button btn-clickable nav-button ${rightNav ? "nav-right" : "nav-left"} border-mid rounded-small`
            }
            onClick={() => changeTab(tab)}
        >
            {
                rightNav ? <NavArrowRight /> : <NavArrowLeft />
            }
        </button>
    }
    return <></>;
}

export function ExamTabs({ tabsState, changeTab, currentTab }) {
    const rightTab = Object.keys(tabsState)[Object.keys(tabsState).indexOf(currentTab) + 1];
    const leftTab = Object.keys(tabsState)[Object.keys(tabsState).indexOf(currentTab) - 1];
    return (
        <div className="exam-tabs flex-row gap-large">
            <NavTabItem tab={leftTab} rightNav={false} changeTab={changeTab} />
            {
                Object.entries(tabsState).map(([tab, tabState]) => {
                    return (
                        <TabButton key={tab} tab={tab} tabState={tabState} changeTab={changeTab} />
                    )
                })
            }
            <NavTabItem tab={rightTab} rightNav={true} changeTab={changeTab} />
        </div>
    )
}