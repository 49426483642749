import Divider from "src/components/layout/Divider";
import { useNavigate, useParams } from "react-router-dom";
import useCallApi from "src/api/useCallApi";
import { useContext, useEffect, useState } from "react";
import OchreApi from "src/api/ochre/OchreApi";
import AreYouSureModal from "src/components/ui/AreYouSureModal";
import { Trash, TrashSolid } from "iconoir-react";
import useDeleteScan from "src/pages/ochre/dashboard/useDeleteScan";
import BackLink from "src/components/nav/BackLink";
import { ScanContext } from "src/pages/ochre/scan/ExamContext";
import { Stack, Skeleton } from "@mui/material";
import { StepState } from "src/api/goodpoint/Const";
import ScanCompleted from "src/pages/ochre/scan/ScanCompleted";
import ScanProcessing from "src/pages/ochre/scan/ScanProcessing";


function DeleteScanButton() {
    const { scanObject } = useContext(ScanContext);
    const { deleteScan } = useDeleteScan();
    const navigate = useNavigate();
    const [popupOpen, setPopupOpen] = useState(false);
    return <>
        <div className="relative inline-block flex items-center justify-center text-zanista-red">
            <button 
                className="group peer p-0 pt-1/2 h-6 w-6 transition-scale hover:scale-125 duration-300 ml-2"
                onClick={() => setPopupOpen(true)}
            >
                <Trash className=
                    "absolute top-0 h-6 w-6 transition-opacity opacity-100 group-hover:opacity-0 duration-300"/>
                <TrashSolid className=
                    "absolute top-0 h-6 w-6 transition-opacity opacity-0 group-hover:opacity-100 duration-300"/>
                {/* Increase the btn-clickable area around the button using an invisible span */}
                <span className="absolute inset-0 -m-2"></span>
            </button>
            <span className={
                `absolute top-1/2 -translate-y-1/2 text-nowrap opacity-0 translate-x-4 transition-all duration-300 ` +
                `right-full peer-hover:translate-x-0 peer-hover:opacity-100 cursor-default pointer-events-none`
            }>
                Delete scan?
            </span>
        </div>
        <AreYouSureModal
            {...{popupOpen, setPopupOpen}}
            onClosePopup={() => setPopupOpen(false)}
            title={"Delete scan"}
            onConfirm={
                () => deleteScan(scanObject.id, () => { setPopupOpen(false); navigate("/ochre"); })
            }
            danger={true}
            confirmContent={"Delete"}
        >
            {`Are you sure you want to delete ${scanObject}?`}
        </AreYouSureModal>
    </>
}


function LoadingScanSkeleton() {
    return <div className="centered">
        <div className="container">
            <BackLink prevPage="Ochre" href="/ochre" />

            <Stack className="mt-1 px-4">
                <Skeleton variant="text" sx={{ fontSize: '4rem' }} />

                <div className="grid grid-cols-4 gap-4 -mt-4">
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </div>

                <div className="h-8"></div>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

                <div className="h-1"></div>
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </Stack>
        </div>
    </div>
}


export default function ExamPage() {
    const { scanId } = useParams();
    const callApi = useCallApi();
    const [scanObject, setScanObject] = useState(null);
    const [originalLink, setOriginalLink] = useState(null);
    const [processedLink, setProcessedLink] = useState(null);
    
    // Fetch exam object from API on load
    useEffect(() => {
        if (scanObject) return;
        callApi("GET", OchreApi.Scan(scanId))
            ?.then((response) => {
                if (response.status === 200) {
                    response.json().then((body) => {
                        const scan = body["data"]["scan"];
                        setScanObject(scan);
                    });
                } else {
                    console.error(
                        `Failed to fetch scan ${scanId}: ${response.status} ${response.statusText}`
                    );
                }
            });
    }, [callApi, scanObject, scanId]);

    if (!scanObject) {
        return <LoadingScanSkeleton />;
    }

    const PageComponent = () => {
        switch (scanObject.state) {
            case StepState.PROCESSING:
                return <ScanProcessing/>
            case StepState.COMPLETED:
                return <ScanCompleted/>
            default:
                return null
        }
    }

    return (
        <div className="centered">
            <div className="container exam-details">
                <ScanContext.Provider
                    value={{
                        scanObject,
                        scanState: scanObject["state"],
                        originalLink,
                        setOriginalLink,
                        processedLink,
                        setProcessedLink
                    }}
                >
                    <div className="flex flex-row justify-between align-center">
                        <BackLink prevPage="Ochre" href="/ochre" />
                        {(scanObject?.state !== StepState.PROCESSING) && <DeleteScanButton />}
                    </div>
                    <h1>{scanObject["source_file"]}</h1>
                    <p className="ml-auto">ID: {scanId}</p>
                    <Divider/>
                    <PageComponent/>
                </ScanContext.Provider>
            </div>
        </div>
    );
}