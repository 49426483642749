import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useMemoFetch from "src/api/useMemoFetch";
import { getGradingClassFeedback, getGradingOverallFeedbackbyId, saveGradingClassFeedback, saveGradingOverallFeedbackById } from "src/storage/GradingStorage";


export function useFetchClassFeedback(id) {
    const cacheGet = useCallback(() => getGradingClassFeedback(id), [id]);
    const cacheSave = useCallback((feedback) => saveGradingClassFeedback(id, feedback), [id]);

    const processResponse = useCallback((response) => {
        return response
            .json().then(
                body => {
                    return body["data"];
                }
            );
    }, []);

    return useMemoFetch({
        apiEndpoint: GoodPointApi.Grading.Feedback.Class(id),
        processResponse, cacheGet, cacheSave
    });
}

export function useFetchStudentFeedback(id, student_id) {
    const cacheGet = useCallback(() => getGradingOverallFeedbackbyId(id, student_id), [id, student_id]);
    const cacheSave = useCallback((feedback) => saveGradingOverallFeedbackById(id, student_id, feedback), [id, student_id]);

    const processResponse = useCallback((response) => {
        return response.json().then(body => body["data"]);
    }, []);

    return useMemoFetch({
        apiEndpoint: GoodPointApi.Grading.Feedback.Student(id, student_id),
        processResponse, cacheGet, cacheSave
    });
}