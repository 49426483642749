import { Trash, TrashSolid } from "iconoir-react";


export default function TrashHover({ onClick, text = undefined, Icon = Trash, IconHover = TrashSolid}) {
    return (
        <div className="relative inline-block flex items-center justify-center text-zanista-red">
            <button 
                className="group peer p-0 pt-1/2 h-6 w-6 transition-scale hover:scale-125 duration-300 ml-2"
                onClick={onClick}
            >
                <Icon className="absolute top-0 h-6 w-6 transition-opacity opacity-100 group-hover:opacity-0 duration-300"/>
                <IconHover className="absolute top-0 h-6 w-6 transition-opacity opacity-0 group-hover:opacity-100 duration-300"/>
                {/* Increase the clickable area around the button using an invisible span */}
                <span className="absolute inset-0 -m-2"></span>
            </button>
            {
                text &&
                <span className={
                    `absolute top-1/2 -translate-y-1/2 text-nowrap opacity-0 translate-x-4 transition-all duration-300 ` +
                    `right-full peer-hover:translate-x-0 peer-hover:opacity-100 cursor-default pointer-events-none`
                }>
                    {text}
                </span>
            }
        </div>
    )
}