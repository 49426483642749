import { createContext } from "react";

const GradingContext = createContext({
    gradingType: null,
    setGradingType: (_t) => {},
    examResults: null,
    gradeBoundaries: null,
    setGradeBoundaries: (_gb) => {},
    clickedStudent: null,
    setClickedStudent: (_cs) => {}
});

export default GradingContext;