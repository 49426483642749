import Popup from "reactjs-popup";
import "./AreYouSureModal.css"
import { Check, WarningTriangleSolid, Xmark } from "iconoir-react";
import { useState } from "react";
import { TailSpin } from "react-loader-spinner";


export default function AreYouSureModal({
    popupOpen,
    setPopupOpen,
    title,
    onClosePopup,
    onConfirm = undefined,
    onCancel = undefined,
    danger = false,
    confirmContent = "Confirm",
    cancelContent = "Cancel",
    ConfirmIcon = Check,
    CancelIcon = Xmark,
    children = null
}) {
    const [confirmClicked, setConfirmClicked] = useState(false);
    return (
        <Popup
            className="popup-content"
            open={popupOpen}
            closeOnDocumentClick={false}
            onClose={() => {setConfirmClicked(false); onClosePopup();}}
        >
            <div className="bg-white rounded-md shadow-lg p-10">
                <div className="px-2 py-2 text-2xl font-semibold border-b-2 border-slate-400">
                    {danger && (
                        <WarningTriangleSolid className="text-yellow-500 text-2xl inline-block mb-2 mr-2" />
                    )}
                    {title}
                </div>
                <div className="py-5 text-lg">
                    {children}
                </div>

                <div className="flex-row gap-large mt-5 justify-between">
                    <button
                        className={
                            `flex flex-row gap-1 rounded-md p-2 pr-3 text-lg bg-zanista-yellow-light ` +
                            (!confirmClicked ? "btn-clickable" : "pointer-events-none")
                        }
                        onClick={() => {
                            setConfirmClicked(false);
                            if (onCancel === undefined) {
                                setPopupOpen(false);
                            } else {
                                onCancel();
                            }
                        }}
                        disabled={confirmClicked}
                    >
                        {CancelIcon && <CancelIcon width="1.2em" strokeWidth={2.0}/>}
                        {cancelContent}
                    </button>

                    <button
                        className={
                            `flex flex-row gap-1 rounded-md p-2 pr-3 text-lg ` +
                            (!confirmClicked 
                                ? "btn-clickable bg-zanista-orange-mid" 
                                : "bg-slate-300 pointer-events-none"
                            )
                        }
                        onClick={() => {
                            onConfirm && onConfirm();
                            setConfirmClicked(true);
                        }}
                        disabled={confirmClicked}
                    >
                        {
                            confirmClicked
                            ? <TailSpin 
                                strokeWidth={3.0} color="black" width="1.2em" 
                                height="1.1em" wrapperClass="padding-small" 
                            />
                            : (
                                ConfirmIcon && <ConfirmIcon width="1.2em" strokeWidth={2.0}/>
                            )
                        }
                        {confirmContent}
                    </button>
                </div>
            </div>
        </Popup>
    );
}
