import { team } from "./Bio";
import { Link } from "react-router-dom"
import { ContactPopup} from "src/pages/home/components/ContactPopup";

function About() {
    return (
        <div className=''>
            <CompanySection />
            <ContactSection />
            <TeamSection />
        </div>
    );
}

function CompanySection() {
    const companyBio = `Zanista is derived from the Kurdish word "zanîn" or, in some dialects, "zanîstin", rooted in "zan-" meaning "to know", connects linguistically to the ancient Sumerian word "zu" (𒍪), which also means "to know" or "to learn." The Sumerian civilization, flourishing between approximately 5500 and 1800 BC, is one of the earliest known civilizations and the first to develop writing. Similar words to "zanîn" appear in Persian دانستن (dânestan) and Greek "γνῶσις" (gnosis), reflecting a shared linguistic emphasis on knowledge. We are proud to carry forward this legacy by facilitating human knowledge with AI.
                        Zanista AI is based in London, UK, and we are aiming to revolutionize education technology sector. 
                        Our services reach a wide and diverse audience encompassing students, teachers, professors, and a variety of educational and research institutions.
                        At Zanista AI, we use generative AI technology to improve automation in education. Our products are including a fully automated exam marking system and a content generation tool serving as an Artificial Teaching Assistant (ATA), and a customized newsletter generator functioning as an Artificial Research Assistant (ARA).`
    return (
        <div className="flex-col justify-start items-start pb-4">
            <h2 className="font-bold text-3xl afacad-flux-normal">
                Zanista AI
            </h2>
            <p className="pb-4 font-bold text-gray-700 text-md sm:text-lg afacad-flux-normal">
                Improving Automation in Educational Technology
            </p>
            {
                companyBio.split('\n').map((line, index) => (
                    <span key={index} className="text-gray-600 text-xs sm:text-sm mb-2 afacad-flux-normal font-light">
                        {line}
                        <br />
                    </span>
                ))
            }
            <span className="text-gray-600 text-xs sm:text-sm mb-2 afacad-flux-normal">
              To stay informed about our dynamic range of products, we invite you to&nbsp;
              <a
                href="https://calendly.com/arman-khaledian-zanista/30min?month=2025-01"
                target="_blank"
                rel="noopener noreferrer"
                className="afacad-flux-normal text-accent text-xs sm:text-sm mb-2 underline cursor-pointer hover:text-gray-800"
              >
                request a demo
              </a>
              &nbsp;to set up a quick call with us.
            </span>
        </div>
    );
}

function ContactSection() {
    return (
        <div className="flex-col justify-start items-start pb-4">
            <h2 className="font-bold text-3xl afacad-flux-normal">
                Contact Us
            </h2>
            <p className="pb-2 font-bold text-sm sm:text-lg text-start text-gray-700 afacad-flux-normal">
                For any inquiries, please leave us an email, we will reach out to you as soon as possible.
            </p>
            <ContactPopup>
                <span className="afacad-flux-normal text-accent hover:text-gray-800 text-md sm:text-lg mb-2 hover:underline cursor-pointer">
                    info@zanista.ai
                </span>
            </ContactPopup>
        </div>
    );
}

function TeamSection() {
    return (
        <div className=''>
            <hr />
            <div className="flex-col justify-start items-start pb-10">
                <h2 className="afacad-flux-normal py-3 font-bold text-3xl kanit-regular">
                    Our Team
                </h2>
                <p className="afacad-flux-normal text-gray-600 text-sm">
                    We are fortunate to work with amazing people with different backgrounds
                </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 md:gap-y-6 gap-x-6">
                {
                    team.map((member, index) => (
                        <BioCard key={index} {...member} />
                    ))
                }
            </div>
        </div>
    );
}

function BioCard({ name, title, image, link, bio }) {
    return (
        <div className="p-4 flex-col justify-start items-start text-start  rounded-tl-3xl rounded-br-3xl bg-white transform transition-transform duration-200 hover:scale-105">
            <div className="flex-row pb-4 items-center">
                <img src={image} alt={name} className="rounded-full mt-0 md:mt-3 w-14 h-14 md:w-20 md:h-20" />
                <div className="flex-col pl-4">
                    <h3 className="afacad-flux-normal pt-3 font-bold text-md md:text-xl">
                        {name}
                    </h3>
                    <div className="flex about-flex-col items-start md:flex-row md:items-end">
                        <p className={"afacad-flux-normal text-gray-600 text-sm " + (title === '' ? '' : 'pr-2')}>
                            {title}
                        </p>
                        <Link to={link}>
                            {
                                link.includes("linkedin") ? (
                                    <img src="https://img.icons8.com/color/48/000000/linkedin.png" alt="LinkedIn"
                                        className="mt-1 min-w-6 w-6 h-6 object-fit"
                                    />
                                ) : (
                                    <img src="https://img.icons8.com/?size=100&id=85777&format=png&color=000000" alt="Link"
                                        className="mt-1 min-w-6 w-6 h-6 object-fit"
                                    />
                                )
                            }
                        </Link>
                    </div>
                </div>
            </div>
            { /* For now, remove bios
                    bio.split('\n').map((line, index) => (
                        <span key={index} className="text-gray-600 text-xs mb-2">
                            {line}
                            <br />
                        </span>
                    ))
                  */
            }
        </div>
    )
}

export default About;
