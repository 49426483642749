import { useContext, useEffect, useMemo, useState } from "react";
import StructureSection from "src/components/content/exam/StructureSection";
import { calculatePercentage } from "src/util/StringUtil";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import ExpandCollapseButtons from "src/components/layout/expand-collapse/ExpandCollapseButtons";
import { NavArrowDown, NavArrowRight } from "iconoir-react";
import { StructureItem } from "src/components/content/exam/StructureItem";
import { ExamStructureContext } from "src/components/content/exam/ExamStructureContext";
import { calcGrade } from "src/pages/goodpoint/exam/tabs/grading/GradeBoundaries";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";
import ClassGradingContext from "src/pages/goodpoint/exam/tabs/grading/class-grading/ClassGradingContext";
import { GradingType } from "src/api/goodpoint/Const";
import "./QuestionFeedbackItem.css"
import { getFromStructure, isAnyFlagged } from "src/components/content/exam/StructureUtil";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";


function QuestionStat({ text = null, textComponents = undefined, values, valuesStyle = "fg-orange-dark" }) {
    return <div className="flex-row justify-content-space-between align-center">
        {
            text && <p>{text}</p>
        }
        {
            textComponents
        }
        <div className="flex-row gap-mid">
            {
                values.map((value, index) => <p key={index} className={valuesStyle}>{value}</p>)
            }
        </div>
    </div>
}

function ExpandableQuestionStat({ text, values, valuesStyle = "fg-orange-dark", flexRow = false, children }) {
    const {
        isExpanded: isAllExpanded,
        isCollapsed: isAllCollapsed,
        reset: resetAll
    } = useContext(ExpandCollapseContext);

    const { isExpanded, expand, collapse, toggle } = useExpandCollapse();

    useEffect(() => { isAllExpanded && expand(); }, [isAllExpanded, expand]);
    useEffect(() => { isAllCollapsed && collapse(); }, [isAllCollapsed, collapse]);

    return <>
        <div
            className="flex-row justify-content-space-between cursor-pointer"
            onClick={() => { toggle(); resetAll(); }}
        >
            <div className="flex-row gap-small">
                {
                    isExpanded
                        ? <NavArrowDown color="var(--zanista-orange-dark)" width={"1em"} />
                        : <NavArrowRight color="var(--zanista-orange-dark)" width={"1em"} />
                }
                <p>{text}</p>
            </div>
            <div className="flex-row gap-mid">
                {
                    values.map((value, index) => <p key={index} className={valuesStyle}>{value}</p>)
                }
            </div>
        </div>
        <div className={`question-stat-content flex gap-2 ${flexRow ? "flex-row" : "flex-col"}`}>
            {
                isExpanded && children
            }
        </div>
    </>
}

function StudentChangeButton({ studentId, studentName }) {
    const { setGradingType, setClickedStudent } = useContext(GradingContext);
    return <button 
        className="student-change-button btn-clickable bg-yellow-light rounded-small" 
        onClick={() => { 
            setGradingType(GradingType.STUDENTS); 
            setClickedStudent(studentId); }
        }
    >
        {studentName}
    </button>
}


function QuestionFeedbackItemHeader({ itemData, nestingLevel }) {
    const { itemCommonProps } = useContext(ExamStructureContext);
    const { gradeBoundaries } = itemCommonProps;
    const questionName = itemData["name"];
    const avgMarks = itemData["total_marks"] ?? 0;
    const maxMarks = itemData["max_total_marks"];
    const percentage = 100.0 * avgMarks / maxMarks;
    const grade = calcGrade(percentage, gradeBoundaries);
    const flagged = useMemo(() => isAnyFlagged(itemData), [itemData]);

    return <div className="question-feedback-item-header fill-width flex-row justify-content-space-between">
        <p className="question-feedback-item-question-tag justify-self-left">
            <b>{(nestingLevel > 0) ? "Part " : ""}{questionName}</b>
        </p>
        {
            flagged && <p className="text-right text-zanista-red">(NEEDS REVIEW)</p>
        }
        {
            <div className="flex-row gap-mid">
                <p>Average:</p>
                <p>{avgMarks.toFixed(1)}/{maxMarks}</p>
                <p>({calculatePercentage(avgMarks, maxMarks)}%)</p>
                <p>{`Grade ${grade}`}</p>
            </div>
        }
    </div>
}

function QuestionFeedbackItemContent({ itemData, editKeysHierarchy, nestingLevel = 0 }) {
    const { gradeBoundaries } = useContext(GradingContext);
    const { studentsPerformance } = useContext(ClassGradingContext);
    const { rubric } = useContext(RubricContext);

    const expandCollapse = useExpandCollapse(false);
    const questionContent = (
        rubric && editKeysHierarchy && 
        getFromStructure(rubric, [...editKeysHierarchy, "question_content"])
    );
    const [feedbackContent, setFeedbackContent] = useState(itemData?.["feedback_content"]);

    const maxMarks = itemData?.["max_total_marks"];

    const studentsForQuestion = studentsPerformance?.[itemData?.id];
    const studentsItems = studentsForQuestion ? Object.entries(studentsForQuestion) : [];
    const studentMarks = studentsItems.map(([_, s]) => s["marks"]);

    const avg = {};
    avg.marks = itemData?.["total_marks"];
    avg.percent = 100.0 * avg.marks / maxMarks;
    avg.grade = calcGrade(avg.percent, gradeBoundaries);

    const highest = {};
    highest.marks = Math.max(...studentMarks);
    highest.percent = 100.0 * highest.marks / maxMarks;
    highest.grade = calcGrade(highest.percent, gradeBoundaries);

    const lowest = {};
    lowest.marks = Math.min(...studentMarks);
    lowest.percent = 100.0 * lowest.marks / maxMarks;
    lowest.grade = calcGrade(lowest.percent, gradeBoundaries);

    const studentsAttempted = studentsItems.filter(([_, sV]) => sV?.["marks"] !== maxMarks);
    const highestStudents = studentsItems.filter(([_, sV]) => sV?.["marks"] === highest.marks);
    const lowestStudents = studentsItems.filter(([_, sV]) => sV?.["marks"] === lowest.marks);
    

    return <div className="feedback-content pt-2">
        <ExpandCollapseContext.Provider value={expandCollapse}>
            {
                questionContent &&
                <StructureSection
                    heading={"Question Content"}
                    content={questionContent}
                    children={undefined}
                    hide
                />
            }
            <StructureSection
                heading={"Statistics"}
                headingControls={
                    <ExpandCollapseButtons
                        props={{
                            iconSize: 17,
                            borderSize: "var(--border-thin)",
                            paddingSize: "0px 5px",
                            roundedSize: "var(--rounded-xsmall)"
                        }}
                    />
                }
                initiallyExpanded={nestingLevel === 0}
                aiGenerated
            >
                <div className="question-feedback-stats flex-column gap-mid">
                    <QuestionStat 
                        text="Average marks" 
                        values={[
                            `${avg.marks?.toFixed(1)}/${maxMarks?.toFixed(1)}`,
                            `(${avg.percent?.toFixed(1)}%)`,
                            `Grade ${avg.grade}`
                        ]} 
                        valuesStyle="fg-orange-dark" 
                    />
                    {
                        (highest.marks !== lowest.marks) &&
                        <>
                            <ExpandableQuestionStat 
                                text="Highest mark" 
                                values={[
                                    `${highest.marks?.toFixed(1)}/${maxMarks?.toFixed(1)}`,
                                    `(${highest.percent?.toFixed(1)}%)`,
                                    `Grade ${highest.grade}`
                                ]} 
                                valuesStyle="fg-orange-dark" flexRow
                            >
                                {
                                    highestStudents.map(([sId, s], index) =>
                                        <StudentChangeButton 
                                            key={index} studentId={sId} studentName={s["student_name"]}
                                        />
                                    )
                                }
                            </ExpandableQuestionStat>
                            <ExpandableQuestionStat 
                                text="Lowest mark" 
                                values={[
                                    `${lowest.marks?.toFixed(1)}/${maxMarks?.toFixed(1)}`,
                                    `(${lowest.percent?.toFixed(1)}%)`,
                                    `Grade ${lowest.grade}`
                                ]} 
                                valuesStyle="fg-orange-dark" flexRow
                            >
                                {
                                    lowestStudents.map(([sId, s], index) =>
                                        <StudentChangeButton 
                                            key={index} studentId={sId} studentName={s["student_name"]}
                                        />
                                    )
                                }
                            </ExpandableQuestionStat>
                        </>
                    }
                    {
                        (studentsAttempted.length > 0) &&
                        <ExpandableQuestionStat 
                            text="Attempted by" 
                            values={[`${studentsAttempted.length} students`]} 
                            valuesStyle="fg-orange-dark"
                        >
                            {
                                studentsAttempted.map(([sId, s], index) =>
                                    <QuestionStat
                                        key={index}
                                        textComponents={
                                            <StudentChangeButton 
                                                key={index} 
                                                studentId={sId} 
                                                studentName={s["student_name"]}
                                            />
                                        }
                                        values={[
                                            `${s?.marks?.toFixed(1)}/${maxMarks?.toFixed(1)}`,
                                            `(${(100.0 * s?.marks / maxMarks)?.toFixed(1)}%)`,
                                            `Grade ${calcGrade(100.0 * s?.marks / maxMarks, gradeBoundaries)}`
                                        ]}
                                        valuesStyle="fg-grey"
                                    />
                                )
                            }
                        </ExpandableQuestionStat>
                    }
                </div>
            </StructureSection>
        </ExpandCollapseContext.Provider>
        {
            feedbackContent &&
            <StructureSection 
                heading={"Feedback"} aiGenerated 
                content={feedbackContent}
                setContent={setFeedbackContent}
                editKeys={[...editKeysHierarchy, "feedback_content"]}
            />
        }
    </div>
}

export default function QuestionFeedbackItem({ itemData, ...props }) {
    const { itemCommonProps } = useContext(ExamStructureContext);
    const { isFilterOnlyMistakes } = itemCommonProps;

    if (isFilterOnlyMistakes && !(itemData["total_marks"] < itemData["max_total_marks"])) {
        return null;
    }

    return <StructureItem
        itemData={itemData}
        HeaderComponent={QuestionFeedbackItemHeader}
        ContentComponent={QuestionFeedbackItemContent}
        {...props}
    />;
}