import Plot from "react-plotly.js";
import { useState, useEffect, useMemo } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";
import useFetchPriceData from "src/api/newswitch/useFetchPriceData";

const SentimentChart = ({ ticker, title = undefined, eventDate = new Date(), showPriceLine = true }) => {    
    let mobileMode = false;
    if (window.innerWidth < 768) {
        mobileMode = true;
    }

    if (title === undefined) {
        title = ticker;
    }

    const callAPI = useNewsWitchApi();
    const [sentimentData, setSentimentData] = useState([]);
    const [netSentimentData, setNetSentimentData] = useState([]);
    const [dailySentimentData, setDailySentimentData] = useState([]);
    const [largestSentiment, setLargestSentiment] = useState(5);
    const [xData, setXData] = useState([]);
    const [volumeData, setVolumeData] = useState([]);
    const [largestVolume, setLargestVolume] = useState(100);
    const [hasChangedData, setHasChangedData] = useState([]); // If the net sentiment is not yet up to date
    const [priceData, setPriceData] = useState([]);
    const [priceRange, setPriceRange] = useState([0, 0]);
    const alpha = 0.75;

    const fetchPriceData = useFetchPriceData();
    
    const daysBefore = 6 * 31;
    const startDate = useMemo(() => new Date(eventDate.getTime() - (daysBefore * 24 * 60 * 60 * 1000)), [eventDate, daysBefore]);
    const twoMonthsAgo = useMemo(() => new Date(eventDate.getTime() - (62 * 24 * 60 * 60 * 1000)), [eventDate]);
    const endDate = useMemo(() => eventDate, [eventDate]);

    const startDateStr = startDate.toISOString().split('T')[0];
    const endDateStr = endDate.toISOString().split('T')[0];

    useEffect(() => {
        callAPI("GET", `/sentiment?ticker=${ticker}&start_date=${startDateStr}&end_date=${endDateStr}`)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    // @ts-ignore
                    const sentiments = body["sentiment"].sort((a, b) => new Date(a["date"]) - new Date(b["date"]));
                    setSentimentData(Array.from(
                        { length: Math.ceil((endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)) + 1 },
                        (_, i) => new Date(startDate.getTime() + i * (24 * 60 * 60 * 1000)).toISOString().split('T')[0]
                    ).map(date => {
                        const sentiment = sentiments.find(s => s["date"] === date);
                        if (sentiment && sentiment["basic"]) {
                            return sentiment;
                        } else {
                            return { 
                                date: date,
                                net_sentiment: null, 
                                basic: { positive: 0, negative: 0 } 
                            };
                        }
                    }));
                });
            }
        });
    }, [ticker, startDateStr, endDateStr, callAPI, startDate, endDate]);

    useEffect(() => {
        if (sentimentData?.length === 0) return;
        fetchPriceData(ticker, eventDate, startDate, false)?.then(prices => {
            let lastPrice = 0;
            //@ts-ignore
            const newPriceData = sentimentData.sort((a, b) => new Date(a["date"]) - new Date(b["date"])).map(item => {
                const price = prices.find(p => p["date"] === item["date"]);
                return price ? price["close"] : null;
            });
            newPriceData.forEach(price => {
                if (price !== null) {
                    lastPrice = price;
                }
            });
            const highestPrice = Math.max(...newPriceData.filter(price => price !== null));
            const lowestPrice = Math.min(...newPriceData.filter(price => price !== null));
            setPriceData(newPriceData);
            const maxDiff = Math.max(
                Math.abs(highestPrice - lastPrice), 
                Math.abs(lastPrice - lowestPrice)
            ) * 1.5;
            setPriceRange([lastPrice - maxDiff, lastPrice + maxDiff]);
        });
    }, [ticker, eventDate, sentimentData, fetchPriceData, startDate]);

    const interpolatePriceData = (priceData) => {
        return priceData.map((y, i) => {
            if (y !== null) return y;
            // Find previous non-null value
            let prev = null;
            let prevIndex = i - 1;
            while (prev === null && prevIndex >= 0) {
                if (priceData[prevIndex] !== null) {
                    prev = priceData[prevIndex];
                    break;
                }
                prevIndex--;
            }
            // Find next non-null value
            let next = null; 
            let nextIndex = i + 1;
            while (next === null && nextIndex < priceData.length) {
                if (priceData[nextIndex] !== null) {
                    next = priceData[nextIndex];
                    break;
                }
                nextIndex++;
            }
            // Linear interpolation
            if (prev !== null && next !== null) {
                return prev + (next - prev) * (i - prevIndex) / (nextIndex - prevIndex);
            }
            return prev || next || null;
        });
    }

    useEffect(() => {
        const newNetSentimentData = []
        const newDailySentimentData = []
        const newXData = []
        const newVolumeData = []
        const newHasChangedData = []

        let prevNetSentiment = null;
        // Apply exponential smoothing using alpha
        sentimentData.forEach(item => {
            const dailySentiment = item["basic"]["positive"] - item["basic"]["negative"];
            if (prevNetSentiment === null) {
                prevNetSentiment = item["net_sentiment"] ? item["net_sentiment"] : 0;
                newNetSentimentData.push(item["net_sentiment"] ? item["net_sentiment"] : 0);
            } else {
                const smoothedNetSentiment = alpha * dailySentiment + (1 - alpha) * prevNetSentiment;
                newNetSentimentData.push(smoothedNetSentiment);
                prevNetSentiment = smoothedNetSentiment;
            }
            newDailySentimentData.push(dailySentiment);
            newXData.push(item["date"]);
            newVolumeData.push(item["news_count"] || 0);
            newHasChangedData.push(item["changed"] || false);
        });

        const minSentiment = Math.min(...newDailySentimentData, -5);
        const maxSentiment = Math.max(...newDailySentimentData, 5);
        const largestSentiment = Math.max(Math.abs(minSentiment), Math.abs(maxSentiment));
        const largestVolume = Math.max(...newVolumeData);

        setXData(newXData);
        setNetSentimentData(newNetSentimentData);
        setDailySentimentData(newDailySentimentData);
        setLargestSentiment(largestSentiment);
        setVolumeData(newVolumeData);
        setLargestVolume(largestVolume);
        setHasChangedData(newHasChangedData);
    }, [sentimentData, alpha]);

    return (
    //@ts-ignore
    <Plot className="w-full my-4"
        data={[
            {   // Net sentiment line
                x: xData,
                y: netSentimentData,
                text: netSentimentData.map(y => `Net Sentiment: ${y.toFixed(2)}`),
                type: 'scatter',
                mode: 'lines',
                line: { 
                    color: 'rgba(0, 0, 0, 0.7)',
                    width: 3,
                    shape: 'spline',
                    smoothing: 0.75,
                },
                marker: {
                    size: 8,
                    color: 'rgba(0, 0, 0, 0.7)',
                },
                hoverinfo: 'x+text',
                name: "Net Sentiment",
                showlegend: true,
                yaxis: 'y2',
            },
            {   // Sentiment bars
                x: xData,
                y: dailySentimentData,
                text: dailySentimentData.map(y => {
                    if (y >= 0) {
                        return `Daily Sentiment: +${y.toFixed(0)}`;
                    } else {
                        return `Daily Sentiment: ${y.toFixed(0)}`;
                    }
                }),
                textfont: {
                    // invisible
                    color: 'rgba(0, 0, 0, 0)',
                },
                type: 'bar',
                yaxis: 'y2',
                name: 'Daily Sentiment',
                marker: { 
                    color: dailySentimentData.map((val, index) => val < 0 ? 'rgba(255, 0, 0, 0.4)' : 'rgba(0, 204, 102, 0.4)'),
                    line: {
                        width: 1.4,
                        color: 'rgba(255, 255, 255, 1)',
                    },
                },
                showlegend: true,
                hoverinfo: 'x+text',
                offset: 0.05,
            },
            {   // Price line
                x: xData,
                y: interpolatePriceData(priceData),
                text: priceData.map(y => y ? `Close Price: ${y.toFixed(2)}` : 'Market Closed'),
                type: 'scatter',
                mode: 'lines+markers',
                line: { 
                    color: 'rgba(0, 0, 255, 0.8)',
                    width: 2,
                },
                marker: { 
                    color: 'rgba(0, 0, 255, 0.7)',
                    size: 4,
                    symbol: 'circle',
                },
                hoverinfo: 'x+text',
                name: "Price",
                showlegend: true,
                yaxis: 'y1',
                visible: showPriceLine,
            },
            {   // Volume data
                x: xData,
                y: volumeData,
                text: volumeData.map((y, i) => hasChangedData[i] ? `Total News: ${y} (Updating)` : `Total News: ${y}`),
                textfont: {
                    // invisible
                    color: 'rgba(0, 0, 0, 0)',
                },
                type: 'bar',
                yaxis: 'y3',
                name: 'News Volume',
                marker: { 
                    color: hasChangedData.map((val, index) => val ? 'rgba(0, 0, 255, 0.4)' : 'rgba(140, 140, 140, 1)'),
                    line: {
                        width: 1.4,
                        color: 'rgba(255, 255, 255, 1)',
                    },
                },
                showlegend: true,
                hoverinfo: 'x+text',
                offset: 0.05,
            },
        ]}
        layout={{
        autosize: true,
        dragmode: 'pan',
        margin: { 
            t: 10,
            b: 80, 
            l: mobileMode ? 20 : 80, 
            r: mobileMode ? 20 : 80
        },
        barmode: 'relative',
        bargap: 0.1,
        hovermode: 'closest',
        xaxis: {
            tickvals: xData,
            ticks: 'outside',
            tickfont: {
                size: 16,
            },
            tickangle: 45,
            tickmode: 'auto',
            nticks: 50,
            tickformat: "%d %b %Y",
            range: [twoMonthsAgo.getTime(), endDate.getTime()],
            showgrid: true,
            gridcolor: 'gray',
            showline: true,
            linecolor: 'gray',
            mirror: true,
            gridwidth: 0.5,
            zeroline: false,
            rangeselector: {
                visible: true,
                buttons: [
                    {step: "month", stepmode: "backward", count: 1, label: "1m"},
                    {step: "month", stepmode: "backward", count: 2, label: "2m"},
                    {step: "month", stepmode: "backward", count: 3, label: "3m"},
                    {step: "month", stepmode: "backward", count: 6, label: "6m"},
                ],
            },
            fixedrange: false,
            minallowed: startDate.getTime(),
            maxallowed: endDate.getTime(),
        },
        yaxis: {
            title: {
                text: 'Close Price',
                font: {
                    size: 18,
                }
            },
            side: 'left',
            range: priceRange,
            overlaying: 'y2',
            showgrid: false,
            fixedrange: true,
        },
        yaxis2: {
            title: {
                text: 'Net Sentiment',
                font: {
                    size: 18,
                }
            },
            range: [largestSentiment * -1.2, largestSentiment * 1.2],
            side: 'right',
            showgrid: true,
            gridcolor: 'gray',
            showline: true,
            linecolor: 'gray',
            mirror: true,
            gridwidth: 0.5,
            showticklabels: true,
            overlaying: 'free',
            fixedrange: true,
        },
        yaxis3: {
            side: 'right',
            range: [0, largestVolume * 10],
            showgrid: false,
            overlaying: 'y2',
            showticklabels: false,
            tickvals: [0, largestVolume],
            fixedrange: true,
        },
        shapes: [
            {   // Horizon line
                type: 'line',
                x0: 0,
                x1: 1,
                xref: 'paper',
                y0: 0,
                y1: 0,
                line: {
                    color: 'blue',
                    width: 3,
                    dash: 'dash',
                },
                yref: 'y2',
            },
        ],
        showlegend: true,
        legend: {
            title: {
                text: `${title}`,
                font: {size: 12},
                side: 'top',
            },
            x: 0.01,
            y: 0.99,
            xanchor: 'left',
            yanchor: 'top',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            font: {size: 12},
            itemclick: false,
            orientation: 'v',
            borderwidth: 1,
        },
        // updatemenus: [
        //     {
        //         type: 'buttons',
        //         x: 1,
        //         y: 1,
        //         xanchor: 'right',
        //         yanchor: 'bottom',
        //         pad: {
        //             l: 0,
        //             r: 0,
        //             t: 0,
        //             b: 10,
        //         },
        //         buttons: [
        //             {
        //                 label: 'Hide Close Price',
        //                 method: 'restyle',
        //                 args: [{'visible': [true, false]}, [0, 1]],
        //             },
        //         ],
        //     },
        // ],
        }}
        config={{
            responsive: true,
            scrollZoom: false,
            displayModeBar: false,
        }}
        />
    );
};

export default SentimentChart;