import Plot from "react-plotly.js";

const addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const addMonths = function(months) {
    var date = new Date(this.valueOf());
    date.setMonth(date.getMonth() + months);
    return date;
}

const addYears = function(years) {
    var date = new Date(this.valueOf());
    date.setFullYear(date.getFullYear() + years);
    return date;
}

const PPPChart = ({ news, prices, title = undefined, eventDate = new Date(), ticker = "" }) => {
    let mobileMode = false;
    if (window.innerWidth < 768) {
        mobileMode = true;
    }

    if (title === undefined) {
        title = ticker;
    }

    if (news.length === 0 && prices.length === 0) return <div className="w-full h-full my-12 text-center">No news or price data</div>;

    const xData = [
        '-1 year', '-6 months', '-3 months', '-1 month', '-6 days', '-3 days', '-2 days', '-1 day', 'Event Date', 
        '+1 day', '+2 days', '+3 days', '+4 days', '+6 days', '+1 week', '+1 month', '+3 months', '+6 months', '+1 year'
    ];

    const addPeriod = function(date, value) {
        if (value.includes('year')) {
            return addYears.call(date, parseInt(value));
        } else if (value.includes('month')) {
            return addMonths.call(date, parseInt(value));
        } else if (value.includes('week')) {
            return addDays.call(date, parseInt(value) * 7);
        } else if (value.includes('day')) {
            return addDays.call(date, parseInt(value));
        } else {
            return date;
        }
    }

    const isWeekend = function(date) {
        return date.getDay() === 0 || date.getDay() === 6;
    }

    const isWithinPeriod = function(date, startDate, endDate) {
        return date >= startDate && date <= endDate;
    }

    const xDates = xData.map((date, index) => {
        return addPeriod(eventDate, date);
    });

    const xDataFormatted = xDates.map((date, index) => {
        const dateText = xData[index];
        const text = isWeekend(date) ? `* ${dateText}` : dateText;
        if (isWithinPeriod(date, addMonths.call(eventDate, -1), eventDate)) {
            return `<span style="color:red;">${text}</span>`
        } else {
            return text;
        }
    })

    const sentimentMap = {
        'Positive': 1,
        'Negative': -1,
        'Strongly Positive': 3,
        'Strongly Negative': -3,
        'Neutral': 0,
        '': 0
    }

    const intensityMap = {
        "Immediate": ["+1 day", "+2 days", "+3 days"],
        "Short-Term": ["+3 days", "+4 days", "+6 days"],
        "Medium-Term": ['+1 week', '+1 month'],
        "Long-Term": ['+3 months', '+6 months', '+1 year']
    }

    let priceData = [];
    if (prices !== undefined) {
        const eventPrice = prices && prices["0d"] ? prices["0d"].close : 0;
        const priceKeys = ["0d", "1d", "2d", "3d", "6d", "1m", "3m", "6m", "1y"].reverse();
        priceData = priceKeys.map(key => ((prices && prices[key]) ? (prices[key].close - eventPrice) / eventPrice * 100 : null)).filter(price => price !== null);
    }

    const priceText = priceData.map((price, index) => {
        if (index === priceData.length - 1) return "";
        return price > 0 ? `+${price.toFixed(1)}%` : `${price.toFixed(1)}%`;
    });

    for (let i = 0; i < 11; i++) {
        priceData.push(0.0);
        priceText.push(null);
    }

    const minPrice = Math.min(...priceData);
    const maxPrice = Math.max(...priceData);
    const priceAbsolute = (Math.max(Math.abs(minPrice), Math.abs(maxPrice))) * 2;

    const xSentimentData = []
    const ySentimentData = []
    const sentimentText = []

    news.forEach(item => {
        const intensity = item["sentiment"]["intensity"];
        if (!intensityMap[intensity]) return;
        intensityMap[intensity].forEach(date => {
            xSentimentData.push(date);
            ySentimentData.push(sentimentMap[item["sentiment"]["direction"]]);
            sentimentText.push(`${item["title"]}`);
        });
    });

    const maxSentiment = Math.max(...xData.map((date, index) => {
        const indexes = xSentimentData.reduce((acc, val, i) => (val === date ? acc.concat(i) : acc), []);
        return indexes.reduce((acc, val) => ySentimentData[val] >= 0 ? acc + ySentimentData[val] : acc, 0);
    }));

    const minSentiment = Math.min(...xData.map((date, index) => {
        const indexes = xSentimentData.reduce((acc, val, i) => (val === date ? acc.concat(i) : acc), []);
        return indexes.reduce((acc, val) => ySentimentData[val] <= 0 ? acc + ySentimentData[val] : acc, 0);
    }));
    
    const sentimentAbsolute = Math.max(Math.abs(minSentiment), Math.abs(maxSentiment)) * 1.025;

    return (
    <Plot className="w-full my-4"
        data={[
            {   // Price data
                x: xData,
                y: priceData,
                text: priceText,
                type: 'scatter',
                mode: 'text+lines+markers',
                line: { 
                    color: 'rgba(0, 0, 255, 0.6)',
                    width: 3,
                },
                hoverinfo: 'text',
                textposition: 'top center',
                textfont: {
                    color: 'black',
                    size: 12,
                },
                name: "Close Price vs Event Date",
                showlegend: true,
                yaxis: 'y1',
            },
            {   // Sentiment data
                x: xSentimentData,
                y: ySentimentData,
                text: sentimentText,
                textfont: {
                    // invisible
                    color: 'rgba(0, 0, 0, 0)',
                },
                type: 'bar',
                yaxis: 'y2',
                name: 'News Sentiment',
                marker: { 
                    color: ySentimentData.map((val, index) => val >= 0 ? 'rgba(0, 204, 102, 0.5)' : 'rgba(255, 0, 0, 0.5)'),
                    line: {
                        width: 1.4,
                        color: 'rgba(255, 255, 255, 1)',
                    }
                },
                showlegend: true,
                hoverinfo: 'text',
                offset: -0.95,
            },
        ]}
        layout={{
        margin: { 
            t: 10,
            b: 80, 
            l: mobileMode ? 20 : 80, 
            r: mobileMode ? 20 : 80
        },
        barmode: 'relative',
        bargap: 0.1,
        hovermode: 'closest',
        xaxis: {
            tickvals: xData,
            ticktext: xDataFormatted,
            ticks: 'outside',
            tickfont: {
                size: 16,
            },
            showgrid: true,
            gridcolor: 'gray',
            showline: true,
            linecolor: 'gray',
            mirror: true,
            gridwidth: 0.5,
            zeroline: false,
        },
        yaxis: {
            title: {
                text: "Close Price Change",
                font: {
                    size: 18,
                }
            },
            side: 'left',
            range: [-priceAbsolute, priceAbsolute],
            showgrid: true,
            gridcolor: 'gray',
            showline: true,
            linecolor: 'gray',
            mirror: true,
            gridwidth: 0.5,
        },
        yaxis2: {
            title: {
                text: 'News Sentiment',
                font: {
                    size: 18,
                }
            },
            overlaying: 'y',
            side: 'right',
            range: [-sentimentAbsolute, sentimentAbsolute],
            showgrid: false,
        },
        shapes: [
            {
                type: 'line',
                x0: 'Event Date',
                x1: 'Event Date',
                yref: 'paper',
                y0: 0,
                y1: 1,
                line: {
                    color: 'blue',
                    width: 3,
                    dash: 'dash',
                },
            },
            {   // Horizon line
                type: 'line',
                x0: 0,
                x1: 1,
                xref: 'paper',
                y0: 0,
                y1: 0,
                line: {
                    color: 'blue',
                    width: 3,
                    dash: 'dash',
                },
            },
        ],
        showlegend: true,
        legend: {
            title: {
                text: `${title} (Event Date: ${eventDate.toISOString().slice(0, 10)})`,
                font: {size: 12},
                side: 'top',
            },
            x: 0.01,
            y: 0.99,
            xanchor: 'left',
            yanchor: 'top',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            font: {size: 12},
            itemclick: false,
            orientation: 'v',
            borderwidth: 1,
        },
        }}
        config={{ 
            responsive: true,
            scrollZoom: false,
            displayModeBar: false,
        }}
    />
    );
};

export default PPPChart;