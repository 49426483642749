import { Download } from "iconoir-react";
import { TailSpin } from "react-loader-spinner";
import { useBlobDownload } from "src/api/useBlobDownload";


export default function DownloadButton({
    downloadEndpoint,
    filename,
    text,
    downloadingText = `Downloading...`,
    Icon = Download,
    DownloadingIcon = () => <TailSpin strokeWidth={3.0} color="black" width={20} height={20} wrapperClass="px-1" />,
    ...props
}) {
    const { download, isDownloading } = useBlobDownload(downloadEndpoint, filename);

    return (
        <button
            className={
                `btn-clickable flex flex-row gap-1 items-center rounded-md p-2 pr-3 bg-zanista-orange-mid`
            }
            onClick={download}
        >
            {
                isDownloading
                ? <><DownloadingIcon/><p>{downloadingText}</p></>
                : <><Icon/><p>{text}</p></>
            }
        </button>
    )
}