import { DownloadSquare, Link, OpenInWindow } from "iconoir-react";
import React, { useCallback, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "./PdfViewer.css";


function Spin() {
    return <TailSpin width={20} height={20} color="black" strokeWidth={3.0} wrapperClass="padding-small" />;
}


function PdfToolbarButton({ onClick, disabled = false, children = null }) {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={
                `${disabled ? "opacity-25" : "hover:bg-slate-200 hover:scale-110 transition-all ease-in"} ` +
                `p-2`
            }
        >
            {children}
        </button>
    )
}


const PdfViewer = React.memo(({ 
    // @ts-ignore
    url, 
    // @ts-ignore
    title,
    // @ts-ignore
    height = "600px",
    // @ts-ignore
    pdfName = null,
    // @ts-ignore
    link = null,
    // @ts-ignore
    enableToolbar = true,
    // @ts-ignore
    allowDownload = true,
    // @ts-ignore
    style = null, 
    ...props
}) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [isDownloading, setIsDownloading] = useState(false);

    const download = useCallback(() => {
        setIsDownloading(true);
        fetch(url)
            .then(async response => {
                if (response.ok) {
                    const blob = await response.blob();
                    const downloadedUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = downloadedUrl;
                    link.download = title;
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    window.URL.revokeObjectURL(downloadedUrl);
                }
            })
            .finally(() => setIsDownloading(false));
    }, [url, title]);

    function OpenPageButton() {
        return (
            <PdfToolbarButton onClick={() => { if (url) { window.open(url, "_blank", "noopener,noreferrer") }}}>
                {
                    url
                        ? <OpenInWindow />
                        : <Spin />
                }
            </PdfToolbarButton>
        )
    }

    function OpenLink() {
        return (
            <PdfToolbarButton onClick={() => { if (link) { window.open(link, "_blank", "noopener,noreferrer") }}}>
                {
                    link
                        ? <Link />
                        : <Spin />
                }
            </PdfToolbarButton>
        )
    }

    function DownloadPdfButton() {
        return (
            <PdfToolbarButton onClick={download}>
                {
                    (isDownloading || !url)
                        ? <Spin />
                        : <DownloadSquare />
                }
            </PdfToolbarButton>
        )
    }

    return (
        <div className={
            `zanista-pdf-viewer shadow-lg shadow-slate-400 border-t-2 border-t-slate-200`
        }>
            {
                enableToolbar &&
                <div className=
                    "flex-row justify-between w-full bg-slate-100 overflow-hidden"
                >
                    <div className="flex-row gap-2">
                        <OpenPageButton />
                        { link && <OpenLink /> }
                    </div>
                    <div className="flex-row gap-2">
                        { allowDownload && <DownloadPdfButton /> }
                    </div>
                </div>
            }
            <div className={`w-full`} style={{"minHeight": height, "height": height, "maxHeight": height}}>
                {
                    url &&
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer 
                            fileUrl={url} 
                            plugins={[defaultLayoutPluginInstance]}
                            defaultScale={SpecialZoomLevel.PageWidth}
                            initialPage={0}
                        />
                    </Worker>
                }
            </div>
        </div>
    )
});

export default PdfViewer;