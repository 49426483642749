import { useCallback } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

export default function useFetchTickerInfo() {
    const callApi = useNewsWitchApi();

    const fetchTickerInfo = useCallback((ticker) => {
        return callApi("GET", `/ticker?ticker=${ticker}`)?.then(response => {
            if (response.status === 200) {
                return response.json().then(body => {
                    return body["ticker"];
                });
            } else {
                console.error(response);
                return undefined;
            }
        });
    }, [callApi]);

    return fetchTickerInfo;
}
