import "src/styles/Main.css"
import "./GradingTab.css"
import { useContext, useEffect, useLayoutEffect, useState } from "react"
import { StepState } from 'src/api/goodpoint/Const'
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext"
import GradingReady from "src/pages/goodpoint/exam/tabs/grading/GradingReady"
import GradingProcessing from "src/pages/goodpoint/exam/tabs/grading/GradingProcessing"
import GradingCompleted from "src/pages/goodpoint/exam/tabs/grading/GradingCompleted"


export default function GradingTab() {
    const { examState, updateExamState, setFooterControls } = useContext(ExamContext);
    const [subtitle, setSubtitle] = useState(null);
    const [gradingCOntent, setGradingContent] = useState(null);
    
    useLayoutEffect(() => {
        setFooterControls(null);
    }, [setFooterControls]);

    useEffect(() => {
        switch (examState.grading) {
            case StepState.AVAILABLE:
                updateExamState({ grading: StepState.READY });
                break;
            case StepState.READY:
                setSubtitle("Configure the grading process");
                setGradingContent(<GradingReady />);
                break;
            case StepState.PROCESSING:
                setSubtitle("Exam is being graded...");
                setGradingContent(<GradingProcessing />);
                break;
            case StepState.COMPLETED:
                setSubtitle("Review your students' performance");
                setGradingContent(<GradingCompleted />);
                break;
            default:
                setGradingContent(null);
                break;
        }
    }, [examState.grading, updateExamState]);

    useEffect(() => {
        if (examState.grading === StepState.NOT_AVAILABLE) {
            if (examState.answers >= StepState.READY) {
                updateExamState({grading: StepState.READY});
            }
        }
    }, [examState.answers, examState.grading, updateExamState]);

    return (
        <div className="grading-tab">
            <div className="flex flex-row justify-between items-center mb-3">
                <h2 className="font-bold text-lg">Grading</h2>
                <p>{subtitle}</p>
            </div>
            {gradingCOntent}
        </div>
    );
}
