import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { GradingImages } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import Divider from "src/components/layout/Divider";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";
import useCallApi from "src/api/useCallApi";
import Loading from "src/components/ui/Loading";
import { ReactComponent as PolarPlotIcon } from "src/assets/polar.svg";
import { ReactComponent as LineGraphIcon } from "src/assets/linegraph.svg";
import { calcGrade, EditGradeBoundaries } from "src/pages/goodpoint/exam/tabs/grading/GradeBoundaries";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";
import StudentOverallFeedback from "src/pages/goodpoint/exam/tabs/grading/student-grading/StudentOverallFeedback";


function StudentResults() {
    const { examResults, gradeBoundaries } = useContext(GradingContext);
    const { selectedStudent } = useContext(StudentsContext);

    const totalMarks = selectedStudent?.["total_marks"];
    const maxMarks = examResults?.["max_marks"];
    const percentage = 100.0 * totalMarks / maxMarks;
    const grade = calcGrade(percentage, gradeBoundaries);

    return (
        examResults 
            ? <div className="flex-row align-center justify-between">
                <div className="flex flex-row gap-2 align-center">
                    <h1 className="font-semibold">{percentage?.toFixed(1)}%</h1>
                    <div>
                        <p>{totalMarks?.toFixed(1)}/{maxMarks} Marks</p>
                        <p>Grade {grade}</p>
                    </div>
                </div>
            </div>
            : <Loading />
    )
}


function StudentGradingPlot({ plotType, Icon, alt }) {
    const callApi = useCallApi();

    const { examObject } = useContext(ExamContext);
    const { selectedStudent } = useContext(StudentsContext);

    const [popupOpen, setPopupOpen] = useState(false);
    const [plotImage, setPlotImage] = useState(null);

    // Reset the plots once the selected student changes.
    useEffect(() => {
        if (!selectedStudent?.id) return;
        setPlotImage(null);
    }, [selectedStudent?.id]);

    // Fetch the plot image.
    useEffect(() => {
        if (plotImage !== null) return;
        callApi("GET", GoodPointApi.Grading.Plots.Student(examObject.id, selectedStudent.id, plotType))
            ?.then(response => {
                if (response.status === 200) {
                    response.blob().then(img => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setPlotImage(reader.result);
                        }
                        reader.readAsDataURL(img);
                    })
                }
            });
    }, [callApi, examObject.id, plotType, plotImage, selectedStudent.id]);

    return <>
        <div className="w-full object-contain">
            {
                plotImage
                    ? <img 
                        className={
                            "cursor-pointer flex-shrink-0 transform transition-transform duration-200 hover:scale-105 " +
                            "shadow-lg hover:shadow-2x1 rounded-lg"
                        }
                        src={plotImage} alt={alt}
                        onClick={() => setPopupOpen(true)}
                    />
                    : <div className="w-full object-contain flex-shrink-0">
                        <div className="relative flex flex-row w-full justify-center items-center shadow-lg">
                            <Icon className="opacity-10 h-96 flex-shrink-0" />
                            <div className="absolute inset-0 flex justify-center items-center rounded-lg">
                                <Loading />
                            </div>
                        </div>
                    </div>
            }
        </div>
        <Popup open={popupOpen} closeOnDocumentClick={true} onClose={() => setPopupOpen(false)}>
            <div className='bg-white rounded-small w-full h-full'>
                <img src={plotImage} alt={alt} />
            </div>
        </Popup>
    </>
}


export default function StudentStats() {
    const { selectedStudent } = useContext(StudentsContext);
    return (
        <div className="student-stats">
            <div className="flex justify-between w-full">
                {
                    selectedStudent && (
                        selectedStudent.config?.student_name
                            ? <p><b>Name: </b> {selectedStudent.config.student_name}</p>
                            : <p><b>Student ID: </b> {selectedStudent.id}</p>
                        )
                }
                {
                    selectedStudent?.config?.student_name &&
                    <p><b>Student ID: </b>{selectedStudent.id}</p>
                }
            </div>

            <Divider />

            <div className="flex flex-row align-center justify-between">
                <StudentResults />
                <EditGradeBoundaries/>
            </div>

            <Divider />

            <div className="flex flex-row max-w-full gap-5 p-5">
                <StudentGradingPlot 
                    plotType={GradingImages.QUESTION_MARKS_POLAR}
                    Icon={PolarPlotIcon}
                    alt="Question Marks Polar Plot"
                />
                <StudentGradingPlot 
                    plotType={GradingImages.STUDENT_QUANTILE}
                    Icon={LineGraphIcon}
                    alt="Student Quantile"
                />
            </div>

            <Divider />

            <StudentOverallFeedback />
        </div>
    )
}