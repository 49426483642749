import { useEffect, useState } from "react";
import { StepState } from "src/api/goodpoint/Const";
import OchreApi from "src/api/ochre/OchreApi";
import useCallApi from "src/api/useCallApi";
import Title from "src/components/content/Title";
import BackLink from "src/components/nav/BackLink";
import ScrollToTopButton from "src/components/nav/ScrollToTopButton";
import ScanMenu from "src/pages/ochre/dashboard/ScanMenu";
import { ScansListContext } from "src/pages/ochre/dashboard/ScansListContext";
import UploadScans from "src/pages/ochre/dashboard/UploadScans";


export default function DocScanDashboard() {
    const callApi = useCallApi();
    const [scans, setScans] = useState(null);

    useEffect(() => {
        if (scans) return;
        callApi("GET", OchreApi.ListScans)
            ?.then(response => {
                if (response.status === 200) {
                    response.json().then(body => {
                        const data = body["data"]
                        const _scans = {};
                        data["scans"].forEach(e => {
                            _scans[e.id] = e;
                        });
                        setScans(_scans);
                    });
                } else {
                    console.error("Failed to fetch scans: " + response.status + " " + response.statusText);
                }
            });
    }, [callApi, scans]);

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Dashboard" href="/dashboard" />
                <Title title="Ochre" margin='0px 0px 10px 0px'/>
                <ScansListContext.Provider value={{ scans, setScans }}>
                    <UploadScans />
                    <ScanMenu 
                        title="Ready to Scan"
                        filter={item => item.state <= StepState.READY}
                        showProcessButton={true}
                        buttonsDisabled={true}
                    />
                    <ScanMenu 
                        title="Ongoing Scans"
                        filter={item => item.state === StepState.PROCESSING}
                        bgColour="zanista-orange-light"
                    />
                    <ScanMenu 
                        title="Finished Scans" 
                        filter={item => item.state === StepState.COMPLETED}
                        bgColour="zanista-orange-mid"
                    />
                </ScansListContext.Provider>
            </div>
            <ScrollToTopButton />
        </div>
    );
}