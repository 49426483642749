import { useContext, useLayoutEffect } from "react";
import { Tab } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import ProcessingWaitPage from "src/pages/goodpoint/exam/components/ProcessingWaitPage";
import ResetTabButton from "src/pages/goodpoint/exam/components/ResetTabButton";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


export default function GradingProcessing() {
    const { examObject, setFooterControls } = useContext(ExamContext);
        
    useLayoutEffect(() => {
        setFooterControls(null);
    }, [setFooterControls]);
    
    return <ProcessingWaitPage
        logsEndpoint={GoodPointApi.Grading.Logs(examObject.id)}
        stateName={"grading"}
        text="Grading exam..."
        onError={() => {
            setFooterControls([
                <ResetTabButton tab={Tab.GRADING} />
            ])
        }}
    />
}