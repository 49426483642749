import "./ProgressBar.css"

function ProgressBar({ progress, text = null, height = 12, colour="zanista-orange-mid" }) {
    const p = Math.round(progress * 100)
    return (
        <div className={`relative h-${height} border-solid border-2 border-black rounded-lg overflow-hidden`}>
            <div className={`h-full bg-${colour}`} style={{ width: `${p}%` }}></div>
            <div className="absolute top-0 left-0 w-full h-full">
                <div className="flex flex-row w-full h-full justify-between align-center p-3">
                    {text && <p>{text}</p>}
                    <b>{p}%</b>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar