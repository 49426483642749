import { createContext } from "react"

const ClassGradingContext = createContext({
    classFeedback: null,
    setClassFeedback: (_c) => {},
    questionFeedback: null,
    studentsPerformance: null,
});

export default ClassGradingContext;
