import { useContext } from "react";
import { ExamStructure } from "src/components/content/exam/ExamStructure";
import "./RubricStructure.css";
import { RubricItem } from "src/components/content/exam/rubric-structure/RubricItem";
import LoadingTab from "src/components/ui/LoadingTab";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";
import useSendEdit from "src/api/goodpoint/useSendEdit";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


export default function RubricStructure() {
    const { examObject } = useContext(ExamContext);
    const { rubric, rubricUrl, isEnhanced } = useContext(RubricContext);
    const { sendEdit, isSending } = useSendEdit(GoodPointApi.Rubric.Edit(examObject.id));

    function RubricHeader({ structure }) {
        const numQuestions = structure.length;
        const totalMarks = structure.reduce(
            (acc, q) => q.max_marks ? acc + q.max_marks : acc, 0
        );

        return <div className="flex-row align-center gap-large">
            <p>{numQuestions} Question{(numQuestions > 1) ? "s" : ""}</p>
            <p>Total Marks: <b>{totalMarks}</b></p>
        </div>
    }

    if (!rubric) {
        return <LoadingTab />;
    }

    return (
        <div className="flex-col gap-mid">
            <ExamStructure
                saveEdit={sendEdit}
                isSavingEdit={isSending}
                structure={rubric}
                HeaderComponent={RubricHeader}
                ItemComponent={RubricItem}
                itemCommonProps={{ isEnhanced }}
                fileUrl={rubricUrl}
                listDisplay={[1, 1, 1, 1, 1, 1]}
            />
        </div>
    );
}
