import { Tab } from "src/api/goodpoint/Const";
import "./StudentGrading.css"
import { useContext, useEffect, useState } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useCallApi from "src/api/useCallApi";
import Divider from "src/components/layout/Divider";
import LoadingTab from "src/components/ui/LoadingTab";
import ResetTabButton from "src/pages/goodpoint/exam/components/ResetTabButton";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";
import GradingStudentsList from "src/pages/goodpoint/exam/tabs/grading/student-grading/GradingStudentsList";
import StudentStats from "src/pages/goodpoint/exam/tabs/grading/student-grading/StudentStats";
import StudentQuestionFeedback from "src/components/content/exam/grading-structure/student-feedback/StudentFeedback";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";
import useFetchRubricQuestions from "src/api/goodpoint/useFetchRubric";


export default function StudentGrading() {
    const callApi = useCallApi();
    const { examObject } = useContext(ExamContext);
    const fetchRubric = useFetchRubricQuestions(examObject.id);
    const { examResults, clickedStudent, setClickedStudent } = useContext(GradingContext);

    const [students, setStudents] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(undefined);  // start with undefined to wait for sorting

    const [orderBy, setOrderBy] = useState("grade");
    const [sorted, setSorted] = useState(false);

    const [isAscendingOrder, setIsAscendingOrder] = useState(false);
    const [answerPdfUrl, setAnswerPdfUrl] = useState(null);
    const [rubric, setRubric] = useState(null);

    useEffect(() => {
        if (students) return;
        callApi("GET", GoodPointApi.Grading.Feedback.Student.All(examObject.id))
            ?.then(response => {
                if (response.ok) {
                    response.json().then(body => {
                        const result = body["data"]["student_feedbacks"];
                        setStudents(result);
                    });
                }
            });
    }, [callApi, examObject.id, students]);

    useEffect(() => {
        if (!students || sorted) return;
        setStudents((ss) => {
            const newStudents = Object
                .entries(ss)
                .sort(([, student1], [, student2]) => {
                    var result;
                    if (orderBy === "grade") {
                        const s1TotalMarks = student1["total_marks"];
                        const s2TotalMarks = student2["total_marks"];
                        result = s1TotalMarks - s2TotalMarks;
                    } else {
                        if (student1[orderBy] && student2[orderBy]) {
                            result = student1[orderBy] - student2[orderBy];
                        } else {
                            result = student1.id.localeCompare(student2.id);
                        }
                    }
                    return isAscendingOrder ? result : -result;
                });
            return Object.fromEntries(newStudents);
        });
        setSelectedStudent(null);
        setSorted(true);
    }, [isAscendingOrder, orderBy, sorted, students]);

    useEffect(() => {
        if ((selectedStudent !== null) || !students || (Object.keys(students).length === 0)) return;
        const selectedStudentId = clickedStudent ?? Object.keys(students)[0];
        setSelectedStudent(students[selectedStudentId]);
        if (clickedStudent) {
            setClickedStudent(undefined);
        }
    }, [clickedStudent, selectedStudent, setClickedStudent, students]);
    
    useEffect(() => {
        if (!selectedStudent) return;
        callApi("GET", GoodPointApi.Answers.Student.Link(examObject.id, selectedStudent.id))
            ?.then(response => {
                if (response.status === 200) {
                    response.json().then(body => {
                        const url = body["data"]["answer_url"];
                        setAnswerPdfUrl(url);
                    })
                }
            });
    }, [callApi, examObject.id, selectedStudent]);

    useEffect(() => {
        if (rubric) return;
        let fetchedRubric = fetchRubric();
        if (fetchedRubric) {
            if (fetchedRubric["rubric"]) {
                const newRubric = fetchedRubric["rubric"].sort((q1, q2) => q1["index"] - q2["index"]);
                setRubric(newRubric);
            }
        }
    }, [fetchRubric, rubric]);

    if (!selectedStudent) {
        return <LoadingTab />;
    }

    return (
        <div className="results-content fill-width fill-height flex-col gap-large">
            <StudentsContext.Provider value={{
                students,
                setStudents,
                selectedStudent,
                setSelectedStudent,
                answerPdfUrl
            }}>
                <GradingStudentsList
                    setOrderBy={(ob) => { 
                        setSorted(false); setOrderBy(ob);
                    }}
                    setIsAscendingOrder={(ao) => {
                        setSorted(false); setIsAscendingOrder(ao);
                    }}
                    {...{ examResults, orderBy, isAscendingOrder }}
                />
                <Divider />
                <StudentStats />
                <Divider />
                <h2 className="text-lg"><b>Marking Per Question</b></h2>
                <RubricContext.Provider value={{
                    rubric, setRubric, rubricUrl: null, setRubricUrl: () => {}, editMode: null,
                    setEditMode: () => {}, isEnhanced: false
                }}>
                    <StudentQuestionFeedback />
                </RubricContext.Provider>
                <div className="flex flex-row justify-between">
                    <ResetTabButton tab={Tab.GRADING} />
                </div>
            </StudentsContext.Provider>
        </div>
    )
}