import "src/pages/newswitch/components/ReportContainer.css";
import "src/pages/newswitch/components/ReportContainerNew.css";
import BackLink from 'src/components/nav/BackLink';
import useResetScroll from "src/hooks/useResetScroll";
import { useCallback, useEffect, useState } from 'react';
import { NewsListsDict } from './components/NewsLists';
import PPPChart from './components/PPPChart';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider, Tooltip } from '@mui/material';
import SentimentChart from "./components/SentimentChart";
import Select from "react-select";
import "./DateRangeSelect.css";
import useFetchSentiment from "src/api/newswitch/useFetchSentiment";
import useFetchNews from "src/api/newswitch/useFetchNews";
import useFetchPriceData from "src/api/newswitch/useFetchPriceData";
import TickerSearchBar from "./components/TickerSearchBar";
import { Link } from "react-router-dom";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";
import Popup from "reactjs-popup";
import { EditPencil, UndoCircle } from "iconoir-react";
import useFetchTickerInfo from "src/api/newswitch/useFetchTickerInfo";
import { useSearchParams } from "react-router-dom";

const statuses = {
    loading: "loading",
    loaded: "loaded",
    error: "error"
}

function NewsWitchDashboard() {
    useResetScroll();

    // Use query params to set the ticker, start date and end date
    const [searchParams, setSearchParams] = useSearchParams();

    const queryTicker = searchParams.get("ticker") || "NASDAQ:AAPL"; // Default to NASDAQ:AAPL if no ticker is set
    const queryStartDate = searchParams.get("start_date");
    const queryEndDate = searchParams.get("end_date");
    const isQueryParamSet = queryTicker !== undefined;

    // Update the query params
    const updateParam = useCallback(
        (key, value) => {
            const newParams = new URLSearchParams(searchParams);
            newParams.set(key, value);

            const sortedParams = new URLSearchParams({
                ...(newParams.get("ticker") && { ticker: newParams.get("ticker") }),
                ...(newParams.get("start_date") && { start_date: newParams.get("start_date") }),
                ...(newParams.get("end_date") && { end_date: newParams.get("end_date") })
            });
            setSearchParams(sortedParams);
        },
        [searchParams, setSearchParams]
    );

    const [startDate, setStartDate] = useState(() => {
        if (queryStartDate && /^\d{4}-\d{2}-\d{2}$/.test(queryStartDate)) {
            let date = new Date(queryStartDate);
            // Check if the date is in the future
            if (date.getTime() > new Date().getTime()) {
                date = new Date();
            }
            date.setHours(0, 0, 0, 0);
            return date;
        }
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
        return yesterday;
    });

    const [endDate, setEndDate] = useState(() => {
        if (queryEndDate && /^\d{4}-\d{2}-\d{2}$/.test(queryEndDate)) {
            let date = new Date(queryEndDate);
            // Check if the date is before the start date
            if (date.getTime() < startDate.getTime()) {
                date = startDate;
            }
            // Check if the date is in the future
            if (date.getTime() > new Date().getTime()) {
                date = new Date();
            }
            date.setHours(23, 59, 59, 999);
            return date;
        }
        const today = new Date();
        today.setHours(23, 59, 59, 999);
        return today;
    });

    const [tickerInfo, setTickerInfo] = useState(undefined);
    const [ticker, setTicker] = useState(() => {
        // Only allow alphanumerics, colons and spaces
        if (queryTicker && /^[a-zA-Z0-9:\s]+$/.test(queryTicker)) {
            return queryTicker;
        }
        if (tickerInfo?.exchange && tickerInfo?.exchange !== "") {
            return tickerInfo?.exchange + ":" + tickerInfo?.ticker;
        }
        return tickerInfo?.ticker;
    });
    const [linkedTickerInfo, setLinkedTickerInfo] = useState(undefined);

    // TODO: This is an extra API call as we also get ticker information in the TickerInfoSection
    const fetchTickerInfo = useFetchTickerInfo();
    useEffect(() => {
        if (ticker === undefined) return;
        const splitTicker = ticker.includes(":") ? ticker.split(":")[1] : ticker;
        fetchTickerInfo(splitTicker)?.then(info => {
            if (info === undefined) return; // TODO: Handle error where ticker is not found
            setTickerInfo(info);
            if (info["linked_ticker"] !== undefined) {
                fetchTickerInfo(info["linked_ticker"]).then(linkedTickerInfo => {
                    setLinkedTickerInfo(linkedTickerInfo);
                });
            }
        });
    }, [ticker, fetchTickerInfo]);

    // Update the query params when the ticker/dates changes
    useEffect(() => {
        if (ticker === undefined) return;
        updateParam("ticker", ticker);
    }, [ticker, updateParam, queryTicker]);

    useEffect(() => {
        if (startDate === undefined || ticker === undefined) return;
        if (startDate.toISOString().split('T')[0] === queryStartDate) return;
        updateParam("start_date", startDate.toISOString().split('T')[0]);
    }, [startDate, ticker, updateParam, queryStartDate]);

    useEffect(() => {
        if (endDate === undefined || ticker === undefined) return;
        if (endDate.toISOString().split('T')[0] === queryEndDate) return;
        updateParam("end_date", endDate.toISOString().split('T')[0]);
    }, [endDate, ticker, updateParam, queryEndDate]);

    const [search, setSearch] = useState({
        ticker: ticker,
        startDate: startDate,
        endDate: endDate,
        requestID: 1,
        status: statuses.loading
    });

    const [newsData, setNewsData] = useState({});
    const [news, setNews] = useState([]); // news is a list of dates and their news data
    const [sentimentData, setSentimentData] = useState([]); // Sentiment is a list of dates and their sentiment data
    const [prices, setPrices] = useState([]);
    const [pppNews, setPppNews] = useState([]);
    const [relevantOnly, setRelevantOnly] = useState(true);

    const fetchSentiment = useFetchSentiment();
    const fetchNews = useFetchNews();
    const fetchPPPNews = useFetchNews(true);
    const fetchPriceData = useFetchPriceData();
    
    useEffect(() => {
        if (search.ticker === undefined || search.startDate === undefined || search.endDate === undefined) return;

        // TODO: try and get the data from local if we have it already

        setSearch(prev => ({
            ...prev,
            status: statuses.loading
        }));

        // Get PPP news / price data only
        const pppStartDate = new Date(search.endDate.getTime());
        // Try event date only first
        pppStartDate.setDate(pppStartDate.getDate());
        pppStartDate.setHours(0, 0, 0, 0);

        const pppEndDate = new Date(search.endDate.getTime()); 
        pppEndDate.setHours(23, 59, 59, 999);

        // TODO: Move this inside PPP to allow for linked ticker
        fetchPPPNews(search.ticker, pppStartDate, pppEndDate, true, search.requestID)?.then(news => {
            if (news.id === search.requestID) {
                if (news.news.length > 0) {
                    setPppNews(news.news);
                    fetchPriceData(search.ticker, search.endDate)?.then(prices => {
                        setPrices(prices);
                    });
                } else {
                    // No news on event date, try including previous day aswell
                    pppStartDate.setDate(pppStartDate.getDate() - 1);
                    pppStartDate.setHours(0, 0, 0, 0);
                    fetchPPPNews(search.ticker, pppStartDate, pppEndDate, true, search.requestID)?.then(news => {
                        if (news.id === search.requestID) {
                            setPppNews(news.news);
                            fetchPriceData(search.ticker, search.endDate)?.then(prices => {
                                setPrices(prices);
                            });
                        }
                    });
                }
            }
        });

        const oneDayAgo = new Date(search.startDate);
        oneDayAgo.setDate(oneDayAgo.getDate() - 1);
        oneDayAgo.setHours(0, 0, 0, 0);

        const twoDaysAgo = new Date(search.startDate);
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
        twoDaysAgo.setHours(0, 0, 0, 0);

        fetchSentiment(search.ticker, search.startDate, search.endDate, search.requestID)?.then(sentiment => {
            setTicker(search.ticker);
            // @ts-ignore
            if (sentiment.id === search.requestID) {
                // @ts-ignore
                setSentimentData(sentiment.sentiment);
                fetchNews(search.ticker, oneDayAgo, search.endDate, relevantOnly, search.requestID)?.then(news => {
                    if (news.id === search.requestID) {
                        setNews(news.news);
                        setSearch(prev => ({
                            ...prev,
                            status: statuses.loaded
                        }));
                        fetchNews(search.ticker, twoDaysAgo, oneDayAgo, relevantOnly, search.requestID)?.then(news => {
                            if (news.id === search.requestID) {
                                setNews(prev => [...prev, ...news.news]);
                                fetchNews(search.ticker, search.startDate, twoDaysAgo, relevantOnly, search.requestID)?.then(news => { // TODO: Don't even load these, only load a day when selected
                                    if (news.id === search.requestID) {
                                        setNews(prev => [...prev, ...news.news]);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }, [search.ticker, search.startDate, search.endDate, search.requestID, relevantOnly, fetchSentiment, fetchPriceData, fetchNews, fetchPPPNews]);

    // Merge news and sentiment data
    useEffect(() => {
        const merged = Array.from(
            { length: Math.ceil((endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)) },
            (_, i) => new Date(startDate.getTime() + i * (24 * 60 * 60 * 1000)).toISOString().split('T')[0]
        ).map(date => {
            const s = sentimentData.find(s => s["date"] === date);
            const n = news.find(n => n["published_date"] === date);
            if (s && n) {
                return {
                    ...s,
                    news: n["news"]
                };
            } else if (s) {
                return {
                    ...s,
                    news: []
                }
            } else if (n) {
                return {
                    date: n["published_date"],
                    sentiment: {},
                    has_news: n["news"].length > 0,
                    news: n["news"]
                };
            } else {
                return {
                    date: date,
                    sentiment: {},
                    has_news: false,
                    news: []
                };
            }
        });
        setNewsData({
            [search.ticker]: merged
        });
    }, [news, sentimentData, startDate, endDate, search.ticker]);

    // Change the ticker to search
    const onSelect = (selection) => {
        const newTicker = selection['exchange'] && selection['exchange'] !== "" ? `${selection['exchange']}:${selection['ticker']}` : selection['ticker'];
        setRelevantOnly(true);
        setSearch(prev => ({
            ...prev, 
            ticker: newTicker,
            requestID: prev.requestID + 1
        }));
        setLinkedTickerInfo(undefined);
        setTickerInfo(selection);
    }

    // Change the date range to search
    const onChange = (start, end) => {
        const newStartDate = start.toDate();
        newStartDate.setHours(0, 0, 0, 0);

        const newEndDate = end.toDate();
        newEndDate.setHours(23, 59, 59, 999);

        if (newStartDate === undefined || newEndDate === undefined) return;

        setRelevantOnly(true);
        setSearch(prev => ({
            ...prev, 
            startDate: newStartDate, 
            endDate: newEndDate,
            requestID: prev.requestID + 1
        }));

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }

    const exchangeTickerFromTickerInfo = (tickerInfo) => {
        if (tickerInfo["exchange"] && tickerInfo["exchange"] !== "") {
            return `${tickerInfo["exchange"]}:${tickerInfo["ticker"]}`;
        } else {
            return tickerInfo["ticker"];
        }
    }

    return (
        <div className="centered">
            <div className="container">
                <TitleSection />
                <SearchSection 
                    ticker={ticker}
                    onSelect={onSelect}
                    onChange={onChange}
                    startDefault={startDate}
                    endDefault={endDate}
                />
                {
                    !isQueryParamSet &&
                    // TODO: Add Dashboard Section here
                    <div className="flex flex-col report-container-new w-full">
                        <div className="flex flex-row justify-between items-start">
                            <div 
                                className="flex flex-col"
                            >
                                <h3>AAPL</h3>
                                <p>Apple Inc.</p>
                            </div>
                        </div>
                    </div>   
                }
                {
                    isQueryParamSet && tickerInfo &&
                <>
                    <TickerInfoSection 
                        name={tickerInfo.name} 
                        exchange={tickerInfo.exchange} 
                        ticker={tickerInfo.ticker} 
                        startDate={startDate} 
                        endDate={endDate}
                        setUpstreamLinkedTickerInfo={setLinkedTickerInfo}
                    />
                    <div className="flex flex-col report-container-new w-full">
                        <NewsListSection 
                            newsData={newsData} 
                            ticker={ticker} 
                            isLoading={search.status === statuses.loading} 
                            noNewsElement={
                                relevantOnly ?
                                    <div className="flex-row items-center gap-2 ml-2">
                                        <button className="text-xs h-min p-1 w-fit rounded outline outline-1 hover:bg-[var(--zanista-orange-mid)]"
                                            onClick={() => {
                                                setRelevantOnly(!relevantOnly);
                                            }}
                                        >
                                            Show Irrelevant News
                                        </button>
                                    </div>
                                :
                                    (search.status === statuses.loading ?
                                        <p className="ml-4">Loading...</p>
                                    :
                                        <p className="ml-4">No News</p>
                                    )
                            }
                        />
                        <ChartSection title="Price Prediction Plot">
                            <PPPChart 
                                news={pppNews} 
                                prices={((tickerInfo.exchange !== undefined && tickerInfo.exchange !== "") || linkedTickerInfo !== undefined) ? prices : []}
                                eventDate={endDate} 
                                ticker={ticker}
                            />
                        </ChartSection>
                        <ChartSection title="Sentiment Plot">
                            <SentimentChart 
                                ticker={ticker} 
                                title={linkedTickerInfo ? `${tickerInfo.ticker} (${exchangeTickerFromTickerInfo(linkedTickerInfo)})` : exchangeTickerFromTickerInfo(tickerInfo)} 
                                eventDate={endDate} 
                                showPriceLine={(tickerInfo.exchange !== undefined && tickerInfo.exchange !== "") || linkedTickerInfo !== undefined}
                            />
                        </ChartSection>
                        <DisclaimerSection />
                    </div>
                </>
                }
            </div>
        </div>
    );
}

function TitleSection() {
    return (
        <div className="flex flex-row items-end justify-between gap-4 w-full mb-4">
            <BackLink prevPage="Dashboard" href="/dashboard" />
            <h1 className="text-2xl">NewsWitch</h1>
        </div>
    )
}

function SearchSection({ ticker, onSelect = (selection) => {}, onChange = () => {}, startDefault = undefined, endDefault = undefined }) {
    return (
        <div className="flex flex-row items-center justify-between gap-4 w-full mb-4">
            <div className="flex-1">
                <TickerSearchBar 
                    onSelect={onSelect} 
                    maxNameLength={100} 
                    showAddCustomTicker={true}
                    onCustomTickerSelect={(ticker, exchange) => {
                        onSelect({
                            ticker: ticker,
                            exchange: exchange
                        });
                    }}
                />
            </div>
            <div className="">
                <DateRangePicker onChange={onChange} startDefault={startDefault} endDefault={endDefault} />
            </div>
            <div className="min-w-24">
                <DateRangeSelect onChange={onChange} />
            </div>
            <div className="max-w-32">
                <ManualRunButton ticker={ticker} startDate={startDefault} endDate={endDefault} />
            </div>
        </div>
    )
}

function TickerInfoSection({ name, exchange, ticker, startDate, endDate, setUpstreamLinkedTickerInfo = (info) => {} }) {

    const [tickerInfo, setTickerInfo] = useState(undefined);
    const [linkedTickerInfo, setLinkedTickerInfo] = useState(undefined);
    const fetchTickerInfo = useFetchTickerInfo();

    useEffect(() => {
        setTickerInfo(undefined);
        fetchTickerInfo(ticker)?.then(tickerInfo => {
            setTickerInfo(tickerInfo);
            if (tickerInfo["linked_ticker"] !== undefined) {
                fetchTickerInfo(tickerInfo["linked_ticker"]).then(linkedTickerInfo => {
                    setLinkedTickerInfo(linkedTickerInfo);
                    setUpstreamLinkedTickerInfo(linkedTickerInfo);
                });
            }
        });
    }, [ticker, fetchTickerInfo, setUpstreamLinkedTickerInfo]);

    return (
        <div className="flex flex-row justify-between mt-5 mb-1">
            <Tooltip 
                title={tickerInfo ? tickerInfo["description"] : ""} 
                placement="right" 
                style={{cursor: "help"}} 
                componentsProps={{
                    tooltip: {
                        sx: {
                            color: "black", 
                            backgroundColor: "var(--zanista-orange-light)",
                            fontSize: "12px",
                            maxHeight: "200px",
                            overflowY: "auto",
                            border: "1px solid black"
                        }
                    }
                }}
            >
                <div className="flex flex-col justify-center items-start">
                    <div className="flex flex-row gap-x-1 items-top">
                        <h1 className="text-xl">
                            {tickerInfo ? tickerInfo["name"] : name}
                        </h1>
                        {/* <InfoCircle width={16} height={16} className="mt-[2px]" /> */}
                    </div>
                    {
                        tickerInfo && tickerInfo["ticker"] ?
                        <p className="text-sm">
                            {tickerInfo["exchange"] && tickerInfo["exchange"] !== "" ? tickerInfo["exchange"] + ":" + tickerInfo["ticker"] : tickerInfo["ticker"]}
                        </p>
                        :
                        <p className="text-sm">
                            {exchange && exchange !== "" ? exchange + ":" + ticker : ticker}
                        </p>
                    }
                    {tickerInfo && (
                        <p className="text-xs text-start">
                            {tickerInfo["asset_class"] ? tickerInfo["asset_class"].replace("_", " ") : "N/A"}
                        </p>
                    )}
                </div>
            </Tooltip>
            {
                tickerInfo && tickerInfo["asset_class"] && tickerInfo["asset_class"] === "EQUITY" &&
                (
                    <div className="flex flex-col gap-y-0">
                        <p className="text-sm text-start">
                            Sector:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tickerInfo["sector"] ? tickerInfo["sector"] : "N/A"}
                        </p>
                        <p className="text-sm text-start">
                            Industry:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tickerInfo["industry"] ? tickerInfo["industry"] : "N/A"}
                        </p>
                        <p className="text-sm text-start">
                            Location:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tickerInfo["location"] ? tickerInfo["location"] : "N/A"}
                        </p>
                    </div>
                )
            }
            {
                tickerInfo && (!tickerInfo["asset_class"] || tickerInfo["asset_class"] !== "EQUITY") && linkedTickerInfo &&
                (
                    <div className="flex flex-col justify-end gap-y-0">
                        <p className="text-sm text-start"
                            onClick={() => {
                                // TODO: OnClick search for the linked ticker
                            }}
                        >
                            Linked Ticker:&nbsp;{linkedTickerInfo["exchange"] && linkedTickerInfo["exchange"] !== "" ? linkedTickerInfo["exchange"] + ":" + linkedTickerInfo["ticker"] : linkedTickerInfo["ticker"]}
                        </p>
                    </div>
                )
            }
        </div>
    )
}

function NewsListSection({ newsData, ticker, isLoading, noNewsElement = undefined }) {
    return (
        <div className="section w-full">
            <NewsListsDict newsData={newsData} ticker={ticker} isLoading={isLoading} noNewsElement={noNewsElement} />
        </div>
    )
}

function ChartSection({ title, rightElement = null, children }) {
    return (
        <div className="section chart-section">
            <div className="flex flex-row justify-between items-start">
                <h2>{title}</h2>
                {rightElement}
            </div>
            {children} 
        </div>
    )
}

function ManualRunButton({ ticker, startDate, endDate }) {

    const callApi = useNewsWitchApi();
    const [isLoading, setIsLoading] = useState(false);
    const [localStartDate, setLocalStartDate] = useState(startDate);
    const [localEndDate, setLocalEndDate] = useState(endDate);
    const [localTicker, setLocalTicker] = useState(ticker);
    const [error, setError] = useState(undefined);

    const createManualRun = useCallback((ticker, startDate, endDate, sendEmail = true) => {
        setError(undefined);
        if (startDate === undefined || endDate === undefined) return;
        const startDateStr = startDate.toISOString().split('T')[0];
        const endDateStr = endDate.toISOString().split('T')[0];
        return callApi("POST", `/manual_run?ticker=${ticker}&start_date=${startDateStr}&end_date=${endDateStr}&notify_users=${sendEmail}`)?.then(response => {
            if (response.status === 200) {
                return true;
            } else {
                response.json().then(body => {
                    const error = body["error"];
                    setError(error);
                    return false;
                });
            }
        });
    }, [callApi]);

    const onClick = (close) => {
        setIsLoading(true);
        createManualRun(localTicker, localStartDate, localEndDate).then((success) => {
            setIsLoading(false);
            if (success) {
                close();
                setIsTooltipOpen(true);
            }
        });
    }

    const onDateChange = (start, end) => {
        const newStartDate = start.toDate();
        newStartDate.setHours(0, 0, 0, 0);

        const newEndDate = end.toDate();
        newEndDate.setHours(23, 59, 59, 999);

        if (newStartDate === undefined || newEndDate === undefined) return;

        setLocalStartDate(newStartDate);
        setLocalEndDate(newEndDate);
    }

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [popupTickerSearch, setPopupTickerSearch] = useState(false);

    const onTickerSelect = (selection) => {
        if (selection["exchange"] === undefined || selection["exchange"] === null || selection["exchange"] === "") {
            setLocalTicker(selection["ticker"]);
        } else {
            setLocalTicker(selection["exchange"] + ":" + selection["ticker"]);
        }
        setPopupTickerSearch(false);
    }

    return (
        <Popup trigger={
            <Tooltip 
                title={isTooltipOpen ? "You will receive a notification on completion" : ""} 
                placement="bottom-end" 
                open={isTooltipOpen}
                componentsProps={{
                    tooltip: {
                        sx: {
                            color: "black", 
                            backgroundColor: "var(--zanista-orange-light)",
                            fontSize: "12px",
                            border: "1px solid black"
                        }
                    }
                }}
            >
                <button 
                    className="text-xs w-full min-h-11 max-h-11 p-2 py-0 rounded outline outline-1 outline-gray-300 hover:bg-[var(--zanista-orange-mid)] hover:outline-black" 
                    disabled={isTooltipOpen}
                >
                    Request Tailored NewsWitch Run
                </button>
            </Tooltip>
        } modal nested
        onOpen={() => {
            setLocalStartDate(startDate);
            setLocalEndDate(endDate);
            setLocalTicker(ticker);
            setPopupTickerSearch(false);
            setError(undefined);
        }}
        onClose={() => {
            setTimeout(() => {
                setIsTooltipOpen(false);
            }, 5000);
        }}
        >
            {
                // @ts-ignore
                close => (
                    <div className='flex-col p-4 text-sm bg-white rounded gap-4 max-w-2/3'>
                        <h2>Request Tailored NewsWitch Run</h2>
                        <hr className='w-full' />
                        {   !popupTickerSearch && 
                            <div className="flex-row gap-2 text-sm justify-between items-center">
                                <h3>Ticker: {localTicker}</h3>
                                <div className="flex-row gap-2 items-center cursor-pointer"
                                    onClick={() => {
                                        setPopupTickerSearch(true);
                                    }}
                                >
                                    <EditPencil width={16} height={16} />
                                </div>
                            </div>
                        }
                        {
                            popupTickerSearch && (
                                <div className="flex-row gap-2 text-sm justify-start items-center">
                                    <div className="flex-1">
                                        <TickerSearchBar 
                                            onSelect={onTickerSelect} 
                                            onCustomTickerSelect={(ticker, exchange) => {
                                                onTickerSelect({
                                                    ticker: ticker,
                                                    exchange: exchange
                                                });
                                            }}
                                            maxNameLength={25} 
                                            showAddCustomTicker={true} />
                                    </div>
                                    <div className="cursor-pointer"
                                        onClick={() => {
                                            setPopupTickerSearch(false);
                                        }}
                                    >
                                        <UndoCircle width={24} height={24} />
                                    </div>
                                </div>
                            )
                        }
                        <DateRangePicker onChange={onDateChange} startDefault={localStartDate} endDefault={localEndDate} disabled={isLoading} />
                        <div className='flex-row gap-3 justify-start'>
                            <button 
                                className={"text-xs h-min p-2 my-2 w-fit rounded outline outline-1 " + (isLoading ? "bg-[var(--zanista-grey-light)]" : "bg-[var(--zanista-orange-light)]")}
                                onClick={() => {close();}}
                                disabled={isLoading}
                            >
                                Cancel
                            </button>
                            <button 
                                className={"text-xs h-min p-2 my-2 w-fit rounded outline outline-1 " + (isLoading ? "bg-[var(--zanista-grey-mid)]" : "bg-[var(--zanista-orange-mid)]")}
                                onClick={() => onClick(close)}
                                disabled={isLoading}
                            >
                                Start Run
                            </button>
                        </div>
                        {error && (
                            <p className="text-xs text-red-500">
                                {error}
                            </p>
                        )}
                    </div>
                )
            }
        </Popup>
    )
}

// @ts-ignore
function DateRangePicker({ onChange = (start, end) => {}, startDefault = undefined, endDefault = undefined, disabled = false }) {
    
    const theme = createTheme({
        palette: {
            primary: {
                main: "#F49569",
                dark: "#EF753D"
            },
            secondary: {
                main: "#F49569"
            }
        },
        typography: {
            fontSize: 11,
        },
    });

    const [startDate, setStartDate] = useState(startDefault ? dayjs(startDefault) : dayjs().add(-1, 'day'));
    const [endDate, setEndDate] = useState(endDefault ? dayjs(endDefault) : dayjs());

    useEffect(() => {
        if (startDefault === undefined || endDefault === undefined) return;
        setStartDate(dayjs(startDefault));
        setEndDate(dayjs(endDefault));
    }, [startDefault, endDefault]);

    const setDateRange = (isStart, date) => {
        if (isStart && !date.isAfter(endDate)) {
            setStartDate(date);
            onChange(date, endDate);
        } else if (!isStart && !date.isBefore(startDate)) {
            setEndDate(date);
            onChange(startDate, date);
        } else if (isStart && date.isAfter(endDate)) {
            setStartDate(date);
            setEndDate(date);
            onChange(date, date);
        } else if (!isStart && date.isBefore(startDate)) {
            setStartDate(date);
            setEndDate(date);
            onChange(date, date);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <div className="flex flex-row gap-2 justify-end items-center max-w-72 flex-grow-0 flex-shrink-0 flex-auto">
                    <DatePicker
                        format='DD MMM YYYY'
                        value={startDate}
                        onChange={(event) => setDateRange(true, event)}
                        disableFuture={true}
                        disabled={disabled}
                    />
                    <p className="text-center">-</p>
                    <DatePicker
                        format='DD MMM YYYY'
                        value={endDate}
                        onChange={(event) => setDateRange(false, event)}
                        disableFuture={true}
                        disabled={disabled}
                    />
                </div>
            </ThemeProvider>
        </LocalizationProvider>
    )
}

// @ts-ignore
// @ts-ignore
function DateRangeSelect({ onChange = (start, end) => {} }) {

    const customStyles = {
        control: (styles) => ({
          ...styles,
          backgroundColor: 'white',
          borderRadius: '4px', 
          height: '53px',
          minWidth: '130px',
          ':hover': {
            borderColor: 'black', // Yellow border on hover
          },
        }),
        option: (styles, { isFocused, isSelected }) => ({
          ...styles,
          backgroundColor: isSelected ? 'var(--zanista-orange-mid)' : isFocused ? 'var(--zanista-yellow-light)' : undefined, // Orange for selected and yellow for hover
          color: isSelected ? 'white' : 'black', // White text when selected, black otherwise
          cursor: 'pointer',
          ':active': {
            ...styles[':active'],
            backgroundColor: isSelected ? 'var(--zanista-orange-mid)' : 'var(--zanista-yellow-light)', // Same active state as hover
          },
          transition: 'background-color 0.15s ease-in-out', // Smooth transition for background color
        }),
        placeholder: (styles) => ({
          ...styles,
          color: '#ccc',
        }),
        singleValue: (styles) => ({
          ...styles,
          color: 'black', // Style for the selected value (default to black text)
        }),
    };

    const endDate = dayjs();
    const options = [
        {"title": "Today", "start": endDate, "end": endDate},
        {"title": "Yesterday", "start": endDate.add(-1, 'day'), "end": endDate.add(-1, 'day')},
        {"title": "Last Week", "start": endDate.add(-7, 'day'), "end": endDate},
        {"title": "Last Month", "start": endDate.add(-31, 'day'), "end": endDate},
        {"title": "Last Year", "start": endDate.add(-365, 'day'), "end": endDate},
    ];
    const [selectedDate, setSelectedDate] = useState({
        start: options[0].start,
        end: options[0].end
    });
  
    const dateOptions = options.map(
      (option) => {
        return {
          value: {
            start: option.start,
            end: option.end
          },
          label: (
            <div className="date-range-button-grid fill-width"> 
              <p className="text-xs">
                {option.title}
              </p>
            </div>
          )
        };
      }
    );
  
    const handleDateChange = (selectedOption) => {
      if (selectedOption) {
        setSelectedDate(selectedOption.value);
        onChange(selectedOption.value.start, selectedOption.value.end);
      }
    };
  
    return (
      <>
        <Select
            className="basic-single"
            classNamePrefix="select"
            value={dateOptions.find(
                (option) => option.value.start === selectedDate.start && option.value.end === selectedDate.end
            )}
            styles={customStyles}
            isClearable={false}
            isSearchable={false}
            name="date-range"
            options={dateOptions}
            onChange={handleDateChange}
        />
      </>
    );
  }

function DisclaimerSection() {
    return (
        <div className="section intro">
            <p><b>By viewing and/or using this report you are consenting to the <Link to="/legal?tab=terms">Terms and Conditions</Link> of Zanista AI.</b></p>
            <p>Some content on this page is generated by GPT-4o</p>
            <p><i>Zanista AI Data Privacy and Confidentiality:</i> No input or output data is accessible to other users, OpenAI, or any third-party services. Data is NOT used to train or enhance any models. Verify information before use. Do not distribute without permission.</p>
        </div>
    )
}

export default NewsWitchDashboard;
