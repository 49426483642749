import { createContext, useCallback, useEffect, useReducer, useState } from "react";
import { getFromStructure } from "src/components/content/exam/StructureUtil";


export const Visibility = ({
    HIDDEN: 0,
    PARTIALLY_VISIBLE: 1,
    PARTIALLY_HIDDEN: 2,
    VISIBLE: 3,
});

export const VisibilityContext = createContext({
    visibilityMap: {},
    setVisibility: (_tagList, _vis) => { },
    getVisibility: (_tagList) => Visibility.VISIBLE,
    countHiddenChildren: (_tagList) => 0,
    clear: (_tagList = null) => { },
    hiddenCount: 0
})

function createVisibilityMap(structure) {
    function recurse(struct) {
        const result = struct.map((item) => {
            const visQ = {};
            if ("sub_items" in item && item["sub_items"].length > 0) {
                visQ.subItems = recurse(item["sub_items"]);
            }
            visQ.visibility = Visibility.VISIBLE;
            return visQ;
        });
        return result;
    }
    const result = recurse(structure);
    return result;
}

function visibilityReducer(visibilityMap, action) {
    const result = [ ...visibilityMap ];
    switch (action.type) {
        case "hide":
        case "show": {
            var visQ = result[action.tagList[0]];
            for (const tag of action.tagList.slice(1)) {
                if ("visibility" in visQ && action.type === "show") {
                    visQ.visibility = Math.max(visQ.visibility, Visibility.PARTIALLY_VISIBLE);
                }
                visQ = visQ.subItems;
                visQ = visQ[tag];
            }
            const visible = (action.type === "show");
            if (!visible) {
                setVisibilityAll(visQ.subItems, Visibility.HIDDEN);
                visQ.visibility = Visibility.HIDDEN;
            } else {
                setVisibilityAll(visQ.subItems, Visibility.VISIBLE);
                visQ.visibility = Visibility.VISIBLE;
            }
            break;
        }
        case "clear": {
            if (action.tagList) {
                const visQ = getFromVisibilityStructure(result, action.tagList);
                setVisibilityAll(visQ.subItems, Visibility.VISIBLE);
                visQ.visibility = Visibility.VISIBLE;
            } else {
                setVisibilityAll(result, Visibility.VISIBLE);
            }
            break;
        }
        default: {
            break;
        }
    }
    return result;
}

function getFromVisibilityStructure(visibilityMap, tagList) {
    return getFromStructure(visibilityMap, tagList.flatMap((item, i) => i === 0 ? [item] : ["subItems", item]));
}

function countHiddenChildrenFromMap(visibilityMap, tagList) {
    const visQ = getFromVisibilityStructure(visibilityMap, tagList);
    var result = 0;
    visQ
        ?.subItems
        ?.forEach((val) => {
            if ((val.visibility === Visibility.HIDDEN) || (val.visibility === Visibility.PARTIALLY_HIDDEN)) {
                result += 1;
            }
        });
    return result;
}

function setVisibilityAll(visibilityMap, visible) {
    if (!visibilityMap) return;
    for (const val of visibilityMap) {
        val.visibility = visible;
        setVisibilityAll(val?.subItems, visible);
    }
}

function countHiddenFromMap(visibilityMap, includePartial = false) {
    var count = 0;
    if (visibilityMap) {
        for (const val of visibilityMap) {
            count += countHiddenFromMap(val?.subItems);
            count += (
                (val.visibility === Visibility.HIDDEN)
                || (includePartial &&  val.visibility === Visibility.PARTIALLY_HIDDEN)
            ) ? 1 : 0;
        }
    }
    return count;
}

export function useVisibility(examStructureMap) {
    const [visibilityMap, dispatch] = useReducer(
        visibilityReducer,
        createVisibilityMap(examStructureMap)
    );

    const setVisibility = useCallback(
        // @ts-ignore
        (tagList, vis) => dispatch({ type: vis ? "show" : "hide", tagList }),
        []
    );

    const getVisibility = useCallback(
        (tagList) => getFromVisibilityStructure(visibilityMap, tagList).visibility,
        [visibilityMap]
    );

    const countHiddenChildren = useCallback(
        (tagList) => countHiddenChildrenFromMap(visibilityMap, tagList),
        [visibilityMap]
    )

    const clear = useCallback(
        // @ts-ignore
        (tagList = null) => dispatch({ type: "clear", tagList }),
        []
    );

    const [hiddenCount, setHiddenCount] = useState(0);
    useEffect(() => {
        const count = countHiddenFromMap(visibilityMap);
        setHiddenCount(count);
    }, [visibilityMap]);

    return {
        visibilityMap,
        setVisibility,
        getVisibility,
        countHiddenChildren,
        hiddenCount,
        clear
    }
}
