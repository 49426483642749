import { Collapse, Expand } from "iconoir-react";
import { useContext } from "react";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";

export default function ExpandCollapseButtons({ props = {}, expandProps = {}, collapseProps = {} }) {
    const { isExpanded, expand, isCollapsed, collapse } = useContext(ExpandCollapseContext);

    return <div className="flex flex-row gap-2">
        <button 
            onClick={expand}
            className={
                `flex flex-row items-center gap-1 py-1 px-2 btn-clickable rounded-md ` + 
                `${isExpanded ? "bg-zanista-orange-mid" : "bg-zanista-yellow-light"}`
            }
            {...props}
            {...expandProps}
        >
            <Expand width={"1em"}/> Expand
        </button>
        <button
            onClick={collapse}
            className={
                `flex flex-row items-center gap-1 py-1 px-2 btn-clickable rounded-md ` + 
                `${isCollapsed ? "bg-zanista-orange-mid" : "bg-zanista-yellow-light"}`
            }
            {...props}
            {...collapseProps}
        >
            <Collapse width="1.2em"/><p>Collapse</p>
        </button>
    </div>
}