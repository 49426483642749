import StructureSection from "src/components/content/exam/StructureSection";
import { StructureItem } from "src/components/content/exam/StructureItem";
import { useContext, useState, useEffect, useMemo } from "react";
import { ExamStructureContext } from "src/components/content/exam/ExamStructureContext";
import { Tooltip } from "@mui/material";
import { useHasRole } from "src/api/useGetRole";
import { calcGrade } from "src/pages/goodpoint/exam/tabs/grading/GradeBoundaries";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";
import { getFromStructure, isAnyFlagged } from "src/components/content/exam/StructureUtil";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";


function StudentFeedbackItemHeader({ itemData, nestingLevel }) {
    const [warningOpen, setWarningOpen] = useState(false);
    const { gradeBoundaries } = useContext(GradingContext);

    //this is actually to keep track of the previous mark to update the difference but i'm saur lazy to change it 
    const [marks, setMarks] = useState(parseFloat(itemData.total_marks));
    useEffect(() => {
        setMarks(parseFloat(itemData?.total_marks ?? "0.0"));
    }, [itemData?.total_marks]);
    const maxMarks = useMemo(() => itemData?.max_total_marks, [itemData?.max_total_marks]);
    const percentage = useMemo(() => 100.0 * marks / maxMarks, [maxMarks, marks]);
    const grade = useMemo(() => calcGrade(percentage, gradeBoundaries), [percentage, gradeBoundaries]);
    const flagged = useMemo(() => isAnyFlagged(itemData), [itemData]);

    const readOnly = useHasRole('student');

    return (
        <div className="flex flex-row justify-between fill-width">
            <p className="text-left"><b>{nestingLevel > 0 ? "Part " : ""} {itemData["name"]}</b></p>
            {
                flagged && <p className="text-right text-zanista-red">(NEEDS REVIEW)</p>
            }
            <div className="grid grid-cols-4 gap-2">
                <div className="flex flex-row gap-1 col-span-2">
                    {
                        readOnly
                            ? <p>{marks} / {maxMarks} Marks</p>
                            : <>
                                <input
                                    type="number"
                                    key={`${itemData.name}_${marks}`}
                                    // onBlur={handleMarkChange}
                                    onClick={(event) => event.stopPropagation()}
                                    defaultValue={marks}
                                    className={`w-12 bg-white outline-black focus:outline-accent text-right`}
                                    disabled={readOnly}
                                />
                                <p className="student-feedback-item-marks"> / {maxMarks} Marks</p>
                                <Tooltip
                                    open={warningOpen}
                                    onOpen={() => setWarningOpen(true)}
                                    onClose={() => setWarningOpen(false)}
                                    title="Invalid marks"
                                >
                                    <div></div>
                                </Tooltip>
                            </>
                    }
                </div>
                <p>({percentage.toFixed(1)}%)</p>
                <p>Grade {grade}</p>
            </div>
        </div>
    );
}

function StudentFeedbackItemContent({ itemData, editKeysHierarchy }) {
    const { rubric } = useContext(RubricContext);
    const questionContent = (
        rubric && editKeysHierarchy && 
        getFromStructure(rubric, [...editKeysHierarchy, "question_content"])
    );
    const [feedback, setFeedback] = useState(itemData["feedback_content"]);

    return (
        <div className="feedback-content pt-2">
            {
                questionContent &&
                <StructureSection
                    heading={"Question Content"}
                    content={questionContent}
                    hide
                />
            }
            {
                feedback &&
                <StructureSection
                    content={feedback}
                    setContent={setFeedback}
                    heading={"Student Feedback"}
                    editKeys={[...editKeysHierarchy, "feedback_content"]}
                    aiGenerated
                >
                </StructureSection>
            }
        </div>
    );
}

export default function StudentFeedbackItem({ itemData, ...props }) {
    const { isFilterOnlyFlagged, isFilterOnlyMistakes } =
        useContext(ExamStructureContext).itemCommonProps;

    if (isFilterOnlyMistakes && !(itemData.total_marks < itemData.max_total_marks)) {
        return null;
    }
    if (isFilterOnlyFlagged && !itemData.flagged) {
        return null;
    }

    return (
        <StructureItem
            itemData={itemData}
            HeaderComponent={StudentFeedbackItemHeader}
            ContentComponent={StudentFeedbackItemContent}
            {...props}
        />
    );
}
