import { Tooltip } from "@mui/material";
import "./StudentFeedback.css";
import { CheckSquareSolid, FastArrowDown, FastArrowUp, QuestionMark, Square } from "iconoir-react";
import { useContext, useMemo, useState, useEffect } from "react";
import StudentFeedbackStructure from "src/components/content/exam/grading-structure/student-feedback/StudentFeedbackStructure";
import Divider from "src/components/layout/Divider";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";


export default function StudentQuestionFeedback() {
    const { selectedStudent } = useContext(StudentsContext);

    const [studentFeedback, setStudentFeedback] = useState(null);
    const [isSorted, setIsSorted] = useState(false);

    const [isFilterOnlyMistakes, setIsFilterOnlyMistakes] = useState(false);
    const [isFilterOnlyFlagged, setIsFilterOnlyFlagged] = useState(false);
    const [orderBy, setOrderBy] = useState("index");
    const [isAscendingOrder, setIsAscendingOrder] = useState(true);

    // Reset student feedback every time the selected student changes
    useEffect(() => {
        if (!selectedStudent?.id) return;
        setIsSorted(false);
        setStudentFeedback(null);
    }, [selectedStudent?.id]);

    useEffect(() => {
        if (isSorted || !selectedStudent) return;
        const newSf = [...selectedStudent["student_question_feedback"]];
        newSf.sort((feedback1, feedback2) => {
            var comp;
            if (orderBy === "percentage") {
                const p1 = feedback1["marks"] / feedback1["max_marks"];
                const p2 = feedback2["marks"] / feedback2["max_marks"];
                comp = p1 - p2;
            } else {
                comp = feedback1[orderBy] - feedback2[orderBy];
            }
            return isAscendingOrder ? comp : -comp;
        });
        setStudentFeedback(newSf);
        setIsSorted(true);
    }, [isAscendingOrder, orderBy, selectedStudent, isSorted, studentFeedback]);

    const hasFlagged = useMemo(() => {
        if (!studentFeedback) return;
        return studentFeedback.some((feedback) => feedback.flagged);
    }, [studentFeedback]);

    const hasMistakes = useMemo(() => {
        if (!studentFeedback) return;
        return studentFeedback.some((feedback) => feedback["marks"] < feedback["max_marks"]);
    }, [studentFeedback])

    return (
        <div className="feedback-container">
            <div className="flex flex-row justify-between items-center">
                <div>
                    <div className={
                        `feedback-mistakes-filter flex-row align-center margin-small ` + 
                        `${hasMistakes ? "" : "translucent"}`
                    }>
                        <div
                            className={`${hasMistakes ? "cursor-pointer" : "pointer-disabled"}`}
                            onClick={() => {
                                if (hasMistakes) {
                                    setIsSorted(false);
                                    setIsFilterOnlyMistakes((m) => !m);
                                }
                            }}
                        >
                            {
                                isFilterOnlyMistakes 
                                    ? <CheckSquareSolid color="var(--zanista-orange-dark)" /> 
                                    : <Square color="var(--zanista-orange-dark)" />
                            }
                        </div>
                        <p className="margin-small">Show only mistakes</p>
                    </div>
                    <div className={
                        `feedback-flagged-filter flex-row align-center margin-small ${hasFlagged ? "" : "translucent"}`
                    }>
                        <div
                            className={`${hasFlagged ? "cursor-pointer" : "pointer-disabled"}`}
                            onClick={() => {
                                if (hasFlagged) {
                                    setIsSorted(false);
                                    setIsFilterOnlyFlagged((f) => !f);
                                }
                            }}
                        >
                            {
                                isFilterOnlyFlagged 
                                    ? <CheckSquareSolid color="var(--zanista-orange-dark)" /> 
                                    : <Square color="var(--zanista-orange-dark)" />
                            }
                        </div>
                        <div className="margin-small flex-row">
                            <p className="margin-x-small">Show only feedback needing review</p>
                            <sup>
                                <Tooltip 
                                    title="
                                        AI-Generated feedback can sometimes be wrong. You can review it to ensure 
                                        its quality. If it is accurate, click on (NEEDS REVIEW) to remove the warning.
                                    "
                                    placement="right"
                                >
                                    <QuestionMark 
                                        color="var(--zanista-orange-dark)" className="cursor-pointer" width={20}
                                    />
                                </Tooltip>
                            </sup>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center">
                    <p>Order by:</p>
                    <select 
                        className="order-by-select padding-x-small font-size-large margin-small" 
                        value={orderBy}
                        onChange={(event) => {
                            setIsSorted(false);
                            setOrderBy(event.target.value);
                        }}
                    >
                        <option value="index">Question Number</option>
                        <option value="marks">Marks</option>
                        <option value="percentage">Percentage</option>
                    </select>
                    <div className="cursor-pointer" 
                        onClick={() => {
                            setIsSorted(false);
                            setIsAscendingOrder((ao) => !ao);
                        }}
                    >
                        {
                            isAscendingOrder 
                                ? <FastArrowUp color="var(--zanista-orange-dark)" /> 
                                : <FastArrowDown color="var(--zanista-orange-dark)" />
                        }
                    </div>
                </div>
            </div>
            <Divider />
            <StudentFeedbackStructure
                studentFeedback={studentFeedback}
                isFilterOnlyFlagged={isFilterOnlyFlagged}
                isFilterOnlyMistakes={isFilterOnlyMistakes}
            />
        </div>
    );
}