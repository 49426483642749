import { useCallback, useContext, useMemo } from "react";
import LoadingTab from "src/components/ui/LoadingTab";
import { ExamStructure } from "src/components/content/exam/ExamStructure";
import QuestionFeedbackItem from "src/components/content/exam/grading-structure/question-feedback/QuestionFeedbackItem";
import useSendEdit from "src/api/goodpoint/useSendEdit";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";


export default function QuestionFeedbackStructure({
    questionFeedback,
    gradeBoundaries = null,
    // setQuestionFeedback,
    isFilterOnlyMistakes = false,
}) {
    const { examObject } = useContext(ExamContext);
    const { rubricUrl } = useContext(RubricContext);
    const { sendEdit, isSending } = 
        useSendEdit(GoodPointApi.Grading.Edit.Class(examObject.id));
        
    const saveQuestionFeedbackEdit = useCallback((action, keys, value, onSuccess = undefined, onFail = undefined) => {
        sendEdit(action, ["question_feedback", ...keys], value, onSuccess, onFail)
    }, [sendEdit]);

    const numAnswers = useMemo(() => {
        return Object.keys(questionFeedback ?? {}).length;
    }, [questionFeedback]);

    function QuestionFeedbackHeader() {
        return <div className="flex-row gap-mid">
            <p>{numAnswers} Question{(numAnswers > 1) ? "s" : ""}</p>
        </div>
    }

    if (!questionFeedback) {
        return <LoadingTab />
    }

    return (
        <ExamStructure
            structure={questionFeedback}
            saveEdit={saveQuestionFeedbackEdit}
            isSavingEdit={isSending}
            HeaderComponent={QuestionFeedbackHeader}
            ItemComponent={QuestionFeedbackItem}
            initiallyExpanded={false}
            itemCommonProps={{ isFilterOnlyMistakes, gradeBoundaries }}
            listDisplay={[0, 0, 1, 1, 1, 0]}
            fileUrl={rubricUrl}
        />
    )
}