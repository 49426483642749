import { useCallback } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

export default function useSendTicker() {
    const callApi = useNewsWitchApi();

    // TODO: This should be sent as a JSON object in body for a POST request
    const sendTicker = useCallback((ticker, name = "", type = "", exchange = "", description = "", custom = false, linkedTicker = undefined, tags = []) => {
        return callApi("POST", "/ticker?" + new URLSearchParams({
            ticker: ticker,
            name: name,
            type: type,
            exchange: exchange,
            description: description,
            custom: custom.toString(),
            linked_ticker: linkedTicker !== undefined ? linkedTicker : "",
            tags: tags.join(",")
        }).toString())?.then(response => {
            if (response.status === 200) {
                return response.json().then(body => {
                    return {success: true, exists_error: false};
                });
            }
            // Ticker is already in database
            else if (response.status === 409) {
                return {success: false, exists_error: true};
            }
            // Other error
            else {
                console.error(response);
                return {success: false, exists_error: false};
            }
        });
    }, [callApi]);

    return sendTicker;
}
