import { createContext } from "react";

//EditToolbar
export const EditMode = {
    DEFAULT: "default",
    REORDER: "reorder",
    DELETE: "delete",
};

export const ExamContext = createContext({
    examObject: null,
    updateExamObject: (_a) => { },
    examState: null,
    updateExamState: (_s) => { },

    // set to true if this exam is being deleted
    isDeleting: false,
    setIsDeleting: (_d) => { },

    footerControls: null,
    setFooterControls: (_fc) => { },

    // EditToolbar
    editMode: EditMode.DEFAULT,
    setEditMode: (_e) => { },

    // delete checkboxes
    deleteSelected: null,
    setDeleteSelected: (_ds) => { },

    // pdf preview
    showPDF: null,
    setShowPDF: (_df) => { },
    // current Tab
    currentTab: null,
});
