import { useState } from 'react';
import useCallApi from 'src/api/useCallApi';
import BackLink from 'src/components/nav/BackLink';

// async function uploadBlogPost(formData) {
//     const url = "http://localhost:5000/blog/upload";  // Hardcoded URL for now

//     try {
//         const response = await fetch(url, {
//             method: 'POST',
//             body: formData,
//         });

//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         return data;  // Return response data
//     } catch (error) {
//         console.error("Error uploading blog post:", error);
//     }
// }


export default function BlogUploads() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [file, setFile] = useState(null);
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState("");
    const callApi = useCallApi();
    // const uploadEndpoint = "http://localhost:5000/blog/upload"
    const uploadEndpoint = "https://dev-api.zanista.ai/blog/upload";

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Create a FormData object to send the data as multipart/form-data
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("category", category);
        formData.append("tags", tags);
        formData.append("date", date);

        // Only append the file if one was selected
        if (file) {
            formData.append("file", file);
        }


        return callApi("POST", uploadEndpoint, {body: formData})
            ?.then(async response => {
                if (response.status === 200) {
                    alert("Blog post uploaded successfully!");
                    setTitle("");
                    setDescription("");
                    setCategory("");
                    setTags("");
                    setDate("");
                    setFile(null);
                } else {
                    console.error(
                        "failed to upload"
                    );
                }
            })
            .finally(() => { });
        // try {
        //     const response = await uploadBlogPost(formData);
        //     if (response) {
        //         alert("Blog post uploaded successfully!");
        //         setTitle("");
        //         setDescription("");
        //         setCategory("");
        //         setTags("");
        //         setDate("");
        //         setFile(null);
        //     } else {
        //         alert("Failed to upload blog post.");
        //     }
        // } catch (error) {
        //     console.error("Error during blog post upload:", error);
        // }
    };


    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    return (
        <div>
            <BackLink prevPage="Admin Portal" href="/admin" />

            <h2 className="pb-4 font-bold">Upload blog posts</h2>
            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'grid',
                    gridTemplateColumns: '300px 1fr',
                    gap: '16px',
                    alignItems: 'center',
                    maxWidth: '900px'
                }}
            >
                <label htmlFor="title">Title:</label>
                <input
                    id="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    className="border-2 border-slate-200"
                />

                <label htmlFor="description">Description:</label>
                <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    className="border-2 border-slate-200"
                />

                <label htmlFor="date">Date:</label>
                <input
                    id="date"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                    className="border-2 border-slate-200"
                />

                <label htmlFor="category">Category:</label>
                <select
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    required
                    className="border-2 border-slate-200"
                >
                    <option value="" disabled>Select a category</option>
                    <option value="AI">AI</option>
                    <option value="Finance">Finance</option>
                </select>

                <label htmlFor="tags">Tags: (separate by comma)</label>
                <textarea
                    id="tags"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                    className="border-2 border-slate-200"
                />

                <label htmlFor="file">Upload PDF:</label>
                <input
                    id="file"
                    type="file"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    required
                />

                <div></div> {/* Empty div to skip label space */}
                <button
                    className="bg-orange-300 rounded-lg hover:bg-orange-400 active:bg-red-600"
                    type="submit">Submit
                </button>
            </form>
        </div>
    );
}
