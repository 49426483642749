import './InfoTab.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useCallback, useContext, useLayoutEffect } from 'react';
import { ExamContext } from 'src/pages/goodpoint/exam/ExamContext';
import useSendInfo from 'src/api/goodpoint/useSendInfo';
import { createTheme, ThemeProvider } from '@mui/material';
import { useHasRole } from 'src/api/useGetRole';


const PLACEHOLDERS = {
    institute: "(institute)",
    department: "(department)",
    course_title: "(course title)",
    course_level: "(course level)",
    subject: "(subject)",
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#F49569",
            dark: "#EF753D"
        },
        secondary: {
            main: "#F49569"
        }
    },
});

function FormItem({ label, dontCenterLabel = false, children }) {
    return (
        <div className={`form-item ${!dontCenterLabel ? "align-center" : ""}`}>
            <p>{label}</p>
            <div>
                {children}
            </div>
        </div>
    )
}

function InfoTab() {
    const { examObject, updateExamObject, footerControls, setFooterControls } = useContext(ExamContext);
    const sendInfo = useSendInfo(examObject.id);

    useLayoutEffect(() => {
        if (!footerControls) return;
        setFooterControls(null);
    }, [setFooterControls, footerControls]);

    const updateInfo = useCallback((updates) => {
        sendInfo(updates);
        updateExamObject({ info: { ...examObject["info"], ...updates } });
    }, [examObject, sendInfo, updateExamObject]);

    function InfoInput({ type = "text", infoKey, valueFunc = null, children = null }) {
        const disabled = useHasRole("student");
        const props = {};
        const currentValue = examObject["info"][infoKey];
        const isPlaceholder = currentValue === PLACEHOLDERS[infoKey];
        if (isPlaceholder) {
            props["placeholder"] = currentValue;
        } else {
            props["defaultValue"] = currentValue;
        }

        var Control = "input";
        if (type === "select") {
            Control = "select";
            props["children"] = children;
        }

        return (
            <Control className={`form-item-content`}
                type={type}
                disabled={disabled}
                onBlur={(e) => {
                    var value = e.target.value;
                    if (!value) {
                        value = PLACEHOLDERS[infoKey];
                        e.target.value = value;
                    }
                    updateInfo({
                        [infoKey]: valueFunc ? valueFunc(e.target.value) : e.target.value
                    });
                }}
                {...props}
            />
        )
    }

    const disabled = useHasRole("student");

    return (
        <div className='exam-info'>
            <div className="flex flex-row justify-between items-center">
                <h2 className='text-xl font-bold'>Info</h2>
                <p>Configure the info for this exam</p>
            </div>
            <form onSubmit={(event) => event.preventDefault()}>
                <FormItem label="Institute">
                    <InfoInput infoKey={"institute"} />
                </FormItem>
                <FormItem label="Department">
                    <InfoInput infoKey={"department"} />
                </FormItem>
                <FormItem label="Course Title">
                    <InfoInput infoKey={"course_title"} />
                </FormItem>
                <FormItem label="Course Level">
                    <InfoInput infoKey={"course_level"} />
                </FormItem>
                <FormItem label="Subject">
                    <InfoInput infoKey={"subject"} />
                </FormItem>
                <FormItem label="Date">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <ThemeProvider theme={theme}>
                            <DatePicker
                                format='DD MMM YYYY'
                                disabled={disabled}
                                defaultValue={
                                    (examObject["info"]["date"] === null)
                                        ? dayjs()
                                        : dayjs(examObject["info"]["date"]).set('hour', 12)
                                }
                                onChange={(event) => updateInfo({ date: event.toDate() })}
                            />
                        </ThemeProvider>
                    </LocalizationProvider>
                </FormItem>
            </form>
        </div>
    );
}

export default InfoTab;
