import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "src/index.css";
import "src/styles/Main.css";
import "src/styles/Visual.css";
import "src/styles/Colour.css";
import "src/styles/Layout.css";
import "src/styles/Button.css";
import "src/styles/Font.css";

import ZanistaRouter from "./Router";

import MsalInstance from "src/auth/Msal";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
            <RouterProvider router={ZanistaRouter({ msal: MsalInstance })} />
        </DndProvider>
    </React.StrictMode>
);
