import { RefreshDouble } from "iconoir-react";
import { useContext, useState } from "react";
import AreYouSureModal from "src/components/ui/AreYouSureModal";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import useResetTab from "src/pages/goodpoint/exam/tabs/useResetTab";


export default function ResetTabButton({ tab }) {
    const { examObject } = useContext(ExamContext);
    const { resetTab } = useResetTab(examObject.id, tab);
    const [popupOpen, setPopupOpen] = useState(false);

    return <>
        <button 
            onClick={() => setPopupOpen(true)}
            className={
                `btn-clickable rounded-md flex flex-row gap-2 p-2 px-3 bg-zanista-red-light hover:bg-zanista-red-mid ` +
                `items-center ml-auto `
            }
        >
            <RefreshDouble width={"1.0em"} strokeWidth={2.0}/>
            <p>Reset {tab}?</p>
        </button>
        <AreYouSureModal
            {...{popupOpen, setPopupOpen}}
            onClosePopup={() => setPopupOpen(false)}
            title={`Reset ${tab}`}
            onConfirm={
                () => resetTab(() => setPopupOpen(false))
            }
            danger={true}
            confirmContent={`Reset ${tab}`}
            ConfirmIcon={() => <RefreshDouble width="1.0em" strokeWidth={2.0} className="mx-1"/>}
        >
            {`Are you sure you want to reset the ${tab} of ${examObject?.name ?? "this exam"}?`}
        </AreYouSureModal>
    </>
}