import "./ExamStructure.css";
import ExpandCollapseButtons from "src/components/layout/expand-collapse/ExpandCollapseButtons";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import { ExamStructureContext } from "src/components/content/exam/ExamStructureContext";
import { useVisibility, Visibility, VisibilityContext } from "src/components/content/exam/Visibility";
import { Xmark } from "iconoir-react";
import { useContext, useMemo, useRef, useState } from "react";
import { ExamContext, EditMode } from "src/pages/goodpoint/exam/ExamContext";
import PdfViewer from "src/components/content/PdfViewer";
import EditToolbar from "src/components/ui/EditToolbar";


export function ExamStructure({
    structure,
    saveEdit = undefined,
    isSavingEdit = undefined,
    HeaderComponent,
    ItemComponent,
    itemCommonProps = null,
    initiallyExpanded = false,
    fileUrl = "",
    listDisplay = [0, 0, 0, 0, 0, 0],
    disableExpandCollapse = false,
    disableEditToolbar = false
}) {
    const { showPDF, editMode } = useContext(ExamContext);

    if (disableExpandCollapse) {
        initiallyExpanded = true;
    }
    const expandCollapse = useExpandCollapse(initiallyExpanded);

    const visibilityContextValue = useVisibility(structure);
    const { clear, visibilityMap } = visibilityContextValue;
    const countHiddenQuestions = Object.values(visibilityMap).filter(
        (q) => q.visibility in [Visibility.HIDDEN, Visibility.PARTIALLY_HIDDEN]
    ).length;

    const searchVisibility = useVisibility(structure);
    const { clear: clearSearchVisibility } = searchVisibility;
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const [searchTerms, setSearchTerms] = useState([]);
    const clearSearchBar = useRef(() => { });

    const structureItems = 
        structure
            .map((itemData, i) => (
                <ItemComponent
                    key={i}
                    itemData={itemData}
                    outerKeys={[]}
                    {...(itemCommonProps ?? {})}
                />
            ));

    function onChangeSearchText(text) {
        const newSearchTerms = text
            .split(",")
            .map((s) => s.trim())
            .filter((s) => s.length > 0);
        setSearchTerms(newSearchTerms);
    }

    function clearSearchTerms() {
        clearSearchVisibility();
        setSearchTerms([]);
        setIsSelectionMode(false);
    }

    // @ts-ignore
    const pdfViewer = useMemo(() => <PdfViewer url={fileUrl} title="rubric" height="1200px" />, [fileUrl]);

    return (
        <ExpandCollapseContext.Provider value={expandCollapse}>
            <ExamStructureContext.Provider
                value={{
                    structure,
                    ItemComponent,
                    isSelectionMode,
                    itemCommonProps: itemCommonProps ?? {},
                    searchTerms,
                    clearSearch: () => clearSearchBar.current(),
                    searchVisibility,
                    saveEdit,
                    isSavingEdit
                }}
            >
                <VisibilityContext.Provider value={visibilityContextValue}>
                    <div className="exam-structure">
                        <div className="flex-row gap-large justify-between mb-4">
                            <HeaderComponent structure={structure} />
                            {!disableExpandCollapse && <ExpandCollapseButtons />}
                        </div>
                        <div className="structure-content flex-col gap-mid">
                            {countHiddenQuestions > 0 && !isSelectionMode && (
                                <div
                                    className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer"
                                    onClick={() => clear()}
                                >
                                    <p>{`${countHiddenQuestions} hidden question${countHiddenQuestions > 1 ? "s" : ""
                                        } (Clear)`}</p>
                                    <Xmark strokeWidth={1.0} />
                                </div>
                            )}
                            {
                                !disableEditToolbar &&
                                    <EditToolbar
                                        isSelectionMode={isSelectionMode}
                                        setIsSelectionMode={setIsSelectionMode}
                                        clearSearchBar={clearSearchBar}
                                        onChangeSearchText={onChangeSearchText}
                                        clearSearchTerms={clearSearchTerms}
                                        listDisplay={listDisplay}
                                    />
                            }
                            <div className="flex flex-row gap-4">
                                <div className={
                                    `${showPDF && "order-2"} ${showPDF ? "w-1/2" : "w-full"} ` + 
                                    `structure-content flex flex-col gap-4`
                                }>
                                    {editMode !== EditMode.REORDER && structureItems}
                                    {/* {editMode === EditMode.REORDER && structureSortables()} */}
                                </div>
                                {showPDF && fileUrl && (
                                    <div className={`${"order-1"} w-1/2`}>
                                        {pdfViewer}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </VisibilityContext.Provider>
            </ExamStructureContext.Provider>
        </ExpandCollapseContext.Provider>
    );
}
