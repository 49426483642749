import { useCallback, useContext, useEffect, useState } from "react";
import Latex from "react-latex-next";
import ExpandCollapseButtons from "src/components/layout/expand-collapse/ExpandCollapseButtons";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import RichMathEditor from "src/components/ui/RichMathEditor";
import { tokenizeText } from "src/components/content/exam/StructureUtil";
import { Tooltip } from "@mui/material";
import StructureSection from "src/components/content/exam/StructureSection";
import RenderMath from "src/components/ui/RenderMath";
import { useHasRole } from "src/api/useGetRole";
import { 
    BadgeCheck, InfoCircle, WarningTriangle, PageStar, EditPencil, NavArrowDown, NavArrowRight 
} from "iconoir-react";
import { ExamStructureContext } from "src/components/content/exam/ExamStructureContext";
import { EditAction } from "src/api/goodpoint/Const";
import HoverIconButton from "src/components/ui/HoverIconButton";


const StepTabText = {
    SOLUTION: "Solution",
    EXPECTATION: "Expectation",
    COMMON_ERRORS: "Common Errors",
    MARKING: "Marking",
};

const StepTabIcon = {
    SOLUTION: BadgeCheck,
    EXPECTATION: InfoCircle,
    COMMON_ERRORS: WarningTriangle,
    MARKING: PageStar,
};

const EditableContent = ({ label, content, setContent, editKeys }) => {
    const { saveEdit, isSavingEdit } = useContext(ExamStructureContext);
    const readOnly = useHasRole("student");
    const [isEdit, setIsEdit] = useState(false);

    const onClickSave = useCallback((newContent) => {
        saveEdit && saveEdit(EditAction.MODIFY, editKeys, newContent, () => {
            setContent && setContent(newContent);
            setIsEdit(false);
        });
    }, [editKeys, saveEdit, setContent]);

    return (
        <div className="w-full">
            <div className="flex flex-row w-full justify-between items-center">
                <h2 className="text-lg"><b>{label}: </b></h2>
                {
                    !readOnly && 
                    <HoverIconButton
                        tooltip="Edit content"
                        tooltipPlacement="right"
                        Icon={EditPencil}
                        onClick={() => setIsEdit(e => !e)}
                        disabled={isEdit}
                    />
                }
            </div>
            {
                isEdit &&
                <RichMathEditor
                    initialTokens={tokenizeText(content)}
                    saveContent={onClickSave}
                    closeEditor={() => setIsEdit(false)}
                    isSaving={isSavingEdit}
                />
            }
            {
                !isEdit &&
                <RenderMath tokens={tokenizeText(content)} />
            }
        </div>
    );
};

function StepTabs({ stepTab, setStepTab }) {
    return (
        <div className="step-tabs flex-col gap-mid">
            {Object.entries(StepTabText).map(([k, v], i) => {
                const selected = stepTab === v;
                const Icon = StepTabIcon[k];
                return (
                    <button
                        onClick={() => setStepTab(v)}
                        className={
                            `btn-clickable flex flex-row gap-1 items-center w-full rounded-md text-nowrap p-1 pr-2 ` + 
                            `${selected ? "bg-zanista-orange-mid" : "bg-zanista-yellow-light"} ` + 
                            `scale-105 duration-100 text-sm`
                        }
                    >
                        <Icon color={selected ? "black" : "var(--zanista-orange-dark)"} width="1.25em" className="margin-0"/>
                        {v}
                    </button>
                );
            })}
        </div>
    );
}

function Step({ index, step, editKeysHierarchy }) {
    const editKeys = [...editKeysHierarchy, index];

    const {
        isExpanded: isAllExpanded,
        isCollapsed: isAllCollapsed,
        reset: resetAll,
    } = useContext(ExpandCollapseContext);

    const { isExpanded, expand, collapse, toggle } = useExpandCollapse();

    useEffect(() => {
        isAllExpanded && expand();
    }, [isAllExpanded, expand]);
    useEffect(() => {
        isAllCollapsed && collapse();
    }, [isAllCollapsed, collapse]);

    const [stepTab, setStepTab] = useState(StepTabText.SOLUTION);

    const [solution, setSolution] = useState(step.solution ?? "[missing]");
    const [expandedSolution, setExpandedSolution] = useState(step.expanded_solution ?? "[missing]");
    const [expected, setExpected] = useState(step.expected ?? "[missing]");
    const [commonErrors, setCommonErrors] = useState(step.common_mistakes?.[0]?.[0] ?? "[missing]");
    const [stepMark, setStepMark] = useState(step.marks);
    const [, setStepMarkDeductions] = useState(step.mark_deduction);
    
    const [warningOpen, setWarningOpen] = useState(false);

    const handleMarkDeductions = (event) => {
        const newMarkDeductions = parseFloat(event.target.value) || 0;
        if (newMarkDeductions > stepMark) {
            setStepMarkDeductions(stepMark);
            setWarningOpen(true);
        } else {
            setStepMarkDeductions(newMarkDeductions);
            setWarningOpen(false);
        }
    };

    var stepContent = null;
    switch (stepTab) {
        case StepTabText.SOLUTION:
            stepContent = (
                <div>
                    <EditableContent
                        label="Solution"
                        content={solution}
                        setContent={setSolution}
                        editKeys={[...editKeys, "solution"]}
                    />
                </div>
            );
            break;

        case StepTabText.EXPECTATION:
            stepContent = (
                <div>
                    <EditableContent
                        label="Expanded Solution"
                        content={expandedSolution}
                        setContent={setExpandedSolution}
                        editKeys={[...editKeys, "expanded_solution"]}
                    />

                    <p></p>
                    <br></br>
                    <EditableContent
                        label="Student Expectation"
                        content={expected}
                        setContent={setExpected}
                        editKeys={[...editKeys, "expected"]}
                    />
                </div>
            );
            break;

        case StepTabText.COMMON_ERRORS:
            stepContent = (
                <div>
                    <EditableContent
                        label="Common Errors"
                        content={commonErrors}
                        setContent={setCommonErrors}
                        editKeys={[...editKeys, "common_mistakes", 0, 0]}
                    />
                    <p></p>
                    <br></br>
                    <b>Examples: </b>
                    <ul>
                        {" "}
                        {
                            []
                                .concat(...step.common_mistakes.map((cm) => cm[1]))
                                .map((v, i) => {
                                    return (
                                        <li key={i}>
                                            <RenderMath tokens={tokenizeText(v)} />
                                        </li>
                                    );
                                })
                        }{" "}
                    </ul>
                </div>
            );
            break;
        case StepTabText.MARKING:
            stepContent = (
                <div>
                    <b>Marks for this step: </b>
                    <p className="text-zanista-orange-dark">
                        <input
                            type="number"
                            key={`${index}`} //forces rerender
                            onBlur={(event) =>
                                setStepMark(parseFloat(event.target.value) || 0)
                            }
                            defaultValue={step.marks}
                            className="w-12 text-right focus:outline-accent"
                        ></input>
                        Marks
                    </p>
                    <p></p>
                    <br></br>
                    <b>Mark deductions for errors: </b>
                    <div className="text-zanista-orange-dark">
                        <input
                            type="number"
                            key={`${index}`} //forces rerender
                            onBlur={handleMarkDeductions}
                            defaultValue={step.mark_deduction}
                            className="w-12 text-right focus:outline-accent"
                        ></input>
                        Marks

                        <Tooltip
                            open={warningOpen}
                            onOpen={() => setWarningOpen(true)}
                            onClose={() => setWarningOpen(false)}
                            title="Mark deduction cannot be more than step mark!"
                        >
                            <div></div>
                        </Tooltip>
                    </div>
                </div>
            );
            break;
        default:
            break;
    }

    return (
        <div className="space-y-1">
            <div
                className="flex flex-row gap-2 justify-between w-full cursor-pointer items-center"
                onClick={() => {
                    toggle();
                    resetAll();
                }}
            >
                <div className="flex flex-row gap-1">
                    {
                        isExpanded ? <NavArrowDown width="1.2em"/> : <NavArrowRight width="1.2em"/>
                    }
                    <p className="text-black text-nowrap mr-1">
                        Step {index + 1}
                    </p>
                    <div className="step-heading-text fg-orange-dark">
                        <Latex>{step.step}</Latex>
                    </div>
                </div>
                <div className="flex-grow mx-1 border-t border-dotted border-zanista-orange-dark"></div>
                <p className="text-black text-nowrap">
                    {step.marks} Marks
                </p>
            </div>
            {
                isExpanded &&
                <div className="step-body flex flex-row gap-4 pl-5 pb-2 pt-1">
                    <StepTabs stepTab={stepTab} setStepTab={setStepTab} />
                    <div className="step-content font-size-medium">{stepContent}</div>
                </div>
            }
        </div>
    );
}

export default function RubricSteps({ steps, editKeysHierarchy = [] }) {
    const editKeys = [...editKeysHierarchy, "steps"];
    const expandCollapse = useExpandCollapse(false);

    if (!steps) {
        return null;
    }

    return (
        <ExpandCollapseContext.Provider value={expandCollapse}>
            <StructureSection
                includeDivider={false}
                heading={"Step-by-Step"}
                headingControls={
                    <ExpandCollapseButtons
                        props={{
                            iconSize: 17,
                            borderSize: "var(--border-thin)",
                            paddingSize: "0px 5px",
                            roundedSize: "var(--rounded-xsmall)",
                        }}
                    />
                }
                aiGenerated
            >
                {
                    <div className="my-2 steps-list space-y-2 pr-3 pl-1">
                        {
                            steps.map((s, i) => (
                                <Step
                                    key={i}
                                    step={s}
                                    index={i}
                                    editKeysHierarchy={editKeys}
                                />
                            ))
                        }
                    </div>
                }
            </StructureSection>
        </ExpandCollapseContext.Provider>
    );
}
