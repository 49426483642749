import { createContext } from "react";
import { StructureItem } from "src/components/content/exam/StructureItem";

export const ExamStructureContext = createContext({
    structure: {},
    ItemComponent: StructureItem,
    isSelectionMode: false,
    itemCommonProps: {},
    searchTerms: [],
    clearSearch: () => {},
    searchVisibility: {},
    saveEdit: (_keys, _content, _onSuccess = undefined, _onFail = undefined) => {},
    isSavingEdit: false
})