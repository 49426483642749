import 'src/components/nav/ZanistaHeader.css'
import logo from 'src/assets/logo.png'
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
// import { ContactPopup, Subject } from 'src/pages/home/components/ContactPopup';
import { Menu, NavArrowRight } from 'iconoir-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest, silentRequest } from 'src/auth/AuthConfig';
import { useEffect } from 'react';

const HomeLinks = [
    { "Home": "/" },
    { "Products": "/products" },
    { "About Us": "/about" },
    // {"Blog": "/blog"},
    { "PaperPal": "/paperpal" },
    { "Pricing": "/pricing" }
]

export default function HomeHeader() {
    // Try to login silently
    const { instance } = useMsal();
    useEffect(() => {
        if (instance.getActiveAccount() !== null) {
            instance.acquireTokenSilent({
                ...silentRequest,
                account: instance.getActiveAccount()
            }).then((response) => {
                console.log("Silent login success", response);
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    console.log("Silent login failed, user interaction required.");
                } else {
                    console.error("Silent login failed", error);
                }
            });
        }
    }, [instance]);

    const handleLogin = () => {
        instance.loginRedirect({
            ...loginRequest,
            redirectStartPage: "/dashboard"
        }).catch(e => {
            console.error("Login with redirect failed", e);
        });
    };

    return (
        <div className="centered static">
            <header className="zanista-header rounded-b-xl" id="zanista-header">
                <div className="container flex-row justify-start align-center">
                    <Link to="/">
                        <img src={logo} alt="Zanista AI" className="object-contain" width="75px" />
                    </Link>
                    <nav className="home-nav pl-3">
                        {/* desktop nav */}
                        <ul className='hidden sm:inline list-none'>
                            {
                                HomeLinks.map((link, i) => {
                                    const key = Object.keys(link)[0];
                                    const value = Object.values(link)[0];
                                    return key === "Products" ? (
                                        <li key={i}>
                                            <Popup position="bottom center" on={['hover', 'focus']}
                                                trigger={
                                                    <span className="mx-1 hover:text-orange-600 cursor-pointer font-bold afacad-flux-normal text-sm transition-all ease-in duration-200 ">
                                                        {key}
                                                    </span>
                                                }
                                            >
                                                {
                                                // @ts-ignore
                                                close => (
                                                    <div className='bg-white shadow-xl outline outline-1 outline-gray-200 rounded p-2 mt-1 w-[90vw] mx-auto md:max-w-[50vw] md:w-[1000px] absolute top-4 -left-[150px] md:-left-20'>
                                                        <hr />
                                                        <h2 className="my-2 text-md font-bold px-2 afacad-flux-normal">
                                                            EdTech
                                                        </h2>
                                                        <hr />
                                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 py-2">
                                                            <ProductItem
                                                                title="GoodPoint"
                                                                desccription="A fully automated grading system with human-level precision and providing detailed feedback"
                                                                href="/products/goodpoint" close={close}
                                                            />
                                                            <ProductItem
                                                                title="AI Exam Master"
                                                                desccription="Generates custom problem sets with solutions for effective learning"
                                                                href="/products/exam-master" close={close}
                                                            />
                                                            <ProductItem
                                                                title="PaperPal"
                                                                desccription="Assists in streamlining literature reviews and summarizing academic papers for dissertations and scholars"
                                                                href="/products/paperpal" close={close}
                                                            />
                                                        </div>
                                                        <hr />
                                                        <h2 className="my-2 text-md font-bold px-2 afacad-flux-normal">
                                                            Finance
                                                        </h2>
                                                        <hr />
                                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 py-2">
                                                            <ProductItem
                                                                title="NewsWitch"
                                                                desccription="Produce detailed reports with news summaries for any list of tickers and time frames"
                                                                href="/products/newswitch" close={close}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                        </li>
                                    )
                                        :
                                        (
                                            <li key={i}>
                                                <Link to={value} className="text-sm mx-1  hover:text-orange-600 font-bold afacad-flux-normal transition-all ease-in duration-200 ">
                                                    {key}
                                                </Link>
                                            </li>
                                        );
                                })
                            }
                        </ul>
                        <ul>
                            <li>
                                <button
                                    onClick={() => window.open("https://calendly.com/arman-khaledian-zanista/30min?month=2025-01", "_blank")}
                                    className="btn-clickable px-3 pr-1 text-sm bg-secondary hover:bg-accent text-black font-semibold shadow-xl rounded-3xl afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center"
                                >
                                    Request a demo
                                    <NavArrowRight className="inline-block" />
                                </button>
                            </li>

                            <li>
                                <UnauthenticatedTemplate>
                                    <div
                                        className="text-xs mx-1 hover:text-orange-600 hidden sm:inline cursor-pointer"
                                        onClick={() => {
                                            // Login redirect if user has not been logged in silently
                                            handleLogin();
                                        }}
                                    >
                                        Sign In
                                    </div>
                                </UnauthenticatedTemplate>
                                <AuthenticatedTemplate>
                                    <Link
                                        className="text-sm mx-1 hover:text-orange-600 hidden sm:inline cursor-pointer afacad-flux-normal font-semibold transition-all ease-in duration-200 "
                                        to="/dashboard"
                                    >
                                        Dashboard
                                    </Link>
                                </AuthenticatedTemplate>
                            </li>
                        </ul>
                        {/* mobile nav */}
                        <Popup position="bottom right"
                            trigger={
                                <Menu size={24} className="sm:hidden cursor-pointer" />
                            }
                            nested
                        >
                            {
                            // @ts-ignore
                            closeMenu => (
                                <div className='popup-animation-fast bg-white shadow-xl w-screen absolute top-3 -left-[calc(100vw-20px)] pt-1 pb-3 px-3 rounded-b border-b font-bold afacad-flux-normal transition-all ease-in duration-200 '>
                                    <ul className='flex-col gap-2 list-none font-bold afacad-flux-normal transition-all ease-in duration-200 '>
                                        {
                                            HomeLinks.map((link, i) => {
                                                const key = Object.keys(link)[0];
                                                const value = Object.values(link)[0];
                                                return key !== "Products" ?
                                                    (
                                                        <li key={i} className='border-t focus:outline-none font-bold afacad-flux-normal transition-all ease-in duration-200 '>
                                                            <Link to={value} onClick={closeMenu} className="text-xs mx-1 hover:text-orange-600 focus:outline-none font-bold afacad-flux-normal transition duration-200 ">
                                                                {key}
                                                            </Link>
                                                        </li>
                                                    )
                                                    :
                                                    (
                                                        <li key={i} className='border-t'>
                                                            <Popup modal nested
                                                                trigger={
                                                                    <span to={value} onClick={closeMenu} className="text-xs mx-1 focus:outline-none cursor-pointer hover:text-orange-600 afacad-flux-normal">
                                                                        {key}
                                                                    </span>
                                                                }
                                                            >
                                                                {
                                                                // @ts-ignore
                                                                close => (
                                                                    <div className='popup-animation-fast bg-white shadow-xl outline outline-1 outline-gray-200 rounded p-2 mt-1 w-[90vw] mx-auto'>
                                                                        <hr />
                                                                        <h2 className="my-2 text-md font-bold px-2">
                                                                            EdTech
                                                                        </h2>
                                                                        <hr />
                                                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 py-2">
                                                                            <ProductItem
                                                                                title="GoodPoint"
                                                                                desccription="A fully automated grading system with human-level precision and providing detailed feedback"
                                                                                href="/products/goodpoint" close={() => { close(); closeMenu(); }}
                                                                            />
                                                                            <ProductItem
                                                                                title="AI Exam Master"
                                                                                desccription="Generates custom problem sets with solutions for effective learning"
                                                                                href="/products/exam-master" close={() => { close(); closeMenu(); }}
                                                                            />
                                                                            <ProductItem
                                                                                title="PaperPal"
                                                                                desccription="Assists in streamlining literature reviews and summarizing academic papers for dissertations and scholars"
                                                                                href="/products/paperpal" close={() => { close(); closeMenu(); }}
                                                                            />
                                                                        </div>
                                                                        <hr />
                                                                        <h2 className="my-2 text-md font-bold px-2">
                                                                            Finance
                                                                        </h2>
                                                                        <hr />
                                                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 py-2">
                                                                            <ProductItem
                                                                                title="NewsWitch"
                                                                                desccription="Produce detailed reports with news summaries for any list of tickers and time frames"
                                                                                href="/products/newswitch" close={() => { close(); closeMenu(); }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Popup>
                                                        </li>
                                                    )
                                            })
                                        }
                                        <li className='border-t'>
                                            <UnauthenticatedTemplate>
                                                <div
                                                    className="text-xs font-bold mx-1 hover:text-orange-600"
                                                    onClick={() => {
                                                        // Login redirect if user has not been logged in silently
                                                        handleLogin();
                                                    }}
                                                >
                                                    Sign In
                                                </div>
                                            </UnauthenticatedTemplate>
                                            <AuthenticatedTemplate>
                                                <Link to={"/dashboard"} className="text-xs font-bold mx-1 hover:text-orange-600 afacad-flux-normal transition-all ease-in duration-200 ">
                                                    Dashboard
                                                </Link>
                                            </AuthenticatedTemplate>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </Popup>
                    </nav>
                </div>
            </header>
        </div>
    );
}

function ProductItem({ title, desccription, href, close }) {
    return (
        <Link className="flex-col cursor-pointer p-2 hover:text-orange-600 focus:outline-none rounded afacad-flux-normal"
            to={href} onClick={() => close()}
        >
            <h3 className="font-bold text-md pb-1 afacad-flux-normal">
                {title}
            </h3>
            <p className="text-xs afacad-flux-normal">
                {desccription}
            </p>
        </Link>
    )
}