import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { StudentsSelect } from "src/pages/goodpoint/exam/components/StudentsSelect";
import useFetchStudentAnswers from "src/api/goodpoint/useFetchStudentAnswers";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import Divider from "src/components/layout/Divider";
import LoadingTab from "src/components/ui/LoadingTab";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";
import useCallApi from "src/api/useCallApi";
import ResetTabButton from "src/pages/goodpoint/exam/components/ResetTabButton";
import { Tab } from "src/api/goodpoint/Const";
import { Archive, EmptyPage, GraduationCap, Star } from "iconoir-react";
import AnswersStructure from "src/components/content/exam/answers-structure/AnswersStructure";
import useFetchRubricQuestions from "src/api/goodpoint/useFetchRubric";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";
import { DownloadsMenuButton } from "src/pages/goodpoint/exam/components/DownloadsMenu";
import { standardSlugify } from "src/util/StringUtil";


export default function AnswersCompleted() {
    const { examObject, setFooterControls } = useContext(ExamContext);
    const callApi = useCallApi();
    const [students, setStudents] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [answerPdfUrl, setAnswerPdfUrl] = useState(null);
    const [rubric, setRubric] = useState(null);
    
    const fetchStudentAnswers = useFetchStudentAnswers(examObject.id);
    const fetchRubric = useFetchRubricQuestions(examObject.id);

    useLayoutEffect(() => {
        setFooterControls([<AnswersDownloads selectedStudent={selectedStudent} students={students} />]);
    }, [examObject.id, selectedStudent, setFooterControls, students]);

    useEffect(() => {
        if (students) return;

        let answers = fetchStudentAnswers();
        if (answers) {
            setSelectedStudent(answers[Object.keys(answers)[0]]);
            setStudents(answers);
        }
    }, [fetchStudentAnswers, setSelectedStudent, setStudents, students]);
    
    useEffect(() => {
        if (!selectedStudent) return;
        const sourceFile = selectedStudent["config"]["source_file"];
        callApi("GET", GoodPointApi.Answers.Original.Link(examObject.id, sourceFile))
            ?.then(response => {
                if (response.status === 200) {
                    response.json().then(body => {
                        var url = body["data"]["answer_url"];
                        setAnswerPdfUrl(url);
                    })
                }
            })
    }, [callApi, examObject.id, selectedStudent]);

    useEffect(() => {
        if (rubric) return;
        let fetchedRubric = fetchRubric();
        if (fetchedRubric) {
            if (fetchedRubric["rubric"]) {
                const newRubric = fetchedRubric["rubric"].sort((q1, q2) => q1["index"] - q2["index"]);
                setRubric(newRubric);
            }
        }
    }, [fetchRubric, rubric]);

    return (
        <div className="space-y-4">
            <StudentsContext.Provider 
                value={{students, setStudents, selectedStudent, setSelectedStudent, answerPdfUrl}}
            >
                <StudentsSelect
                    extraCells={[
                        (studentData) => {
                            const questionsAnswered = Object.keys(studentData["answered_questions"]).length;
                            return <div className="text-right">
                                <p>{questionsAnswered} question{questionsAnswered > 1 ? "s" : ""}{" "} answered</p>
                            </div>
                        }
                    ]}
                />
                <Divider />
                <RubricContext.Provider
                    value={{
                        rubric, setRubric, rubricUrl: null, 
                        setRubricUrl: () => {}, editMode: null, setEditMode: () => {}, isEnhanced: false
                    }}
                >
                    <StudentAnswers />
                </RubricContext.Provider>
                <ResetTabButton tab={Tab.ANSWERS}/>
            </StudentsContext.Provider>
        </div>
    );
}

function StudentAnswers() {
    const { selectedStudent } = useContext(StudentsContext);

    if (!selectedStudent) {
        return <LoadingTab />;
    }

    var answers = Object.values(selectedStudent["answered_questions"]).sort(
        (answer1, answer2) => {
            return answer1.question_number - answer2.question_number;
        }
    );

    return (
        <div className="student-answers resize-y space-y-4">
            <div className="answers-container">
                {answers != null ? (
                    <AnswersStructure
                        selectedStudent={selectedStudent}
                        structureMap={answers}
                    />
                ) : (
                    <LoadingTab />
                )}
            </div>
        </div>
    );
}

function AnswersDownloads({ selectedStudent, students }) {
    const { examObject } = useContext(ExamContext);
    const examName = examObject.name ?? "this exam";
    
    if (!students) return null;

    return (
        <DownloadsMenuButton
            text="Student Answer Downloads"
            downloadsIcon={GraduationCap}
            rowData={[
                {
                    Icon: EmptyPage,
                    text: "Original answers",
                    endpointFunc:
                        // this is a hack, need to create a proper Original Download endpoint later
                        (filename) => GoodPointApi.Answers.Original.View(examObject.id, filename),
                    filenameFunc: (filename) => filename,
                    enabledClassName: "bg-zanista-yellow-light",
                    selectOptions:
                        students && Object.values(students).map((s) => s["config"]["source_file"]),
                    defaultSelection: selectedStudent && selectedStudent["config"]["source_file"]
                },
                {
                    Icon: GraduationCap,
                    text: "Processed answers",
                    endpointFunc:
                        (studentId) => 
                            GoodPointApi.Answers.Student.Download(examObject.id, "processed", studentId),
                    filenameFunc: 
                        (studentId) => 
                            `${standardSlugify(students[studentId]["config"]["student_name"])}_answer_processed.pdf`,
                    enabledClassName: "bg-zanista-orange-light",
                    selectOptions:
                        students && Object.values(students).map((s) => [s.id, s["config"]["student_name"]]),
                    defaultSelection: selectedStudent && selectedStudent.id
                },
                {
                    Icon: (props) => {
                        return <div className="relative">
                            <GraduationCap {...props}/>
                            <div className="absolute bottom-0 right-0">
                                <Star width={13} height={13} fill="white" className="stroke-[2]"/>
                            </div>
                        </div>
                    },
                    text: "Enhanced answers",
                    endpointFunc:
                        (studentId) => 
                            GoodPointApi.Answers.Student.Download(examObject.id, "enhanced", studentId),
                    filenameFunc:
                        (studentId) => 
                            `${standardSlugify(students[studentId]["config"]["student_name"])}_answer_enhanced.pdf`,
                    enabledClassName: "bg-zanista-orange-mid",
                    selectOptions:
                        students && Object.values(students).map((s) => [s.id, s["config"]["student_name"]]),
                    defaultSelection: selectedStudent && selectedStudent.id
                },
                {
                    Icon: Archive,
                    text: "All answers (.zip)",
                    endpointFunc: () => GoodPointApi.Answers.DownloadAll(examObject.id),
                    filenameFunc: () => `${standardSlugify(examName)}_answers_output.zip`,
                    enabledClassName: "bg-zanista-orange",
                }
            ]}
        >
            <p>Click on a button below to download a specific student's submitted answer for {examName}.</p>
            <p>Or, click on "Download All Answers" to download a .zip archive of all the students' answers.</p>
        </DownloadsMenuButton>
    )
}
