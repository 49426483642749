import HomeHeader from "./components/HomeHeader";
import { MsalProvider } from "@azure/msal-react";
// import { ContactPopup, Subject } from "./components/ContactPopup";
import { Link } from "react-router-dom";
import './Home.css'
import { NavArrowRight, BrightStar, Timer, Group, TestTube, DashboardSpeed, Atom, ShieldCheck, } from "iconoir-react";
import Feature from "./components/Feature";
import zanistaImg from 'src/assets/logo.png';
import msFoundersImg from 'src/assets/home/ms-founders.png';
import nvidiaImg from 'src/assets/nvidia.png';
import azureImg from 'src/assets/home/azure.png';
import microsoftImg from 'src/assets/home/microsoft.png';
import FAQAccordion from "./components/FAQAccordion";
import ScrollAnimation from "react-animate-on-scroll";
import Carousel from "./components/Carousel";
import HomeFooter from 'src/pages/home/components/HomeFooter';


function HeroSection() {
    return (
        <div className={`relative min-h-screen flex overflow-clip px-5 sm:px-10 -mt-20 bg-gradient-to-b from-primary to-white -ml-5 -mr-5`}>
            <div className="pt-4 max-w-screen-xl mx-auto flex justify-center items-center">
                <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-[100px] h-full">
                    <div className="flex-col h-full justify-start md:mt-[200px] mt-0 pl-4">
                        <HeadlineSection />
                        <CTASection />
                    </div>
                    <div className="lg:flex-col h-full justify-start md:mt-[270px] mt-0 overflow-visible hidden lg:flex">
                        <Carousel />
                    </div>
                </div>
            </div>
        </div>
    );
}


function HeadlineSection() {
    return (
        <div className="flex-col justify-content-center items-start py-6 md:py-0 animate-fadeIn">

            <h1 className="pt-5 pb-3 text-start md:text-6xl text-4xl font-bold afacad-flux-normal">
                Improving Automation in EdTech and FinTech by Leveraging Generative AI
            </h1>

            <p className="text-start font-bold inline-block afacad-flux-normal text-lg">
                <BrightStar className="inline-block mb-1 mr-1 text-gray-600" />
                Streamline exam grading using AI with GoodPoint
            </p>

            <p className="text-start font-bold inline-block afacad-flux-normal text-lg">

                <BrightStar className="inline-block mb-1 mr-1 text-gray-600" />
                Automated academic literature review and insights with PaperPal
            </p>
        </div>
    )
}

export function CTASection({ className = "", bigger = false, animate = false }) {
    const Section = () => (
        <div className={`flex-row justify-start gap-4 mt-5 ${className}`}>
            {/* Replace ContactPopup with an anchor tag */}
            <a
                href="https://calendly.com/arman-khaledian-zanista/30min?month=2025-01"
                target="_blank"
                rel="noopener noreferrer"
            >
                <button
                    className={
                        `btn-clickable px-3 pr-1 ${
                            bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'
                        } leading-snug bg-zanista-yellow-light hover:bg-accent text-slate-800 font-semibold shadow-xl rounded-3xl ` + 
                        `afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`
                    }
                >
                    Request a demo
                    <NavArrowRight className="inline-block" />
                </button>
            </a>

            <Link to="products/goodpoint">
                <button
                    className={
                        `btn-clickable px-3 pr-1 ${
                            bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'
                        } leading-snug bg-secondary hover:bg-accent text-black font-semibold shadow-xl rounded-3xl ` + 
                        `afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`
                    }
                >
                    Learn more
                    <NavArrowRight className="inline-block" />
                </button>
            </Link>
        </div>

    );

    return animate ? (
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
            <Section />
        </ScrollAnimation>
    ) : (
        <div className="animate-fadeIn">
            <Section />
        </div>
    );
}


function FeaturesSection() {
    return (
        <div className={`relative min-h-screen flex bg-white`}
        >
            <div className="max-w-screen-xl mx-auto flex justify-center items-center ">

                <div className="bg-transparent pb-12 px-8">
                    <div className="container mx-auto">
                        <div className="grid md:grid-cols-3 px-0 md:px-8 grid-cols-1 gap-x-4 gap-y-8 md:gap-y-16">
                            <Feature
                                icon={<Group size={32} />}
                                title={"Personalised feedback for every student"}
                                description={"Question by question feedback gives students the necessary resources to learn from their mistakes."}
                            />
                            <Feature
                                icon={<Timer size={32} />}
                                title={"More time for educators"}
                                description={"GoodPoint allows educators to focus more on teaching and less on marking."}
                                gray={true}
                            />
                            <Feature
                                icon={<TestTube size={32} />}
                                title={"Pilot programs and partnerships"}
                                description={"We are currently engaged in pilot programs with educators from all over the world to improve our product."}
                            />
                            <Feature
                                icon={<DashboardSpeed size={32} />}
                                title={"Fast marking with AI"}
                                description={"No more waiting weeks for results, only to have forgotten the content."}
                                gray={true}

                            />
                            <Feature
                                icon={<Atom size={32} />}
                                title={"Specialization in STEM Subjects"}
                                description={"Our focus is on STEM subjects for pre-university, undergraduate, and postgraduate courses."}

                            />
                            <Feature
                                icon={<ShieldCheck size={32} />}
                                title={"Commitment to Data Privacy"}
                                description={"We ensure GDPR-compliant data protection, emphasizing strict security measures without saving or sharing user data with third parties."}
                                gray={true}

                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function TestimonialsSection() {
    const imgClass = "object-contain h-12 my-4 mx-auto transform transition-transform duration-200 hover:scale-105"
    return (

        <div className="mt-10 py-2 flex-col justify-start items-start text-start  rounded-tl-3xl rounded-br-3xl">
            <div className='container mx-auto'>
                <div className="flex-col align-center">
                    <h4 className="text-center lg:text-md text-sm text-black pb-2 afacad-flux-normal">
                        We are supported by&nbsp;
                        <a href="https://startups.microsoft.com"
                            className="text-center lg:text-md text-sm text-black pb-2 afacad-flux-normal underline hover:text-accent transition-all duration-200">
                            Microsoft for  Startups Founders Hub
                        </a> and &nbsp;
                        <a href="https://www.nvidia.com/en-us/startups/"
                            className="text-center lg:text-md text-sm text-black pb-2 afacad-flux-normal underline hover:text-accent transition-all duration-200">
                            NVIDIA Inception
                        </a>
                    </h4>
                    <div className="grid md:grid-cols-5 grid-cols-3 lg:gap-4 gap-1 justify-content-center mx-auto">
                        <img className={imgClass} alt="Zanista AI" src={zanistaImg} />
                        <img className={imgClass} alt="Nvidia" src={nvidiaImg} />
                        <img className={imgClass} alt="Microsoft for Startups Founders Hub" src={msFoundersImg} />
                        <img className={imgClass} alt="Microsoft Azure" src={azureImg} />
                        <img className={imgClass} alt="Microsoft" src={microsoftImg} />
                    </div>
                </div>
            </div>
        </div>
    );
}


function FAQSection() {
    return (
        <div className={`relative min-h-screen flex bg-white`}>
            <div className="max-w-screen-xl mx-auto flex justify-center items-center text-4xl ">
                <div className="bg-transparent py-6 px-8">
                    <div className='container mx-auto'>
                        <ScrollAnimation animateIn='fadeIn'
                            animateOut='fadeOut'>
                            <p className="text-xl afacad-flux-normal text-center pb-2">Frequently Asked Questions</p>
                            <FAQAccordion />
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeIn'
                            animateOut='fadeOut'>

                            <TestimonialsSection />
                        </ScrollAnimation>

                    </div>
                </div>
            </div>
        </div>
    );
}


function TryNowSection() {
    return (
        <div className={`relative min-h-screen flex overflow-clip sm:px-20 sm:py-20 `}>
            <div className="max-w-screen-xl mx-auto w-full flex flex-col justify-center items-center bg-primary-gradient rounded-3xl">
                <ScrollAnimation animateIn='fadeIn'
                    animateOut='fadeOut'>
                    <h1 className="afacad-flux-normal text-5xl sm:text-6xl px-8 sm:px-0">Try&nbsp;
                        <span className=" text-accent afacad-flux-normal text-5xl sm:text-6xl">Goodpoint </span>now.</h1>
                </ScrollAnimation>
                <CTASection className="justify-center" bigger animate />
            </div>
        </div>
    );
}


export default function Home({ msal }) {

    return (
        <MsalProvider instance={msal}>
            <div>
                <HomeHeader />
                <div className="h-screen">
                    <div className="">
                        <HeroSection />
                    </div>
                    <div className="" >
                        <FeaturesSection />
                    </div>
                    <div className="" >
                        <FAQSection />
                    </div>
                    <div className="" >
                        <TryNowSection />
                    </div>
                    <div className='mx-8 pb-2'>
                        <HomeFooter />
                    </div>
                </div>
            </div>
        </MsalProvider>

    )
}
