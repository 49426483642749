import { BrainResearch, PlusCircle, Upload } from 'iconoir-react';
import { Link } from 'react-router-dom';

import "./ExamItem.css"
import useCallApi from "src/api/useCallApi";
import { protectedResources } from 'src/auth/AuthConfig';
import { useNavigate } from 'react-router-dom';
import { Tab } from 'src/api/goodpoint/Const';
import { StepState } from 'src/api/goodpoint/Const';
import dayjs from 'dayjs';
import GoodPointApi from 'src/api/goodpoint/GoodPointApi';
import { TabIcons } from 'src/pages/goodpoint/exam/tabs/Tabs';


function nextStepIcon({ rubric: rubricState, answers: answersState, grading: gradingState}) {
    if (rubricState === StepState.AVAILABLE) return Upload;
    if (rubricState < StepState.COMPLETED) return TabIcons[Tab.RUBRIC];
    if (answersState === StepState.AVAILABLE) return Upload;
    if (answersState < StepState.COMPLETED) return TabIcons[Tab.ANSWERS];
    if (gradingState < StepState.COMPLETED) return BrainResearch;
    return TabIcons[Tab.GRADING];
}

function nextStep({ rubric: rubricState, answers: answersState, grading: gradingState}) {
    switch (gradingState) {
        case StepState.COMPLETED: return "Graded"
        case StepState.PROCESSING: return "Grading..."
        case StepState.READY:
        case StepState.AVAILABLE: return "Ready to grade"
        default:
    }
    switch (answersState) {
        case StepState.COMPLETED: return "Ready to grade"
        case StepState.PROCESSING: return "Processing student answers..."
        case StepState.READY: return "Process student answers"
        case StepState.AVAILABLE: return "Upload student answers"
        default:
    }
    switch (rubricState) {
        case StepState.COMPLETED: return "Upload student answers"
        case StepState.PROCESSING: return "Processing rubric..."
        case StepState.READY: return "Process rubric"
        case StepState.AVAILABLE: return "Upload rubric"
        default:
    }
    return "Click to get started"  // This should never appear
}

export function NewExam() {
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write }
    })
    const navigate = useNavigate();

    function createExam() {
        callApi("POST", GoodPointApi.NewExam)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    navigate(`exam/${body["data"]["id"]}`);
                });
            } else {
                console.error("Failed to create new exam: " + response.status + " " + response.statusText);
            }
        });
    }

    return (
        <button 
            className={
                "btn-clickable bg-zanista-orange-mid flex flex-row items-center gap-2 justify-start w-full " +
                "hover:scale-105 hover:bg-zanista-red-light p-3 hover:py-4 rounded-lg mt-2"
            }
            onClick={createExam}
        >
            <PlusCircle width={"1em"} height={"1em"} strokeWidth={2.5}/>
            <p>New Exam</p>
        </button>
    );
}

export function ExamItem({ item, clickable = true, colour, hoverColour }) {
    const { id: examId, name: examName, info: examInfo, state: examState } = item;
    const examDate = examInfo["date"] ? dayjs(examInfo["date"]).format('DD MMM YYYY') : "";
    const examCourse = examInfo["course_title"] !== "(course title)" ? examInfo["course_title"] : null;
    const examSubject = examInfo["subject"] !== "(subject)" ? examInfo["subject"] : null;
    const ActionIcon = nextStepIcon(examState);
    return (
        <Link
            className={
                `exam-item ${clickable ? `btn-clickable` : "pointer-events-none"} ` + 
                `bg-${colour} hover:bg-${hoverColour} w-full p-3 rounded-small hover:scale-105 hover:py-4 `
            }
            to={`exam/${examId}`}
        >
            <div className={`exam-item-grid gap-large box-sizing-border-box`}>
                <p className='exam-title font-extrabold'>{examName}</p>
                <p className='exam-date'>{examDate}</p>
                <p className='exam-course-title'>{examCourse}</p>
                <p className='exam-subject'>{examSubject}</p>
                <div className='exam-action flex-row gap-mid justify-content-right'>
                    {nextStep(examState)}
                    <ActionIcon />
                </div>
            </div>
        </Link>
    )
}