import { ArrowRight, Check } from "iconoir-react"
import { useNavigate } from 'react-router-dom';
import { capitalize } from "src/util/StringUtil"


function FooterRightButton({ text, Icon, onClick, enabled = true }) {
    return (
        <button 
            className={
                `flex flex-row gap-1 items-center p-1 px-2 pl-3 rounded-md transition ` + 
                `${
                    enabled 
                    ? "btn-clickable bg-zanista-orange hover:bg-zanista-red border-solid border-2 " +
                      "border-zanista-orange hover:border-zanista-red "
                    : "bg-slate-300 text-slate-500"
                }`
            }
            onClick={onClick} disabled={!enabled}
        >
            <p>{text}</p>
            <Icon width={20} height={20} strokeWidth={2.0}/>
        </button>
    )
}

function NextButton({ text, onClick, enabled = true }) {
    return <FooterRightButton text={text} Icon={ArrowRight} onClick={onClick} enabled={enabled} />
}

function FinishButton({ enabled = true }) {
    const navigate = useNavigate();
    return (
        <FooterRightButton 
            text={"Finish"} 
            Icon={Check} 
            onClick={() => { navigate("/goodpoint") }} 
            enabled={enabled}
        />
    )
}

export default function FooterControlBar({ changeTab, currentTab, nextTab, futureTab, footerControls = null }) {
    var nT, nextEnabled;
    if (currentTab === nextTab) {
        nT = futureTab;
        nextEnabled = false;
    } else {
        nT = nextTab;
        nextEnabled = true;
    }
    const nextButton = (nT != null)
        ? (<NextButton text={`Next: ${capitalize(nT)}`} onClick={() => changeTab(nT)} enabled={nextEnabled} />)
        : (<FinishButton enabled={nextEnabled} />)
    return (
        <div className="centered fixed w-full bottom-0 left-0 p-3 bg-white shadow-[0_0_20px_rgba(0,0,0,0.3)]">
            <div className="container w-full flex flex-row justify-between align-center">
                <div className="flex flex-row gap-3">
                    {
                        footerControls
                            ? footerControls.map((c, i) => <div key={i}>{c}</div>)
                            : null
                    }
                </div>
                {nextButton}
            </div>
        </div>
    )
}