import { Link } from "react-router-dom";
import Markdown from 'react-markdown'
import { ContactPopup, Subject } from "src/pages/home/components/ContactPopup";

function CrawlerInfo() {
    return (
        <div className='w-full'>
            <div className="p-2">
                {
                    <RenderMarkdown text={infoText} />
                }
            </div>
        </div>
    );
}

function RenderMarkdown({ text }) {
    return (
        <div className="flex-col gap-2">
            <Markdown
                components={{
                    h1(props) {
                        const { node, ...rest } = props
                        return <p className="sm:text-lg text-base text-black" {...rest} />
                    },
                    h2(props) {
                        const { node, ...rest } = props
                        return <p className="text-base font-bold text-gray-700" {...rest} />
                    },
                    p(props) {
                        const { node, ...rest } = props
                        return <p className="text-xs sm:text-sm text-gray-700" {...rest} />
                    },
                    // Bold
                    strong(props) {
                        const { node, ...rest } = props
                        return <b className="text-xs sm:text-sm font-bold text-gray-700" {...rest} />
                    },
                    // Italics
                    em(props) {
                        const { node, ...rest } = props
                        return <p className="text-xs sm:text-sm italic text-gray-700" {...rest} />
                    },
                    // Links
                    a(props) {
                        const { node, ...rest } = props
                        return (
                            rest.href.toLowerCase() === "mailto:info@zanista.ai" ?
                                <ContactPopup subject={Subject.GENERAL}>
                                    <span className="text-xs sm:text-sm underline cursor-pointer text-gray-700" {...rest} />
                                </ContactPopup>
                                :
                                <Link to={rest.href} className="text-xs sm:text-sm underline cursor-pointer text-gray-700" {...rest} />
                        )
                    },
                    li(props) {
                        const { node, ...rest } = props
                        return (<div className="pl-2 py-1 gap-2 flex-row items-center">
                            <div className="bg-slate-700 w-1 h-1 rounded-full"></div>
                            <div className="" {...rest} />
                        </div>)
                    },
                }}
            >
                {text}
            </Markdown>
        </div>
    );
}

const infoText = `

# Zanista.ai Crawler Information

## About ZanistaBot

ZanistaBot is a web crawler operated by Zanista.ai, designed to gather financial news from a variety of sources. 
Our primary objective is to provide our customers with summarized reports that include sentiment analysis, ensuring a tailored and relevant experience. 
We prioritize delivering a high-quality service and **promoting visibility for news sources that provide valuable content. 
All our reports include references to the original news sources, enhancing visibility for these sources among our customer base, which consists of experts in the financial sector.**

## Crawler Details

- Crawler Name: ZanistaBot
  
- Version: 1.0
  
- User-Agent String: ZanistaBot/1.0 (+https://zanista.ai/crawler-info)
  

## Purpose of Crawling

ZanistaBot collects financial news articles to:

1. Summarize the content using advanced language models (LLM).
  
2. Perform sentiment analysis on the articles.
  
3. Provide tailored financial news reports to our customers with citations to the original sources.
  

## Crawling Policies

- **Respect for robots.txt:** ZanistaBot fully respects the rules and directives specified in the robots.txt file of each website we crawl. If a website disallows certain sections or all crawling activity, ZanistaBot will comply with these instructions.
  
- **Rate Limiting:** We implement rate limiting to ensure that our crawling activities do not negatively impact the performance of the websites we interact with. Our bot adheres to respectful crawling practices to avoid overloading any website's server.
  
- **User-Agent Identification:** Our crawler identifies itself clearly through the user-agent string provided above. This helps website administrators recognize our crawling activities.
  

## Contact Us

If you have any questions, concerns, or requests regarding our crawler, please feel free to contact us:

- Email: info@zanista.ai
  
- Phone: +44 7934 222542
  

We are committed to working collaboratively with website administrators to ensure our crawling activities are mutually beneficial and adhere to best practices.
`;

export default CrawlerInfo;
