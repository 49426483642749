//top down adjusting marks
export function adjustListToSum(lst, targetSum) {
    function roundToHalf(value) {
        return Math.round(value * 2) / 2;
    }

    const originalSum = lst.reduce((acc, val) => acc + val, 0);
    const multiplier = originalSum !== 0 ? targetSum / originalSum : 1;

    let adjustedLst = lst.map((num) => roundToHalf(num * multiplier));
    adjustedLst.reverse();

    let diff = parseFloat(
        (targetSum - adjustedLst.reduce((acc, val) => acc + val, 0)).toFixed(1)
    );
    let countedAdj = 0;

    while (Math.abs(diff) > 0.01 && countedAdj <= 100) {
        countedAdj += 1;
        for (let i = 0; i < adjustedLst.length; i++) {
            if (diff > 0) {
                adjustedLst[i] += 0.5;
                diff = parseFloat((diff - 0.5).toFixed(1));
                if (Math.abs(diff) < 0.01) break;
            } else if (diff < 0) {
                adjustedLst[i] -= 0.5;
                diff = parseFloat((diff + 0.5).toFixed(1));
                if (Math.abs(diff) < 0.01) break;
            }
        }
    }
    adjustedLst.reverse();
    return adjustedLst;
}

export function tokenizeText(text) {
    // Regex to match LaTeX between $...$ or $$...$$
    const regex = /(\$\$[\s\S]*?\$\$|\$.*?\$)/g;
    const tokens = text.split(regex);

    // Create tokens with the filter condition applied
    let filteredTokens = tokens
        .map((token, index) => {
            if (token.trim() === "") {
                return null; // Return null for empty tokens
            }
            if (token.startsWith("$$") && token.endsWith("$$")) {
                return { type: "latex", content: token, id: index };
            }
            if (token.startsWith("$") && token.endsWith("$")) {
                return { type: "latex", content: token, id: index };
            }
            return { type: "text", content: token, id: index };
        })
        .filter((token) => token !== null); // Filter out null tokens

    if ((filteredTokens.length > 1) && filteredTokens[filteredTokens.length - 1].type === "latex") {
        filteredTokens.push({
            id: filteredTokens.length,
            content: "",
            type: "text",
        });
    }
    // Update the IDs of the remaining tokens to be consecutive
    filteredTokens = filteredTokens.map((token, index) => ({
        ...token,
        id: index,
    }));

    return filteredTokens;
};

export function unTokenizeText(tokens) {
    if (!Array.isArray(tokens)) {
        return "";
    }
    const content = tokens.map((token) => token.content).join("");
    return content;
}

export function isAnyFlagged(questions) {
    if (!questions) {
        return false;
    }
    if (!Array.isArray(questions)) {
        questions = [questions];
    }
    for (const q of questions) {
        if (q.flagged) {
            return true;
        }
        if ("sub_items" in q) {
            const anySubItemFlagged = isAnyFlagged(q.sub_items);
            if (anySubItemFlagged) {
                return true;
            }
        }
    }
    return false;
}

export function getFromStructure(structure, tagList) {
    var item = structure;
    for (const [, tag] of tagList.entries()) {
        item = item[tag];
    }
    return item;
}