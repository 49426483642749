// import './ZanistaHeader.css'
import ZanistaLogo from "src/assets/logo.png"
import { ProfileCircle, Xmark } from 'iconoir-react';
import { Link, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import useCallApi from 'src/api/useCallApi';
import { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import { capitalize } from 'src/util/StringUtil';
import GoodPointApi from 'src/api/goodpoint/GoodPointApi';
import { ComponentGuard } from 'src/api/RouteGuard';
import { protectedResources } from 'src/auth/AuthConfig';


function UserPopup({ userInfo }) {
    const { instance } = useMsal();
    const callApi = useCallApi();
    
    const popupRef = useRef(null);
    const closePopup = () => popupRef?.current?.close();

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    const handleDelete = () => {
        if (!instance.getActiveAccount() || !instance.getActiveAccount().idTokenClaims['extension_UserID']) {
            return;
        }
        if (!window.confirm("Are you sure you want to delete your account?")) {
            return;
        }
        callApi("DELETE", protectedResources.apiManagement.endpoint + "/user", {
            body: {
                user_id: instance.getActiveAccount().idTokenClaims['extension_UserID']
            }
        }).then(response => {
            if (response.status === 200) {
                handleLogout();
            } else {
                console.error("Failed to delete user: " + response.status + " " + response.statusText);
            }
        });
    }

    return (
        <div className="cursor-pointer">
            <Popup 
                ref={popupRef}
                position="bottom right"
                trigger={
                    <ProfileCircle
                        className="hover:scale-110"
                        height={32}
                        width={32}
                    />
                }
            >
                <div className={
                    "user-info-modal flex flex-col bg-white z-50 rounded-lg p-4 shadow-slate-400 " +
                    "m-auto mt-1 shadow-[0px_0px_10px]"
                }>
                    <div className='flex flex-row w-full justify-between items-center'>
                        <h2>User Info</h2>
                        <div onClick={closePopup} className="cursor-pointer hover:scale-110">
                            <Xmark />
                        </div>
                    </div>
                    {
                        !userInfo 
                            ? <div><p>Fetching user data...</p></div>
                            : <div>
                                <p>{capitalize(userInfo.first_name) + " " + capitalize(userInfo.last_name)}</p>
                                {
                                    userInfo.email &&
                                    <p>{userInfo.email}</p>
                                }
                                <ComponentGuard roles={["admin"]} >
                                    <p>{"Job Title: " + capitalize(userInfo.job_title)}</p>
                                    <p>{"Role: " + capitalize(userInfo.role)}</p>
                                    <p>{"Access: " + capitalize(userInfo.access.toString())}</p>
                                </ComponentGuard>
                                <p>{"Credits: " + capitalize(userInfo.credits.toString())}</p>
                            </div>
                    }
                    <div className='flex flex-wrap gap-3 pt-2'>
                        <button 
                            className="btn-clickable p-1 px-2 bg-zanista-orange-mid rounded-md"
                            onClick={() => { closePopup(); handleLogout(); }}
                        >
                            Logout
                        </button>
                        {
                            userInfo &&
                            <button 
                                onClick={() => {closePopup()}} 
                                className="btn-clickable p-1 px-2 bg-zanista-orange-mid rounded-md"
                            >
                                <Link to="/checkout" className="text-nowrap" >
                                    Buy Credits
                                </Link>
                            </button>
                        }
                        {
                            userInfo &&
                            <button 
                                onClick={handleDelete} 
                                className=
                                "btn-clickable p-1 px-2 bg-zanista-orange-mid hover:bg-zanista-red-mid rounded-md text-nowrap"
                            >
                                Delete Account
                            </button>
                        }
                    </div>
                </div>
            </Popup>
        </div>
    )
}


export default function ZanistaHeader() {
    const callApi = useCallApi();

    const [userInfo, setUserInfo] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (userInfo) return;
        callApi("GET", GoodPointApi.User)
            ?.then(response => {
                if (response.status === 200) {
                    response.json().then(body => {
                        // Get everything from local storage and update exams list
                        var result;
                        if (body["data"]) {
                            result = body["data"];
                        } else {
                            result = body;
                        }
                        setUserInfo(result);
                    });
                } else if (response.status === 401) {
                    // User does not have access to the API
                    console.error(
                        `User does not have currently have access: ${response.status} ${response.statusText}`
                    );
                    setUserInfo({
                        first_name: "",
                        last_name: "",
                        job_title: "",
                        email: "",
                        role: "user",
                        access: false,
                        credits: 0,
                    });
                } else {
                    console.error(`Failed to fetch user info: ${response.status} ${response.statusText}`);
                }
            });
    }, [callApi, userInfo]);

    return (
        <header className={
            "bg-white shadow-lg shadow-slate-300 height-[50px] p-3 w-full fixed top-0 left-0 right-0 z-50"
        }>
            <div className="container mx-auto flex flex-row w-full items-center justify-between">
                <div className="flex flex-row items-center gap-4">
                    <Link to="/">
                        <img className="h-[40px]" src={ZanistaLogo} alt="Zanista AI"/>
                    </Link>
                    {
                        location.pathname !== "/dashboard" &&
                        <Link
                            className="text-xs mx-1 hover:text-orange-600 hidden sm:inline cursor-pointer"
                            to="/dashboard"
                        >
                            Dashboard
                        </Link>
                    }
                </div>
                <nav>
                    <div className="flex flex-row items-center gap-4">
                        <ComponentGuard roles={["admin"]}>
                            {
                                userInfo &&
                                    <p className='text-xs'>
                                        {userInfo.first_name} {userInfo.last_name}
                                    </p>
                            }
                        </ComponentGuard>
                        <ComponentGuard roles={["admin"]} >
                            <Link
                                to="/admin"
                                className={
                                    "btn-clickable text-xs h-min p-1 px-2 hidden md:inline " + 
                                    "rounded bg-zanista-orange-mid hover:bg-zanista-red-light"
                                }
                            >
                                Admin Portal
                            </Link>
                        </ComponentGuard>
                        <UserPopup userInfo={userInfo} />
                    </div>
                </nav>
            </div>
        </header>
    );
}